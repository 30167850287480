import React from 'react'
import Button from '../../components/Button'
import useLocale from '../../hooks/useLocale'
import useLegalTexts from '../../hooks/useLegalTexts'
import useModal from '../../hooks/useModal'
import Modal from '../Modal'
import { H2 } from '../Headings'
import { Select } from '../FormComponents'

function LegalsSelection({ formLegal, onChange }) {
  const { l } = useLocale()
  const { legalTexts } = useLegalTexts(false)
  const { openModal, showModal, closeModal } = useModal()

  return (
    <>
      <Button tooltip={ l('legal_texts_form') } onClick={ openModal }>
        <img
          src='/img/legal.svg'
          className='max-w-[25px]'
          alt='legal_texts_img'
        />
      </Button>
      <Modal show={ showModal } onClose={ closeModal }>
        <H2>{ l('legal_texts') }</H2>
        <Select
          label={ l('legal_text') }
          options={ legalTexts }
          labelKey={ 'name' }
          valueKey='_id'
          value={ formLegal }
          name='legalText'
          onChange={ onChange }
        />
        <Button type='secondary' full onClick={ closeModal }>
          { l('accept') }
        </Button>
      </Modal>
    </>
  )
}

export default LegalsSelection
