import { Link } from 'react-router-dom';
import CheckRole from '../../../components/CheckRole';
import DangerButton from '../../../components/DangerButton';
import useLocale from '../../../hooks/useLocale';
import { DangerModalContent } from '../../ManageUsers/components/DangerModalContent';

export function UserExchangesActionColumn({ rowData, handleExchangeSent }) {
  const { l } = useLocale();
  return (
    <>
      <CheckRole has="view_user_exchanges">
        <Link
          to={`/user-exchanges/${rowData._id}`}
          className="bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200"
        >
          <img className="w-[25px]" src="/img/view.svg" alt="" />
        </Link>
      </CheckRole>

      <CheckRole has="edit_user_exchanges">
        <DangerButton
          type="dark"
          tooltip={l('mark_exchange_as_sent')}
          onClick={() => handleExchangeSent(rowData)}
          content={<DangerModalContent />}
          icon={null}
          textContent={<img className="w-[25px]" src="/img/send.svg" alt="" />}
        />
      </CheckRole>

      {rowData.product.attributes.link ? (
        <a
          href={rowData.product.attributes.link}
          target="_blank"
          rel="noreferrer"
          title={l('open_product_link')}
          className="bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200"
        >
          <img className="w-[25px]" src="/img/shopping_cart.svg" alt="" />
        </a>
      ) : (
        <span
          title={l('open_product_link')}
          className="bg-gray-500 px-2 py-1 rounded-md cursor-not-allowed"
        >
          <img className="w-[25px]" src="/img/shopping_cart.svg" alt="" />
        </span>
      )}
    </>
  );
}
