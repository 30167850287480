import React from 'react'
import Card from '../../components/Card'
import useLocale from '../../hooks/useLocale'
import { H1 } from '../../components/Headings'
import { Checkbox, Input, Select } from '../../components/FormComponents'
import Editor from '../../components/Editor'
import Button from '../../components/Button'
import useEmail from '../../hooks/useEmail'
import Modal from '../../components/Modal'
import CheckRole from '../../components/CheckRole'
import Accordion from '../../components/Accordion'
import useRoles from '../../hooks/useRoles'
import useEmails from '../../hooks/useEmails'
import useConfigurations from '../../hooks/useConfigurations'

function ShowEmail() {
	const { l } = useLocale()
	const { roles } = useRoles(false)
	const {
		mail,
		handleChange,
		handleSave,
		showModal,
		closeModal,
		openModal,
		handleEmailTest,
		handleRecipientsChange,
		testDestinationsRef,
		handleUserSelect,
		handleCheckboxChange,
		testConfigSet,
	} = useEmail()
	const { mails } = useEmails(false)
	const { configSets } = useConfigurations(false)
	const isTemplate = mail.isTemplate

	return (
		<>
			<H1>
				<img src='/img/email.svg' className='w-[35px]' alt='' />
				{l('view_emails')} {mail.name}
			</H1>
			<Card>
				{!isTemplate && (
					<CheckRole has='send_tests_emails'>
						<Button type='secondary' onClick={openModal}>
							{l('test_email_send')}
						</Button>
					</CheckRole>
				)}
				<div className='grid grid-cols-1 md:grid-cols-3 gap-5 mt-3'>
					<div>
						<Checkbox
							label={l('is_template')}
							onChange={handleCheckboxChange}
							checked={mail.isTemplate}
							name={'isTemplate'}
						/>
						<Input
							label={l('name')}
							name='name'
							onChange={handleChange}
							value={mail.name}
						/>
						<Select
							name={'template'}
							label={l('layout')}
							valueKey={'_id'}
							labelKey={'name'}
							onChange={handleChange}
							options={mails.filter(
								email => email.isTemplate && email._id !== mail._id
							)}
							value={mail.template || ''}
						/>
					</div>
					{!isTemplate && (
						<>
							<div>
								<label className='font-bold'>{l('recipients')}</label>
								<div className='columns-2'>
									<Checkbox
										label={l('send_to_user')}
										name='user'
										checked={mail.sendToUser}
										onChange={handleUserSelect}
									/>
									{roles.map(role => (
										<Checkbox
											key={role._id}
											label={role.name}
											name={role._id}
											checked={mail.rolesToSend?.includes(role._id)}
											onChange={e => handleRecipientsChange(e, 'rolesToSend')}
										/>
									))}
								</div>
							</div>
							<div>
								<Input
									label={l('additional')}
									name='additionalDestinations'
									onChange={handleChange}
									value={mail.additionalDestinations}
								/>
								<p className='text-sm text-gray-300'>{l('mails_tooltip')}</p>
							</div>
						</>
					)}
				</div>
				<Accordion openDefault={true} title={l('variables')}>
					<div className='grid grid-cols-1 md:grid-cols-4'>
						<ul>
							<li className='font-bold'>{l('user')}</li>
							<li>
								{l('name')}: {'{user.name}'}
							</li>
							<li>
								{l('email')}: {'{user.email}'}
							</li>
							<li>
								{l('attributes')}: {'{user.attributes.*}'}
							</li>
						</ul>
						<ul>
							<li className='font-bold'>{l('form')}</li>
							<li>
								{l('name')}: {'{form.name}'}
							</li>
						</ul>
						<ul>
							<li className='font-bold'>{l('clinic_case')}</li>
							<li>
								{l('case_last_comment')}: {'{clinicCase.lastComment.comment}'}
							</li>
						</ul>
					</div>
				</Accordion>
				{!isTemplate && (
					<Input
						label={l('subject')}
						name='subject'
						onChange={handleChange}
						value={mail.subject}
					/>
				)}
				<label className='font-bold'>{l('text')}</label>
				<Editor
					value={mail.text || ''}
					handleChange={(event, editor) => {
						const data = editor.getData()
						handleChange({
							target: {
								name: 'text',
								value: data,
							},
						})
					}}
				/>
			</Card>
			<CheckRole has='edit_emails'>
				<Button onClick={handleSave}>{l('save')}</Button>
			</CheckRole>
			<Modal onClose={closeModal} show={showModal}>
				<form onSubmit={handleEmailTest}>
					<Input label={l('recipients')} reference={testDestinationsRef} />
					<Select
						label={l('config')}
						options={configSets}
						valueKey={'_id'}
						labelKey={'name'}
						reference={testConfigSet}
					/>
					<Button type='secondary' full>
						{l('send')}
					</Button>
				</form>
			</Modal>
		</>
	)
}

export default ShowEmail
