import {Link} from 'react-router-dom';
import Button from '../../../components/Button';
import {H2} from '../../../components/Headings';
import Modal from '../../../components/Modal';
import {Stack} from '../../../components/Stack';
import useLocale from '../../../hooks/useLocale';
import useModal from '../../../hooks/useModal';
import CheckRole from '../../../components/CheckRole';

export function AuthorDetails ({author = {}}) {
  const modal = useModal()
  const {l} = useLocale()

  return (
    <CheckRole has={'see_author_details'}>
      <Button onClick={modal.openModal}>
        {l('see_author_details')}
      </Button>
      <Modal show={modal.showModal} onClose={modal.closeModal}>
        <H2>{l('author_details')}</H2>

        <div className="grid grid-cols-2">
          <Stack>
            <strong>{l('name')}:</strong>
            <span>{author.name}</span>
          </Stack>

          <Stack>
            <strong>{l('email')}:</strong>
            <span>{author.email}</span>
          </Stack>

        </div>
        <div className='flex justify-center'>
          <Link className='bg-gray-900 px-4 py-2 rounded-md mt-2' to={`/users/${author._id}`}>{l('view_full_user')}</Link>
        </div>
      </Modal>
    </CheckRole>
  )
}