import useLocale from '../../hooks/useLocale';
import { H2 } from '../Headings';
import Modal from '../Modal';

export function ViewQuestionsModal({ modal, id, item }) {
  const {l} = useLocale();
  
  return (
    <Modal show={modal.showModal} onClose={modal.closeModal}>
      <H2>{l('view_questions')}</H2>
      
      {item?.attributes.questions.map((question, index) => (
        <>
          <p className='font-bold'>{l('question')} {index + 1}</p>
          <p>{question.question}</p>

          <p className="font-bold mt-3">{l('answers')}</p>
          <ol className='list-decimal ml-10'>
            {question.possibleAnswers.map(answer => <li>{answer.answer} {answer.isCorrect ? '✅' : null}</li>)}
          </ol>

          <p className="font-bold mt-3">{l('justification')}</p>
          <p>{question.justification}</p>
        </>
      ))}
    </Modal>
  )
}