import React from 'react'
import { H2 } from "../Headings";
import { Checkbox, Input } from "../FormComponents";
import useLocale from "../../hooks/useLocale";

function SmtpConfig({ config, handleChange, handleCheckboxChange }) {
  const { l } = useLocale()

  return <>
    <H2><img src="/img/email.svg" className={ 'w-[25px]' } alt=""/> { l('email_config') }</H2>
    <div className={ 'grid gird-cols-1 md:grid-cols-4 gap-3' }>
      <Input
        label={ l('server') }
        onChange={ handleChange }
        value={ config.smtp.server }
        name={ 'server' }
      />
      <Input
        label={ l('port') }
        onChange={ handleChange }
        value={ config.smtp.port }
        name={ 'port' }
      />
      <Input
        label={ l('username') }
        onChange={ handleChange }
        value={ config.smtp.username }
        name={ "username" }
      />
      <Input
        label={ l('password') }
        onChange={ handleChange }
        value={ config.smtp.password }
        name={ "password" }
        type={ "password" }
      />
    </div>
    <Checkbox
      label={ l('secure') }
      checked={ config.smtp.secure }
      onChange={ handleCheckboxChange }
      name={ "secure" }
    />
  </>
}

export default SmtpConfig