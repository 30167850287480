import { Link } from 'react-router-dom';
import Card from '../../components/Card';
import { PageHeading } from '../../components/Headings';
import useLocale from '../../hooks/useLocale';
import CheckRole from '../../components/CheckRole';
import DataGridView from '../../components/DataGridView';
import React from 'react';
import ActiveForm from '../../models/ActiveForm';
import { ActiveFormsActionColumn } from './components/ActionColumn';

export function ActiveFormsPage() {
  const {l} = useLocale()
  const [activeForms, setActiveForms] = React.useState([])

  const getActiveForms = React.useCallback(async () => {
    const response = await ActiveForm.findAll();
    setActiveForms(response);
  }, [])

  async function handleDelete(id) {
    await ActiveForm.delete(id)
    getActiveForms()
  }

  const columns = [
    {
      header: l('form'),
      accessor: 'form.name',
    },
    {
      header: l('actions'),
      component: ({rowData, page, limit, filters}) => <ActiveFormsActionColumn handleDelete={handleDelete} rowData={rowData} />,
      cellClass: 'flex gap-2 justify-center'
    }
  ]


  return (
    <>
      <PageHeading title={l('active_forms')} image={'/img/active.svg'}>
        <CheckRole has={"add_active_forms"}>
          <Link className='bg-gray-500 px-2 py-1 rounded-md hover:bg-gray-400 ease-in-out duration-200' to={'/active-forms/add'}>{l('add')}</Link>
        </CheckRole>
      </PageHeading>
      <Card>
        <DataGridView columns={columns} data={activeForms} getData={getActiveForms} showPagination={false} />
      </Card>
    </>
  )
}