import React from 'react'
import Modal from '../../components/Modal'
import Question from './Question'

function AnswerQuestions ({ modal, questionsRef, id, item }) {
  if (modal.showModal === false) return null;

  return (
    <Modal show={modal.showModal} onClose={modal.closeModal}>
      {questionsRef.map(question => <Question item={item} id={id} question={question} />)}
    </Modal>
  )
}

export default AnswerQuestions