import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ClinicCase from "../models/ClinicCase";
import Form from "../models/Form";
import { toast } from "react-toastify";
import useLocale from "./useLocale";
import { ObjectId } from "bson";

function useAnswer() {
  const { form: id } = useParams();
  const [form, setForm] = React.useState({ items: {}, actions: [] });
  const [errors, setErrors] = React.useState([]);
  const [temps, setTemps] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [consent, setConsent] = React.useState(null);
  const { l } = useLocale();
  const navigate = useNavigate();

  React.useEffect(() => {
    Form.show(id).then((res) => {
      for (const key in res.items) {
        const item = res.items[key];
        if (
          item.component === "section" &&
          item.attributes.is_multiple === true
        ) {
          setTemps((old) => ({
            ...old,
            [key]: JSON.parse(JSON.stringify(res.items[key].items)),
          }));
          res.items[key].items = [res.items[key].items];
        }
      }
      setForm(res);
    });
  }, []);

  const handleInputChange = (e, key, subKey = null, multipleIndex = null) => {
    const { value } = e.target;
    const aux = form;
    if (subKey === null) aux.items[key].attributes.value = value;
    else if (multipleIndex !== null)
      aux.items[key].items[multipleIndex][subKey].attributes.value = value;
    else aux.items[key].items[subKey].attributes.value = value;
    setForm({ ...aux });
  };

  const handleTagChange = (value, key, subKey = null, multipleIndex = null) => {
    const aux = form;
    if (subKey === null) aux.items[key].attributes.value = value;
    else if (multipleIndex !== null)
      aux.items[key].items[multipleIndex][subKey].attributes.value = value;
    else aux.items[key].items[subKey].attributes.value = value;
    setForm({ ...aux });
  };

  const handleCheckboxChange = (
    e,
    key,
    subKey = null,
    multipleIndex = null,
  ) => {
    const { value } = e.target;
    const aux = form;
    if (subKey === null) {
      const index = form.items[key].attributes.value.indexOf(value);
      if (index === -1) aux.items[key].attributes.value.push(value);
      else aux.items[key].attributes.value.splice(index, 1);
    } else {
      if (multipleIndex === null) {
        const index =
          form.items[key].items[subKey].attributes.value.indexOf(value);
        if (index === -1)
          aux.items[key].items[subKey].attributes.value.push(value);
        else aux.items[key].items[subKey].attributes.value.splice(index, 1);
      } else {
        const index =
          form.items[key].items[multipleIndex][subKey].attributes.value.indexOf(
            value,
          );
        if (index === -1)
          aux.items[key].items[multipleIndex][subKey].attributes.value.push(
            value,
          );
        else
          aux.items[key].items[multipleIndex][subKey].attributes.value.splice(
            index,
            1,
          );
      }
    }

    setForm({ ...aux });
  };

  const handleAction = (action) => {
    ClinicCase.save({data: form, id, action: action._id})
      .then(async (res) => {
        const fd = new FormData();
        files.forEach((file) => {
          fd.append(file.key, file.file);
          Object.entries(file.meta).forEach((meta) => {
            fd.append(`${file.file.name}[${meta[0]}]`, meta[1]);
          });
        });
        fd.append("consent", consent);

        if (action.mustSendImagesToGallery === true) {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}clinic-cases/${res._id}/upload-to-gallery`,
            {
              method: "POST",
              body: fd,
              credentials: "include",
            },
          );
          if (!response.ok) {
            throw new Error("General_error");
          }
        }
        toast.success(l("clinic_case_saved"));
        navigate(`/clinic-cases`);
      })
      .catch((err) => {
        if (ClinicCase.fetch.status === 422) {
          toast.error(l("fields_required"));
          return setErrors(err.errors);
        }
        toast.error(l("general_error"));
      });
  };

  const handleAddRow = (key) => {
    const aux = form;
    const items = temps[key].map((item) => ({
      ...item,
      _id: new ObjectId().toHexString(),
    }));
    aux.items[key].items.push(JSON.parse(JSON.stringify(items)));
    setForm({ ...aux });
  };

  const handleDeleteRow = (key, arrIndex) => {
    if (form.items[key].items.length === 1) return;
    const aux = form;
    aux.items[key].items.splice(arrIndex, 1);
    setForm({ ...aux });
  };

  const handleFileSelect = (e, key, subKey = null, multipleIndex = null) => {
    const file = e.target.files[0];
    const aux = form;

    if (subKey === null) aux.items[key].attributes.file = file;
    else if (multipleIndex !== null)
      aux.items[key].items[multipleIndex][subKey].attributes.file = file;
    else aux.items[key].items[subKey].attributes.file = file;

    setForm({ ...aux });
  };

  const handleMetaChange = (e, key, subkey = null, multipleIndex = null) => {
    const aux = form;
    const { name, value } = e.target;

    if (subkey === null) {
      const metaIndex = aux.items[key].attributes.meta.findIndex(
        (item) => item.key === name,
      );
      if (metaIndex === -1) {
        aux.items[key].attributes.meta.push({
          key: name,
          value,
        });
      } else {
        aux.items[key].attributes.meta[metaIndex] = {
          key: name,
          value,
        };
      }
    } else if (multipleIndex !== null) {
      const metaIndex = aux.items[key].items[multipleIndex][
        subkey
      ].attributes.meta.findIndex((item) => item.key === name);
      if (metaIndex === -1) {
        aux.items[key].items[multipleIndex][subkey].attributes.meta.push({
          key: name,
          value,
        });
      } else {
        aux.items[key].items[multipleIndex][subkey].attributes.meta[metaIndex] =
          {
            key: name,
            value,
          };
      }
    } else {
      const metaIndex = aux.items[key].items[subkey].attributes.meta.findIndex(
        (item) => item.key === name,
      );
      if (metaIndex === -1) {
        aux.items[key].items[subkey].attributes.meta.push({
          key: name,
          value,
        });
      } else {
        aux.items[key].items[subkey].attributes.meta[metaIndex] = {
          key: name,
          value,
        };
      }
    }
    setForm({ ...aux });
  };

  const handleAddGalleryItem = () => {
    setFiles([
      ...files,
      {
        key: null,
        file: null,
        meta: {},
      },
    ]);
  };

  const handlePushGalleryFile = (e, index = null) => {
    const { name, files: inputFiles } = e.target;
    const file = inputFiles[0];

    const aux = files;
    aux[index].file = file;
    aux[index].key = name;

    setFiles([...aux]);
  };

  const handleSetConsentDocument = (e) => {
    const { files } = e.target;
    const file = files[0];

    setConsent(file);
  };

  const handleDeleteFile = (index) => {
    const aux = files;
    aux.splice(index, 1);
    setFiles([...aux]);
  };

  const handleSetMetaField = (e, index) => {
    const { name, value } = e.target;

    const aux = files;
    aux[index].meta[name] = value;

    setFiles([...aux]);
  };

  function handleOptinChange (e, key, subKey = null, multipleIndex = null) {
    const {checked} = e.target
    const aux = form;

    if (subKey === null) aux.items[key].attributes.value = `${checked}`;
    else if (multipleIndex !== null)
      aux.items[key].items[multipleIndex][subKey].attributes.value = `${checked}`;
    else aux.items[key].items[subKey].attributes.value = `${checked}`;

    setForm({...aux});
  }

  return {
    form,
    handleInputChange,
    handleCheckboxChange,
    handleAction,
    errors,
    handleAddRow,
    handleDeleteRow,
    handleTagChange,
    setForm,
    handleFileSelect,
    handleMetaChange,
    handlePushGalleryFile,
    handleAddGalleryItem,
    files,
    handleSetConsentDocument,
    handleDeleteFile,
    handleSetMetaField,
    handleOptinChange
  };
}

export default useAnswer;
