import React from 'react'
import useLocale from '../../hooks/useLocale'
import useModal from '../../hooks/useModal'
import Button from '../Button'
import CheckRole from '../CheckRole'
import { Input } from '../FormComponents'
import { H2 } from '../Headings'
import Modal from '../Modal'
import User from '../../models/User'
import { toast } from 'react-toastify'

function ChangeUserPassword({ userId }) {
	const { l } = useLocale()
	const passwordRef = React.useRef(null)
	const passwordConfirmationRef = React.useRef(null)
	const { openModal, showModal, closeModal } = useModal()

	const handleSubmit = e => {
		e.preventDefault()
		User.changePassword({
			password: passwordRef.current.value,
			passwordConfirmation: passwordConfirmationRef.current.value,
			userId,
		})
			.then(res => {
				closeModal()
				passwordRef.current.value = ''
				passwordConfirmationRef.current.value = ''
				toast.success(l('password_changed'))
			})
			.catch(err => toast.error(l('general_error')))
	}

	return (
		<div>
			<CheckRole has='change_user_password'>
				<Button onClick={openModal} type='secondary'>
					{l('change_password')}
				</Button>
			</CheckRole>
			<Modal show={showModal} onClose={closeModal}>
				<H2>{l('change_password')}</H2>
				<form onSubmit={handleSubmit}>
					<Input
						type='password'
						reference={passwordRef}
						label={l('password')}
					/>
					<Input
						type='password'
						reference={passwordConfirmationRef}
						label={l('confirm_password')}
					/>
					<Button type='secondary' full>
						{l('save')}
					</Button>
				</form>
			</Modal>
		</div>
	)
}

export default ChangeUserPassword
