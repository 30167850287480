import React from 'react'
import Button from '../Button'
import useLocale from '../../hooks/useLocale'

function ViewModeButton ({ viewMode, toggle }) {
  const { l } = useLocale()
  const viewLabel = viewMode === 'mine' ? 'view_all' : 'view_mine'

  return (
    <Button onClick={toggle}>{l(viewLabel)}</Button>
  )
}

export default ViewModeButton