import React from 'react'
import {PageHeading} from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import Card from '../../components/Card'
import {Link} from 'react-router-dom'
import CheckRole from '../../components/CheckRole'
import DangerButton from '../../components/DangerButton'
import useBooks from '../../hooks/useBooks'

export default function BooksPage () {
  const {l} = useLocale()
  const {books, handleDelete} = useBooks()

  return (
    <>
      <PageHeading title={l('books')} image={'/img/book.svg'}>
        <CheckRole has="add_books">
          <Link to="/books/create" className='bg-black px-2 py-1 rounded-md hover:bg-gray-900 duration-150 ease-in-out'>{l('add_book')}</Link>
        </CheckRole>
      </PageHeading>
      <Card>
        <table className='w-full'>
          <thead>
            <tr className='text-left border-b border-gray-600'>
              <th>{l('title')}</th>
              <th>{l('isbn')}</th>
              <th>{l('number_of_clinic_cases_assigned')}</th>
              <th>{l('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {books.map(book => (
              <tr>
                <td>{book.title}</td>
                <td>{book.isbn}</td>
                <td>{book.clinicCases.length}</td>
                <td className='flex gap-3'>
                  <CheckRole has='view_books'>
                    <Link
                      to={`/books/${book._id}`}
                      className='bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200'
                    >
                      <img className='w-[25px]' src='/img/view.svg' alt='' />
                    </Link>
                  </CheckRole>
                  <CheckRole has='delete_books'>
                    <DangerButton
                      onClick={() => handleDelete(book._id)}
                    />
                  </CheckRole>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
    </>
  )
}