import React from 'react'
import useLocale from '../../hooks/useLocale'
import { H1 } from '../../components/Headings'
import Card from '../../components/Card'
import { Input } from '../../components/FormComponents'
import Accordion from '../../components/Accordion'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import CheckRole from '../../components/CheckRole'
import useLegalText from '../../hooks/useLegalText'
import Editor from '../../components/Editor'

function ShowLegalText() {
	const { l } = useLocale()
	const {
		legalText,
		handleInputChange,
		handleDelete,
		handleChange,
		openModal,
		showModal,
		closeModal,
		handleSave,
		handleAddSection,
		nameRef,
		handleTextChange,
	} = useLegalText()

	return (
		<>
			<H1>
				<img src='/img/legal.svg' className='w-[35px]' alt='' />
				{l('view_legal_text')} {legalText.name}
			</H1>
			<Card>
				<Input
					label={l('name')}
					name='name'
					value={legalText.name}
					onChange={handleInputChange}
				/>
				<div className='mb-2 mt-5'>
					{legalText.texts?.map((text, idx) => (
						<React.Fragment key={idx}>
							<Accordion
								title={text.title}
								onDelete={() => handleDelete(idx)}
								onChange={e => handleChange(e, idx)}
							>
								<Editor
									value={text.text}
									handleChange={(event, editor) => {
										const data = editor.getData()
										handleTextChange({
											name: 'text',
											value: data,
											idx,
										})
									}}
								/>
							</Accordion>
						</React.Fragment>
					))}
				</div>
				<Button onClick={openModal}>{l('add')}</Button>
			</Card>
			<CheckRole has='edit_legal_texts'>
				<Button onClick={handleSave}>{l('save')}</Button>
			</CheckRole>
			<Modal show={showModal} onClose={closeModal}>
				<form onSubmit={handleAddSection}>
					<Input label={l('name')} reference={nameRef} />
					<Button type='secondary' full>
						{l('add')}
					</Button>
				</form>
			</Modal>
		</>
	)
}

export default ShowLegalText
