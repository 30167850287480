import React from 'react'
import ReactDOM from 'react-dom'
import Card from '../Card'

function Modal ({show = false, children, onClose = null}) {
  return ReactDOM.createPortal(
    <>
      <div
        tabIndex={-1}
        aria-hidden='true'
        data-modal-toggle='authentication-modal'
        id='authentication-modal'
        className={`${!show && 'hidden'
          } flex justify-center items-center fixed top-0 left-0 w-full bg-gray-900 h-full bg-opacity-75 z-50`}
      >
        <Card classname='max-h-[95%] max-w-[95%] overflow-x-hidden overflow-y-auto'>
          <button
            className='text-white absolute right-2 top-0 text-lg cursor-pointer'
            onClick={onClose}
          >
            &times;
          </button>
          {children}
        </Card>
      </div>
    </>,
    document.getElementById('modal-root')
  )
}

export default Modal
