import React from 'react'
import DeleteButton from '../DeleteButton'

function FormButtons({ handleDeletion, handleMove }) {
	return (
		<div className='absolute top-0 right-0'>
			<button
				onClick={() => handleMove('up')}
				className='bg-gray-600 py-2 px-3 rounded-bl-md hover:bg-gray-700 ease-in-out duration-200'
			>
				<img src='/img/arrow.svg' className='w-[20px]' alt='' />
			</button>
			<button
				onClick={() => handleMove('down')}
				className='bg-gray-600 py-2 px-3 hover:bg-gray-700 ease-in-out duration-200'
			>
				<img src='/img/arrow.svg' className='w-[20px] rotate-180' alt='' />
			</button>
			<DeleteButton handleDelete={handleDeletion} />
		</div>
	)
}

export default FormButtons
