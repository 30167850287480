import {Link} from 'react-router-dom';
import CheckRole from '../../../components/CheckRole';
import DangerButton from '../../../components/DangerButton';
import CopyToClipboard from '../../../components/CopyToClipboard';

export function UsersActionColumn ({rowData, handleDelete}) {
  return (
    <>
      <CheckRole has='view_users'>
        <Link
          to={`/users/${rowData._id}`}
          className='bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200'
        >
          <img className='max-w-[25px] h-[25px] object-contain' src='/img/view.svg' alt='' />
        </Link>
      </CheckRole>
      <CheckRole has='delete_users'>
        <DangerButton onClick={() => handleDelete(rowData._id)} />
      </CheckRole>
      <CopyToClipboard text={rowData._id} />
    </>
  )
}