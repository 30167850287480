import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card';
import {PageHeading} from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import ActiveForm from '../../models/ActiveForm';
import { ActiveFormsForm } from './components/form';

export function CreateActiveFormPage() {
  const {l} = useLocale();
  const navigate = useNavigate()

  async function onSbumit(data) {
    await ActiveForm.create(data)
    navigate('/active-forms')
  }

  return (
    <>
      <PageHeading title={l('add_active_form')} image={'/img/active.svg'}></PageHeading>

      <Card>
        <ActiveFormsForm onSubmit={onSbumit} />
      </Card>
    </>
  )
}