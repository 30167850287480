import React from 'react'
import Card from '../../components/Card'
import { PageHeading } from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import { Link } from 'react-router-dom'
import Modal from '../../components/Modal'
import { Input } from '../../components/FormComponents'
import useRules from '../../hooks/useRules'
import CheckRole from '../../components/CheckRole'
import Button from '../../components/Button'
import DangerButton from '../../components/DangerButton'
import Paginator from '../../components/Paginator'

function RuleSets() {
	const { l } = useLocale()
	const {
		openModal,
		sets,
		handleAdd,
		handleDelete,
		showModal,
		closeModal,
		nameRef,
		search,
		setSearch,
		setPage,
	} = useRules()

	return (
		<>
			<PageHeading title={l('rulesets')} image='/img/flow.svg'>
				<CheckRole has='add_rulesets'>
					<Button onClick={openModal}>{l('add')}</Button>
				</CheckRole>
			</PageHeading>
			<Card>
				<Input
					className='w-64'
					value={search}
					onChange={e => setSearch(e.target.value)}
					placeholder={l('search')}
				/>
				<table className='w-full'>
					<thead>
						<tr className='text-left border-b border-gray-600'>
							<th>{l('name')}</th>
							<th>{l('actions')}</th>
						</tr>
					</thead>
					<tbody>
						{sets.docs?.map(set => (
							<tr key={set._id}>
								<td>{set.name}</td>
								<td className='flex gap-3'>
									<CheckRole has='view_rulesets'>
										<Link
											to={`/rulesets/${set._id}`}
											className='bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200'
										>
											<img className='w-[25px]' src='/img/view.svg' alt='' />
										</Link>
									</CheckRole>
									<CheckRole has='delete_rulesets'>
										<DangerButton onClick={() => handleDelete(set._id)} />
									</CheckRole>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<Paginator meta={sets} setPage={setPage} />
			</Card>
			<Modal show={showModal} onClose={closeModal}>
				<Input name='name' label={l('name')} reference={nameRef} />
				<button
					className='bg-gray-900 w-full py-1 rounded-md hover:bg-gray-600 ease-in-out duration-200'
					onClick={handleAdd}
				>
					{l('add')}
				</button>
			</Modal>
		</>
	)
}

export default RuleSets
