import React from 'react'
import useLocale from './useLocale'
import Rule from '../models/Rule'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import useModal from '../hooks/useModal'

function useRule() {
	const { l } = useLocale()
	const [rules, setRules] = React.useState({
		name: '',
		rules: [],
	})
	const { id } = useParams()
	const [selected, setSelected] = React.useState({ mails: [] })
	const { showModal, openModal, closeModal } = useModal()

	const TRIGGERS = [
		{
			label: l('save'),
			value: 'save',
		},
		{
			label: l('send'),
			value: 'send',
		},
		{
			label: l('deny'),
			value: 'deny',
		},
	]

	React.useEffect(() => {
		Rule.show(id)
			.then(res => {
				setRules(res)
			})
			.catch(err => toast.error(l('general_error')))
	}, [id])

	const handleAdd = () => {
		setRules(old => ({
			...old,
			rules: [...old.rules, {}],
		}))
	}

	const handleSelect = (e, key) => {
		const { value, name } = e.target

		let aux = rules
		aux.rules[key] = {
			...aux.rules[key],
			[name]: value,
		}

		setRules({ ...aux })
	}

	const handleDeleteRow = key => {
		let aux = rules
		aux.rules.splice(key, 1)
		setRules({ ...aux })
	}

	const handleSave = () => {
		if (rules._id) {
			Rule.update(rules, rules._id)
				.then(res => {
					toast.success(l('rules_updated'))
				})
				.catch(err => toast.error(l('general_error')))
			return
		}
		Rule.save(rules)
			.then(res => {
				toast.success(l('rules_saved'))
			})
			.catch(err => toast.error(l('general_error')))
	}

	const handleRuleSelect = idx => {
		setSelected(idx)
		openModal()
	}

	const handleSelectEmail = e => {
		const { name } = e.target

		const index = rules.rules[selected].mails.indexOf(name)

		let aux = rules
		if (index === -1) {
			aux.rules[selected].mails.push(name)
		} else {
			aux.rules[selected].mails.splice(index, 1)
		}

		setRules({ ...aux })
	}

	return {
		handleAdd,
		handleDeleteRow,
		rules,
		TRIGGERS,
		handleSelect,
		handleSave,
		handleRuleSelect,
		selected,
		openModal,
		closeModal,
		showModal,
		handleSelectEmail,
	}
}

export default useRule
