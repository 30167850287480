import Button from "../Button";
import useLocale from "../../hooks/useLocale";
import useModal from "../../hooks/useModal";
import Modal from "../Modal";
import useConfigurations from "../../hooks/useConfigurations";
import { H2 } from "../Headings";
import { Select } from "../FormComponents";

function ConfigSelection({ handleChange, config }) {
  const { l } = useLocale()
  const { showModal, closeModal, openModal } = useModal()
  const { configSets } = useConfigurations(false)

  return <>
    <Button onClick={ openModal }>
      <img src="/img/cog.svg" className={ 'w-[25px]' } alt=""/>
    </Button>
    <Modal show={ showModal } onClose={ closeModal }>
      <H2>{ l('config') }</H2>
      <Select
        label={ l('config') }
        name={ 'config' }
        valueKey={ '_id' }
        labelKey={ 'name' }
        value={ config }
        onChange={ handleChange }
        options={ configSets }
      />
      <Button
        type={ 'secondary' }
        onClick={ closeModal }
        full
      >
        { l('accept') }
      </Button>
    </Modal>
  </>
}

export default ConfigSelection