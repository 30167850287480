import Fetch from './Fetch'

class Permission {
	fetch = null

	static {
		this.fetch = new Fetch()
	}

	static get() {
		this.fetch.endpoint = 'permissions'
		this.fetch.method = 'GET'
		return this.fetch.performRequest()
	}
}

export default Permission
