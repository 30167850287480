import React from 'react';

export function ExchangeStateColumn({ columnData, rowData }) {
  const formatDate = dateString => {
    if (!dateString) {
      return 'Pendiente';
    }

    const date = new Date(dateString);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  };

  const formattedDate = React.useMemo(() => {
    return formatDate(rowData[columnData.accessor]);
  }, [rowData, columnData.accessor]);

  return <span className="px-4 py-0.5 inline-block min-w-[120px]">{formattedDate}</span>;
}
