import React from 'react'
import Button from '../Button'
import useLocale from '../../hooks/useLocale'
import useModal from '../../hooks/useModal'
import Modal from '../Modal'
import ClinicCase from '../../models/ClinicCase'
import { H2 } from '../Headings'
import { Checkbox } from '../FormComponents'
import CheckRole from '../CheckRole'
import { toast } from 'react-toastify'
import Export from '../../models/Export'

function ExportClinicCases (form) {
  const { l } = useLocale()
  const { showModal, closeModal, openModal } = useModal()
  const [fields, setFields] = React.useState([])
  const [selected, setSelected] = React.useState({})

  const getFields = async () => {
    try {
      const res = await ClinicCase.getFields([form.form])
      setFields(res)
      openModal()
    } catch (err) {
      toast.error(l('general_error'))
    }
  }

  const getSaved = async () => {
    const res = await Export.get(form.form)
    if (res === null) return

    setSelected(res.fields)
  }

  const startExportProcess = async e => {
    await Promise.all([
      getFields(),
      getSaved()
    ])
  }

  const handleChange = (e, data) => {
    const { checked, name } = e.target
    if (checked === true) {
      setSelected(old => ({
        ...old,
        [name]: {
          ...data
        }
      }))
    } else {
      const aux = selected
      delete aux[name]
      setSelected({ ...aux })
    }
  }

  const handleSubmit = () => {
    ClinicCase.export({ forms: [form.form], fields: selected })
      .then(blob => {
        var a = document.createElement('a')
        a.href = window.URL.createObjectURL(blob)
        a.download = `clinic_cases.xlsx`
        a.click()
      })
      .catch(() => toast.error(l('general_error')))
      .finally(() => {
        Export.save({ selected, form: form.form }).catch(err => toast.error(l('save_export_fields_error')))
      })
  }

  return (
    <>
      <CheckRole has="export_clinic_cases">
        <Button onClick={startExportProcess} type='warning'>{l('export')}</Button>
      </CheckRole>
      <Modal show={showModal} onClose={closeModal}>
        <H2>{l('select_fields')}</H2>
        <div className="columns-2 my-3">
          {fields.map(field => <Checkbox
            onChange={e => handleChange(e, field)}
            label={`${field.form.name} > ${field.section !== null ? `${field.section} > ` : ''}${field.fieldName}`}
            name={`${field.form.name}_${field.section !== null ? `${field.section}_` : ''}${field.fieldName}`}
            checked={`${field.form.name}_${field.section !== null ? `${field.section}_` : ''}${field.fieldName}` in selected}
          />)}
        </div>
        <Button onClick={handleSubmit} type="warning" full>{l('export')}</Button>
      </Modal>
    </>
  )
}

export default ExportClinicCases