import React from 'react'
import Divider from '../Divider'
import Button from '../Button'
import useLocale from '../../hooks/useLocale'
import Answer from '../../models/Answer'

function Question ({ question, id, item }) {
  const inputRef = React.useRef([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [answer, setAnswer] = React.useState({})
  const { l } = useLocale()

  async function handleAnswer () {
    const checkedInput = inputRef.current.find(input => input.checked === true)
    const { value } = checkedInput
    const response = await Answer.answer(id, item, question, value)
    setAnswer(response)
  }

  const checkQuestion = React.useCallback(async () => {
    const answer = await Answer.check(question._id, id)
    setAnswer(answer)
    setIsLoading(false)
  }, [id, question])

  React.useEffect(() => {
    checkQuestion()
  }, [checkQuestion])

  if (isLoading) return;

  console.log(question)

  return (
    !answer.answered ? <div>
      <label className='font-bold'>{l('question')}:</label>
      <p>{question.question}</p>
      <Divider />
      {question.possibleAnswers.map((possibleAnswer, index) => <div>
        <label>
          <input
            type="radio"
            value={possibleAnswer.answer}
            name={question.question}
            ref={el => (inputRef.current[index] = el)}
          /> {possibleAnswer.answer}
        </label>
      </div>)}
      <Button type='secondary' onClick={handleAnswer} full className='mt-2'>
        {l('send')}
      </Button>
    </div> :
      <div>
        <p><strong>Question:</strong> {question.question}</p>
        <p><strong>Your answer:</strong> {answer.answer.answer}</p>
        <div>
          <img src={answer.answer.isCorrect ? '/img/check.svg' : '/img/cross.svg'} className="max-w-[10rem] mx-auto" alt="" />
        </div>
        <span className="font-bold w-full">Justification:</span>
        <div>
          {answer.answer.question.justification}
        </div>
      </div>
  )
}

export default Question