import React from 'react'
import { H2, PageHeading } from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import Card from '../../components/Card'
import Log from '../../models/Log'
import Paginator from '../../components/Paginator'
import { Input, Select } from '../../components/FormComponents'
import Modal from '../../components/Modal'
import useModal from '../../hooks/useModal'
import Button from '../../components/Button'
import Code from '../../components/Code'
import Accordion from '../../components/Accordion'

function Logs() {
	const { l } = useLocale()
	const [logs, setLogs] = React.useState({ docs: [] })
	const [page, setPage] = React.useState(1)
	const { openModal, showModal, closeModal } = useModal()
	const [codes, setCodes] = React.useState({})
	const [filters, setFilters] = React.useState({
		path: '',
		method: '',
		from: '',
		to: '',
	})

	React.useEffect(() => {
		Log.get({ page, filters }).then(res => setLogs(res))
	}, [page, filters])

	const handleChange = e => {
		const { value, name } = e.target
		setFilters(old => ({
			...old,
			[name]: value,
		}))
	}

	const options = [
		{ name: 'GET' },
		{ name: 'POST' },
		{ name: 'PUT' },
		{ name: 'DELETE' },
	]

	const handleOpen = ({
		query = {},
		response = {},
		cookies = {},
		user = {},
		body = {},
		old = {},
	}) => {
		setCodes({ query, response, cookies, user, body, old })
		openModal()
	}

	return (
		<div>
			<PageHeading title={l('logs')} image={'/img/logs.svg'}></PageHeading>
			<Card>
				<div className='flex gap-3'>
					<Input
						type='date'
						label={l('from')}
						name={'from'}
						onChange={handleChange}
					/>
					<Input
						type='date'
						label={l('to')}
						name='to'
						onChange={handleChange}
					/>
					<Input
						label={l('path')}
						value={filters.path}
						name={'path'}
						onChange={handleChange}
					/>
					<Select
						onChange={handleChange}
						label={l('http_verb')}
						value={filters.method}
						options={options}
						name={'method'}
						labelKey={'name'}
						valueKey={'name'}
					/>
				</div>
				<table className='w-full'>
					<thead>
						<tr className='text-left border-b border-gray-600'>
							<th>{l('started')}</th>
							<th>{l('path')}</th>
							<th>{l('user')}</th>
							<th>{l('http_verb')}</th>
							<th>{l('ip')}</th>
							<th>{l('response_status')}</th>
							<th>{l('actions')}</th>
						</tr>
					</thead>
					<tbody>
						{logs.docs.map(log => (
							<tr key={log._id}>
								<td>{new Date(log.startedAt).toLocaleString()}</td>
								<td>{log.path}</td>
								<td>{log.user?.name}</td>
								<td>{log.httpVerb}</td>
								<td>{log.ip}</td>
								<td>{log.httpResponseCode}</td>
								<td>
									<Button onClick={() => handleOpen(log)}>
										<img src='/img/info.svg' className='w-[20px]' alt='' />
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<Paginator meta={logs} setPage={setPage} />
			</Card>
			<Modal show={showModal} onClose={closeModal}>
				<H2>{l('info')}</H2>
				<Accordion title={l('body')}>
					<Code code={codes.body} />
				</Accordion>
				<Accordion title={l('user')}>
					<Code code={codes.user} />
				</Accordion>
				<Accordion title={l('cookies')}>
					<Code code={codes.cookies} />
				</Accordion>
				<Accordion title={l('response')}>
					<Code code={codes.response} />
				</Accordion>
				<Accordion title={l('query')}>
					<Code code={codes.query} />
				</Accordion>
				<Accordion title={l('old')}>
					<Code code={codes.old} />
				</Accordion>
			</Modal>
		</div>
	)
}

export default Logs
