import Fetch from './Fetch'

class Origin {
	fetch = null

	static {
		this.fetch = new Fetch()
	}

	static get() {
		this.fetch.endpoint = 'origins'
		this.fetch.method = 'GET'
		return this.fetch.performRequest()
	}

	static create(data) {
		this.fetch.endpoint = 'origins'
		this.fetch.method = 'POST'
		this.fetch.body = data
		return this.fetch.performRequest()
	}

	static delete(id) {
		this.fetch.endpoint = `origins/${id}`
		this.fetch.method = 'DELETE'
		return this.fetch.performRequest()
	}
}

export default Origin
