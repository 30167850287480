import React from "react"

function useModal() {
	const [showModal, setShowModal] = React.useState(false)

	function closeModal() {
		setShowModal(false)
	}

	function openModal() {
		setShowModal(true)
	}

	function toggleModal() {
		setShowModal(old => !old)
	}

	return { showModal, closeModal, openModal, toggleModal }
}

export default useModal
