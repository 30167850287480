import React from 'react'
import CheckRole from '../../../components/CheckRole'
import {Link} from 'react-router-dom'

function ActionColumn ({rowData}) {
  return (
    <>
      <CheckRole has='view_contacts'>
        <Link
          to={`/consultations/${rowData._id}`}
          className='bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200'
        >
          <img className='w-[25px]' src='/img/view.svg' alt='' />
        </Link>
      </CheckRole>
    </>
  )
}

export default ActionColumn