import React from 'react'
import useLocale from '../../hooks/useLocale'
import useForms from '../../hooks/useForms'
import { Checkbox } from '../../components/FormComponents'

function FormPermissions({ selectedRole, handleRoleChange }) {
  const { l } = useLocale()
  const { forms } = useForms({ paginate: false })

  return (
    <>
      <p className='font-bold my-2'>{ l('form_permissions') }</p>
      <div className='columns-3'>
        { forms.map(form => (
          <React.Fragment key={ form._id }>
            <Checkbox
              name={ form._id }
              checked={ selectedRole.forms.includes(form._id) }
              onChange={ e => handleRoleChange(e, 'forms') }
              label={ form.name }
            />
          </React.Fragment>
        )) }
      </div>
    </>
  )
}

export default FormPermissions
