import React from 'react'
import AppContext from '../../contexts/AppContext'

function LocaleButtons() {
	const { locale, setLocale } = React.useContext(AppContext)

	return (
		<div className={` bottom-0 w-full justify-center flex flex-row gap-3 my-3`}>
			<button
				className={`${locale === 'es' && 'font-bold'}`}
				onClick={() => setLocale('es')}
			>
				ES
			</button>
			<button
				className={`${locale === 'en' && 'font-bold'}`}
				onClick={() => setLocale('en')}
			>
				EN
			</button>
		</div>
	)
}

export default LocaleButtons
