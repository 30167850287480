import React from 'react'
import ClinicCase from '../models/ClinicCase'

function useAnswerClinicCases () {
  const [clinicCases, setClinicCases] = React.useState({ docs: [] })
  const [page, setPage] = React.useState(1)

  React.useEffect(() => {
    get()
  }, [page])

  function get () {
    ClinicCase.published(page).then(data => setClinicCases(data))
  }

  return {
    clinicCases,
    page,
    setPage
  }
}

export default useAnswerClinicCases