import React from 'react'
import useLocale from '../../hooks/useLocale'
import useStates from '../../hooks/useStates'
import { Checkbox } from '../FormComponents'

function StatesPermissions({ selectedRole, handleRoleChange }) {
  const { l } = useLocale()
  const { states } = useStates(false)

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
      <div>
        <p className='font-bold my-2'>{ l('derive_state_permissions') }</p>
        <div className='columns-3'>
          { states.map(state => (
            <React.Fragment key={ state._id }>
              <Checkbox
                name={ state._id }
                checked={ selectedRole.states.includes(state._id) }
                onChange={ e => handleRoleChange(e, 'states') }
                label={ state.name }
              />
            </React.Fragment>
          )) }
        </div>
      </div>
      <div>
        <p className='font-bold my-2'>{ l('view_state_permissions') }</p>
        <div className='columns-3'>
          { states.map(state => (
            <React.Fragment key={ state._id }>
              <Checkbox
                name={ state._id }
                id={ `view_${ state._id }` }
                checked={ selectedRole.viewStates.includes(state._id) }
                onChange={ e => handleRoleChange(e, 'viewStates') }
                label={ state.name }
              />
            </React.Fragment>
          )) }
        </div>
      </div>
      <div>
        <p className='font-bold my-2'>{l('edit_state_permissions')}</p>
        <div className='columns-3'>
          {states.map(state => (
            <React.Fragment key={state._id}>
              <Checkbox
                name={state._id}
                id={`edit_${state._id}`}
                checked={selectedRole.editableStates.includes(state._id)}
                onChange={e => handleRoleChange(e, 'editableStates')}
                label={state.name}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default StatesPermissions
