import React from 'react'
import Card from '../../components/Card'
import { Input } from '../../components/FormComponents'
import Tab from '../../components/Tab'
import Button from '../../components/Button'
import useLocale from '../../hooks/useLocale'
import { useSearchParams } from 'react-router-dom'
import useProduct from '../../hooks/useProduct'

const componentsMap = {
  'Basic': React.lazy(() => import('../ProductTabs/Basic')),
  'Quantities': React.lazy(() => import('../ProductTabs/Quantities')),
  'Shipping': React.lazy(() => import('../ProductTabs/Shipping')),
  'Pricing': React.lazy(() => import('../ProductTabs/Pricing')),
  'Form': React.lazy(() => import('../ProductTabs/Form')),
  'Attributes': React.lazy(() => import('../ProductTabs/Attributes')),
  'Options': React.lazy(() => import('../ProductTabs/Options'))
}

function ProductForm ({ initData = null, product = null }) {
  const { l } = useLocale()
  const [params] = useSearchParams()
  const [currentTab, setCurrentTab] = React.useState(params.get('tab') || 'Basic')
  const {
    handleChange,
    productInfo,
    handleCheck,
    handleSetImage,
    updateImage,
    handleSaveFile,
    handleRemoveFile,
    handleEditFile,
    handleAddAttribute,
    handleRemoveAttribute,
    handleRemoveImage,
    handleSave,
    handleChangeTag
  } = useProduct(initData, product)

  React.useEffect(() => {
    setCurrentTab(params.get('tab') || 'Basic')
  }, [params])

  return (
    <>

      <Card>
        <Input
          label={l('name')}
          name='name'
          onChange={handleChange}
          value={productInfo.name}
        />

        <div class="text-sm font-medium text-center my-5">
          <ul class="flex flex-wrap -mb-px">
            <li class="mr-2">
              <Tab active={currentTab === 'Basic'} label={l('basic')} tab="Basic" onClick={() => setCurrentTab('Basic')} />
            </li>
            <li class="mr-2">
              <Tab active={currentTab === 'Quantities'} label={l('quantities')} tab="Quantities" onClick={() => setCurrentTab('Quantities')} />
            </li>
            <li class="mr-2">
              <Tab active={currentTab === 'Shipping'} label={l('shipping')} tab="Shipping" onClick={() => setCurrentTab('Shipping')} />
            </li>
            <li class="mr-2">
              <Tab active={currentTab === 'Pricing'} label={l('pricing')} tab="Pricing" onClick={() => setCurrentTab('Pricing')} />
            </li>
            {product && <li class="mr-2">
              <Tab active={currentTab === 'Form'} label={l('form')} tab="Form" onClick={() => setCurrentTab('Form')} />
            </li>}
            <li class="mr-2">
              <Tab active={currentTab === 'Attributes'} label={l('attributes')} tab="Attributes" onClick={() => setCurrentTab('Attributes')} />
            </li>
            <li class="mr-2">
              <Tab active={currentTab === 'Options'} label={l('options')} tab="Options" onClick={() => setCurrentTab('Options')} />
            </li>
          </ul>
        </div>

        <React.Suspense fallback={<p>Loading...</p>}>
          {React.createElement(componentsMap[currentTab], {
            ...productInfo,
            handleChange,
            handleCheck,
            handleSetImage,
            updateImage,
            handleSaveFile,
            handleRemoveFile,
            handleEditFile,
            handleChangeTag,
            handleAddAttribute,
            handleRemoveAttribute,
            handleRemoveImage
          })}
        </React.Suspense>
      </Card>
      <Button onClick={handleSave}>
        {l('save')}
      </Button>
    </>
  )
}

export default ProductForm