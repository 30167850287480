import React from 'react'
import Origin from '../models/Origin'
import useLocale from './useLocale'
import { toast } from 'react-toastify'
import useModal from '../hooks/useModal'

function useOrigins() {
	const [origins, setOrigins] = React.useState([])
	const { openModal, showModal, closeModal } = useModal()
	const inputRef = React.useRef(null)
	const { l } = useLocale()

	const handleSubmit = e => {
		e.preventDefault()
		Origin.create({ origin: inputRef.current.value })
			.then(() => {
				toast.success(l('origin_created'))
				getOrigins()
				closeModal()
				inputRef.current.value = ''
			})
			.catch(() => toast.error(l('general_error')))
	}

	const handleDelete = id => {
		Origin.delete(id).then(() => getOrigins())
	}

	const getOrigins = () => {
		Origin.get()
			.then(origins => setOrigins(origins))
			.catch(err => toast.error(l('general_error')))
	}

	React.useEffect(() => {
		getOrigins()
	}, [])

	return {
		origins,
		openModal,
		showModal,
		closeModal,
		inputRef,
		handleSubmit,
		handleDelete,
	}
}

export default useOrigins
