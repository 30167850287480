import React from 'react'
import Card from '../../components/Card'
import { H1, H2 } from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import { Checkbox, Select } from '../../components/FormComponents'
import useStates from '../../hooks/useStates'
import Divider from '../../components/Divider'
import useRule from '../../hooks/useRule'
import CheckRole from '../../components/CheckRole'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import useEmails from '../../hooks/useEmails'

function ShowRuleSets() {
	const {
		rules,
		handleAdd,
		handleSelect,
		handleSave,
		handleDeleteRow,
		showModal,
		handleRuleSelect,
		closeModal,
		selected,
		handleSelectEmail,
	} = useRule()
	const { mails } = useEmails(false)
	const { l } = useLocale()
	const { states } = useStates(false)

	return (
		<>
			<H1>
				<img src='/img/flow.svg' className='w-[35px]' alt='' />
				{l('ruleset')} {rules.name}
			</H1>
			<Card>
				<Button onClick={handleAdd}>{l('add')}</Button>
				{rules.rules.map(({ current_state, next_state }, key) => {
					return (
						<React.Fragment key={key}>
							<div className='flex gap-5 flex-wrap mb-2'>
								<Select
									label={l('current_state')}
									options={states}
									value={current_state || ''}
									name='current_state'
									onChange={e => handleSelect(e, key)}
									labelKey='name'
									valueKey='_id'
								/>
								<Select
									label={l('next_state')}
									options={states}
									name='next_state'
									onChange={e => handleSelect(e, key)}
									value={next_state || ''}
									labelKey='name'
									valueKey='_id'
								/>
								<Button
									onClick={() => handleDeleteRow(key)}
									type='danger'
									className='self-end mb-2'
								>
									<img
										src='/img/trash.svg'
										alt=''
										className='w-full max-w-[20px]'
									/>
								</Button>
								{/* <CheckRole has='set_emails_on_rule'> */}
								<Button
									className='self-end mb-2'
									onClick={() => handleRuleSelect(key)}
								>
									<img
										src='/img/email.svg'
										className='w-full max-w-[20px]'
										alt=''
									/>
								</Button>
								{/* </CheckRole> */}
							</div>
							<Divider />
						</React.Fragment>
					)
				})}
			</Card>
			<Modal show={showModal} onClose={closeModal}>
				<H2>{l('emails')}</H2>
				{mails.map(mail => (
					<Checkbox
						key={mail._id}
						checked={rules.rules[selected]?.mails.includes(mail._id)}
						onChange={handleSelectEmail}
						name={mail._id}
						label={mail.name}
					/>
				))}
			</Modal>
			<CheckRole has='edit_rulesets'>
				<Button onClick={handleSave}>{l('save')}</Button>
			</CheckRole>
		</>
	)
}

export default ShowRuleSets
