import React from 'react'
import useAnswerClinicCases from '../../hooks/useAnswerClinicCases'
import { PageHeading } from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import Card from '../../components/Card'
import Paginator from '../../components/Paginator'
import { Link } from 'react-router-dom'

function AnswerClinicCases () {
  const { l } = useLocale()
  const { clinicCases, setPage } = useAnswerClinicCases()

  return (
    <>
      <PageHeading title={l('answer_clinic_cases')} image='/img/answer.svg'>
      </PageHeading>
      <Card>
        <table className="w-full">
          <thead>
            <tr className='text-left border-b border-gray-600'>
              <th>{l('form')}</th>
              <th>{l('user')}</th>
              <th>{l('difficulty')}</th>
              <th>{l('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {clinicCases.docs.map(clinicCase => <>
              <tr>
                <td>{clinicCase.form.name}</td>
                <td>{clinicCase.user.name}</td>
                <td>{clinicCase.difficulty?.label} ({clinicCase.difficulty?.points} pts.)</td>
                <td className='flex gap-3'>
                  <Link to={`/answer-clinic-cases/${clinicCase._id}`} className='bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200'>
                    {l('answer_clinic_case')}
                  </Link>
                </td>
              </tr>
            </>)}
          </tbody>
        </table>
      </Card>
      <Paginator meta={clinicCases} setPage={setPage} />
    </>
  )
}

export default AnswerClinicCases