import {toast} from 'react-toastify';
import Button from '../Button'
import useLocale from '../../hooks/useLocale';

function CopyToClipboard ({text}) {
  const {l} = useLocale();
  const handleCopy = async () => {
    await navigator.clipboard.writeText(text)
    toast.success(l('copied'))
  };

  return (
    <Button onClick={handleCopy}>
      <img src="/img/copy.svg" className='max-w-[25px]' alt="" />
    </Button>
  );
};


export default CopyToClipboard;
