import React from 'react'
import LocaleButtons from '../LocaleButtons'
import LogoutButton from '../LogoutButton'
import Divider from '../Divider'

function Sidebar({ children }) {
	return (
		<>
			<aside className='min-h-screen w-72 overflow-y-auto bg-gray-900 scrollbar-hide'>
				<LogoutButton />
				<LocaleButtons />
				<Divider />
				<div className='py-4 px-3 rounded'>{children}</div>
			</aside>
		</>
	)
}

export default Sidebar
