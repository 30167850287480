import React from 'react'
import Card from '../../components/Card'
import {H1} from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import componentsMap from '../../config/answerComponents'
import useAnswer from '../../hooks/useAnswer'
import Divider from '../../components/Divider'
import Button from '../../components/Button'
import {textColor} from '../../utils'
import useQuestions from '../../hooks/useQuestions'
import FormQuestions from '../../components/FormQuestions'
import {Select} from '../../components/FormComponents'


function AnswerForm () {
  const {l} = useLocale()
  const {
    form,
    handleCheckboxChange,
    handleInputChange,
    handleAction,
    errors,
    handleAddRow,
    handleDeleteRow,
    handleTagChange,
    setForm,
    handleFileSelect,
    handleMetaChange,
    handlePushGalleryFile,
    handleAddGalleryItem,
    files,
    handleDeleteFile,
    handleSetMetaField,
    handleSetConsentDocument,
    handleOptinChange
  } = useAnswer()
  const {
    handleViewQuestions,
    ...restOfQuestionsLogic
  } = useQuestions({form, setForm})

  const selectComiteRef = React.useRef(null)

  function handleSendToComite (event) {
    const rule = form.actions?.find(action => action._id === selectComiteRef.current.value)
    handleAction(rule)
  }

  const comiteStates = form.actions?.filter(action => action.isComiteState === true)
  const noComiteStates = form.actions?.filter(action => action.isComiteState === false || action.isComiteState == null)
  const hasComiteStates = comiteStates.length > 0;

  return (
    <>
      <H1>
        <img src='/img/form.svg' className='w-[35px]' alt='' />
        {l('answer_form')} {form.name}
      </H1>
      <Card>
        <div className='grid grid-cols-12 gap-3'>
          {Object.keys(form.items).map(key => {
            const item = form.items[key]
            const error = errors.find(
              error =>
                (error.key === item.attributes?.label || error.key === item._id) && error.passed === false
            )
            return (
              <div style={{gridColumn: `span ${item.attributes.cols || 12} / span ${item.attributes.cols || 12}`}} key={key}>
                {React.createElement(componentsMap[item.component], {
                  item,
                  handleInputChange,
                  handleCheckboxChange,
                  index: key,
                  handleAddRow,
                  handleDeleteRow,
                  errors,
                  handleTagChange,
                  handleViewQuestions,
                  handleFileSelect,
                  handleMetaChange,
                  handlePushGalleryFile,
                  handleAddGalleryItem,
                  files,
                  handleSetConsentDocument,
                  handleDeleteFile,
                  handleSetMetaField,
                  handleOptinChange,
                  ...restOfQuestionsLogic
                })}
                {error && error.message.map(message => <p className='text-sm text-red-500'>{l(message)}</p>)}
                {item.attributes.has_questions && (
                  <Button type='warning' onClick={() => handleViewQuestions(item, key)}>
                    {l('view_questions')}
                  </Button>
                )}
              </div>
            )
          })}
        </div>
        <Divider />
      </Card>
      <div className='flex justify-between'>
        <div className={'flex gap-3 items-center'}>
          {noComiteStates.map((rule, idx) => (
            <Button
              key={rule._id}
              style={{background: rule.color, color: textColor(rule.color)}}
              onClick={() => handleAction(rule)}
            >
              {rule.label}
            </Button>
          ))}
        </div>
        {hasComiteStates && (
          <div className='flex gap-3 items-end'>
            <Select
              label={'Enviar a comite'}
              options={comiteStates.map((rule) => ({
                label: rule.label, value: rule._id
              }))}
              reference={selectComiteRef}
            />
            <Button onClick={handleSendToComite} className='my-2'>
              {l('send')}
            </Button>
          </div>
        )}
      </div>

      <FormQuestions {...restOfQuestionsLogic} />
    </>
  )
}

export default AnswerForm
