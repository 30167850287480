import React from 'react'
import Button from '../../components/Button'
import CheckRole from '../../components/CheckRole'
import useLocale from '../../hooks/useLocale'
import useModal from '../../hooks/useModal'
import { Select } from '../FormComponents'
import { H2 } from '../Headings'
import Modal from '../Modal'
import User from '../../models/User'
import { toast } from 'react-toastify'
import useForms from '../../hooks/useForms'

function RememberPassword({ email = '' }) {
	const { l } = useLocale()
	const { openModal, closeModal, showModal } = useModal()
	const formRef = React.useRef(null)
	const { forms } = useForms({ paginate: false })

	const handleClick = e => {
		const { value: form } = formRef.current
		toast.promise(User.rememberPassword({ email, form }), {
			pending: l('processing'),
			success: l('email_sended'),
			error: l('general_error'),
		})
	}

	return (
		<>
			<CheckRole has='send_remember_password_mail'>
				<Button onClick={openModal}>{l('send_remember_password_mail')}</Button>
			</CheckRole>
			<Modal onClose={closeModal} show={showModal}>
				<H2>{l('send_remember_password_mail')}</H2>
				<Select
					options={forms}
					labelKey='name'
					valueKey='_id'
					label={l('form')}
					reference={formRef}
				/>
				<Button onClick={handleClick} type='secondary' full>
					{l('send')}
				</Button>
			</Modal>
		</>
	)
}

export default RememberPassword
