import React from 'react'
import {toast} from 'react-toastify'
import Book from '../models/Book'
import useLocale from './useLocale'

function useBooks () {
  const {l} = useLocale()
  const [books, setBooks] = React.useState([])

  const getBooks = React.useCallback(async function () {
    Book.getAll().then(data => setBooks(data))
  }, [])

  React.useEffect(() => {
    getBooks()
  }, [getBooks])

  async function handleDelete (id) {
    try {
      await Book.delete(id)
      getBooks()
      toast.success(l('book_deleted'))
    } catch (err) {
      toast.error(l('general_error'))
    }
  }

  function handleClinicCaseToBook (bookId, clinicCaseId) {
    const aux = books.map(book => {
      if (book._id !== bookId) return book

      if (!book.clinicCases.some(bookCase => bookCase === clinicCaseId)) book.clinicCases.push(clinicCaseId)
      else {
        const index = book.clinicCases.indexOf(clinicCaseId)
        book.clinicCases.splice(index, 1)
      }

      return book
    })
    setBooks([...aux])
  }

  async function handleSyncClinicCases () {
    try {
      await Book.syncClinicCases(books)
      toast.success(l('books_assigned'))
    } catch (err) {
      toast.error(l('general_error'))
    }
  }

  return {
    books,
    handleDelete,
    handleClinicCaseToBook,
    handleSyncClinicCases
  }
}

export default useBooks