import React from 'react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import useLocale from '../../hooks/useLocale'

function Index ({value, handleChange}) {
  const {l} = useLocale()

	return (
		<CKEditor
			editor={Editor}
			data={value}
			config={{
				simpleUpload: {
					uploadUrl: `${process.env.REACT_APP_API_URL}images`,
					withCredentials: true,
				},
        link: {
          decorators: [
            {
              mode: 'manual',
              label: l('target_blank'),
              attributes: {
                target: '_blank'
              }
            }
          ]
        }
			}}
			onChange={handleChange}
		/>
	)
}

export default Index
