import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import useLocale from '../hooks/useLocale';
import Form from '../models/Form';
import User from '../models/User';

function useUsers(paginate = true, call = false) {
  const [users, setUsers] = React.useState([]);
  const [platforms, setPlatforms] = React.useState([]);
  const { l } = useLocale();

  const getUsers = useCallback(
    ({ page = 1, limit = 10, filters = {} } = {}) => {
      User.get(
        filters.name,
        page,
        limit,
        paginate,
        filters.email,
        filters['platform?.name'],
        filters.active
      )
        .then(res => {
          if (User.fetch.status !== 200) {
            return toast.error(l('general_error'));
          }
          setUsers(res);
        })
        .catch(err => toast.error(l('general_error')));
    },
    [paginate]
  );

  const getPlatforms = useCallback(async () => {
    const platforms = await Form.get(null, null, false);
    setPlatforms(platforms.map(platform => ({ label: platform.name, value: platform._id })));
  }, []);

  React.useEffect(() => {
    if (call === true) getUsers();
  }, [call]);

  React.useEffect(() => {
    getPlatforms();
  }, []);

  const handleDelete = id => {
    User.delete(id)
      .then(res => getUsers())
      .catch(err => toast.error(l('general_error')));
  };

  const exportXlsx = () => {
    User.export().then(blob => {
      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = `users.xlsx`;
      a.click();
    });
  };

  return {
    users,
    handleDelete,
    exportXlsx,
    getUsers,
    platforms,
  };
}

export default useUsers;
