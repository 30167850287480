import React from 'react';
import AppContext from '../../../contexts/AppContext';
import Form from '../../../models/Form';
import DangerButton from '../../../components/DangerButton';
import { DangerModalContent } from '../../ManageUsers/components/DangerModalContent';

export function StatusColumn({rowData, refresh}) {
  const {permissions} = React.useContext(AppContext);
  const hasPermissions = React.useMemo(() => permissions.find(item => item.name === 'activate_forms') != null, [permissions])

  const activeText = `${rowData.active}`;
  const labelsMap = {
    true: '/img/check.svg',
    false: '/img/cross.svg'
  }

  async function handleToggleAccount() {
    await Form.toggle(rowData._id);
    refresh()
  }
  
  return (
    <>
      <DangerButton 
        onClick={hasPermissions ? handleToggleAccount : null} 
        content={<DangerModalContent />}
        icon={null} 
        textContent={<img className='max-w-[30px]' src={labelsMap[activeText]} alt="" />} 
        className={`${hasPermissions ? 'cursor-pointer' : ''}`}
        type="raw" 
      />
    </>
  )
}