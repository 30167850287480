import React from 'react'
import { Checkbox, Input } from '../../components/FormComponents'
import { H2 } from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import usePermissions from '../../hooks/usePermissions'
import CheckRole from '../CheckRole'
import FormPermissions from '../FormPermissions'
import StatesPermissions from '../StatesPermissions'

function RolePermissions({
                           selectedRole,
                           handleRoleEdit,
                           handleInputChange,
                           handleRoleChange,
                         }) {
  const { l } = useLocale()
  const { permissions } = usePermissions()

  return (
    <div>
      { selectedRole !== null && (
        <>
          <H2>
            { l('permissions_for') } { selectedRole.name }
          </H2>
          <form onSubmit={ handleRoleEdit }>
            <Input
              label={ l('name') }
              name='name'
              value={ selectedRole.name }
              onChange={ handleInputChange }
            />
            <div className='columns-3'>
              { Object.keys(permissions).map(key => {
                const group = permissions[key]
                return (
                  <React.Fragment key={ key }>
                    <p className="font-bold">{ l(key) }</p>
                    { group.map(permission => (
                      <React.Fragment key={ permission._id }>
                        <Checkbox
                          name={ permission._id }
                          checked={ selectedRole.permissions.includes(permission._id) }
                          onChange={ handleRoleChange }
                          label={ permission.name }
                        />
                      </React.Fragment>
                    )) }
                  </React.Fragment>
                )
              }) }
            </div>
            <FormPermissions
              selectedRole={ selectedRole }
              handleRoleChange={ handleRoleChange }
            />
            <StatesPermissions
              selectedRole={ selectedRole }
              handleRoleChange={ handleRoleChange }
            />
            <CheckRole has='edit_roles'>
              <input
                type='submit'
                value={ l('send') }
                className='bg-gray-500 px-2 py-1 rounded-md text-white mt-5 cursor-pointer hover:bg-gray-900 ease-in-out duration-200'
              />
            </CheckRole>
          </form>
        </>
      ) }
    </div>
  )
}

export default RolePermissions
