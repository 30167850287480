import React from 'react'
import Button from "../Button";
import useLocale from "../../hooks/useLocale";

function Paginator({ meta, setPage }) {
  const { l } = useLocale()

  return <div className={ 'flex justify-center mt-5 mb-2 gap-3 items-center' }>
    <Button disabled={ meta.page === 1 } onClick={ () => setPage(1) }>{l('first')}</Button>
    <Button disabled={ meta.hasPrevPage === false } onClick={ () => setPage(meta.prevPage) }>{l('prev')}</Button>
    <span>{ l('page') } { meta.page } { l('of') } { meta.totalPages }</span>
    <Button disabled={ meta.hasNextPage === false } onClick={ () => setPage(meta.nextPage) }>{ l('next') }</Button>
    <Button disabled={ meta.page === meta.totalPages } onClick={ () => setPage(meta.totalPages) }>{ l('last') }</Button>
  </div>
}

export default Paginator