import React from 'react'
import {PageHeading} from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import Card from '../../components/Card'
import ImageGallery from '../../models/ImageGallery'
import Button from '../../components/Button'
import {Link} from 'react-router-dom'

function ImageGalleryPage () {
  const {l} = useLocale()
  const [items, setItems] = React.useState([])

  React.useEffect(() => {
    ImageGallery.list().then(data => setItems(data))
  }, [])

  const handleDownload = (uuid) => {
    ImageGallery.download(uuid)
      .then(blob => {
        var a = document.createElement('a')
        a.href = window.URL.createObjectURL(blob)
        a.download = `${uuid}.zip`
        a.click()
      })
  }

  return (
    <>
      <PageHeading title={l('image_gallery')} image='/img/image-gallery.svg'>
      </PageHeading>
      <Card>
        <table className='w-full'>
          <thead>
            <tr className='text-left border-b border-gray-600'>
              <th>uuid</th>
              <th>{l('name')}</th>
              <th>{l('user')}</th>
              <th>{l('clinic_case')}</th>
              <th>{l('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {items.map(item => <tr>
              <td>{item.uuid}</td>
              <td>{item.app.name}</td>
              <td>{item.external_user_id}</td>
              <td>{item.external_id}</td>
              <td className='flex gap-3'>
                <Link
                  to={`/image-gallery/${item.uuid}`}
                  className='bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200'
                >
                  <img className='w-[25px]' src='/img/view.svg' alt='' />
                </Link>
                <Button type="secondary" onClick={() => handleDownload(item.uuid)}>
                  <img src='/img/download.svg' className='w-[25px]' alt='' />
                </Button>
              </td>
            </tr>)}
          </tbody>
        </table>
      </Card>
    </>
  )
}

export default ImageGalleryPage