import React from 'react'
import { H2 } from '../Headings'
import useLocale from '../../hooks/useLocale'
import { Checkbox } from '../FormComponents'

function GamificationConfig ({ config, handleCheckboxChange, addClassifier, removeClassifier }) {
  const { l } = useLocale()

  return (
    <>
      <H2>
        <img src='/img/game.svg' className={'w-[25px]'} alt='' />{' '}
        {l('game_config')}
      </H2>

      <Checkbox checked={config.gamification?.enabled} name="enabled" onChange={handleCheckboxChange} label={l('enable')} />

      {/* {config.gamification?.enabled === true && <>
        <div className='grid grid-cols-2 gap-4'>
          <div>
            <div>
              <H3>
                {l('qualities')}{" "}
                <Button onClick={() => handleOpen('quality')}>
                  <img src="/img/plus_.svg" className='w-[15px]' alt="" />
                </Button>
              </H3>
            </div>
            <table className='w-full'>
              <thead>
                <tr className='border-b border-gray-600 text-left'>
                  <th>{l('label')}</th>
                  <th>{l('points')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {config.gamification?.qualities?.map((quality) => <tr key={quality._id}>
                  <td>
                    {quality.label}
                  </td>
                  <td>
                    {quality.points}
                  </td>
                  <td>
                    <Button type='danger' onClick={() => removeClassifier('qualities', quality._id)}>
                      <img src="/img/trash.svg" className='w-[20px]' alt="" />
                    </Button>
                  </td>
                </tr>)}
              </tbody>
            </table>
          </div>

          <div>
            <H3>
              {l('difficulties')} {" "}
              <Button onClick={() => handleOpen('difficulty')}>
                <img src="/img/plus_.svg" className='w-[15px]' alt="" />
              </Button>
            </H3>
            <table className='w-full'>
              <thead>
                <tr className='border-b border-gray-600 text-left'>
                  <th>{l('label')}</th>
                  <th>{l('points')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {config.gamification?.difficulties?.map((difficulty) => <tr key={difficulty._id}>
                  <td>
                    {difficulty.label}
                  </td>
                  <td>
                    {difficulty.points}
                  </td>
                  <td>
                    <Button type='danger' onClick={() => removeClassifier('difficulties', difficulty._id)}>
                      <img src="/img/trash.svg" className='w-[20px]' alt="" />
                    </Button>
                  </td>
                </tr>)}
              </tbody>
            </table>
          </div>
        </div>
        <ClassifiersForm addClassifier={addClassifier} {...modal} typeRef={typeRef} />
      </>} */}
    </>
  )
}

export default GamificationConfig