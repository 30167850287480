import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import Product from '../models/Product';
import useLocale from './useLocale';

function useUserExchanges(paginate = true) {
  const { l } = useLocale();
  const [exchanges, setExchanges] = React.useState({ docs: [] });
  const [page, setPage] = React.useState(1);

  const getExchanges = useCallback(
    ({ page = 1, limit = 10, filters = {} } = {}) => {
      Product.getExchanges(
        filters['user.name'],
        filters['product.name'],
        filters['sentDate'],
        page,
        limit
      )
        .then(res => setExchanges(res))
        .catch(() => toast.error(l('general_error')));
    },
    [l]
  );

  React.useEffect(() => {
    getExchanges();
  }, [page, getExchanges]);

  const handleUpdate = userExchangeInfo => {
    Product.updateExchange(userExchangeInfo._id, userExchangeInfo)
      .then(res => {
        toast.success(l('user_exchange_updated'));
        getExchanges({ page });
      })
      .catch(() => toast.error(l('general_error')));

    return;
  };

  const exportXlsx = () => {
    Product.exportXlsx()
      .then(blob => {
        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = `canjeos.xlsx`;
        a.click();
        toast.success('Descarga completada');
      })
      .catch(() => toast.error(l('general_error')));
  };

  return {
    exchanges,
    setPage,
    getExchanges,
    handleUpdate,
    exportXlsx,
  };
}

export default useUserExchanges;
