import React from 'react'
import { PageHeading } from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import ProductForm from '../../components/ProductForm'
import useProduct from '../../hooks/useProduct'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import ExchangeButton from '../../components/ExchangeButton'

function Show () {
  const { l } = useLocale()
  const { product, getProduct, productLoaded, id } = useProduct()
  const navigate = useNavigate()

  React.useEffect(() => getProduct(), [])

  return (
    <>
      <PageHeading title={`${l('view_product')} ${id}`} image={'/img/shopping_cart.svg'}>
        <Button type="warning" onClick={() => navigate('/products')} className='flex gap-2'>
          <img src="/img/back.svg" className='max-w-[20px]' alt="" />{l('return_to_products')}
        </Button>
        <ExchangeButton product={product} withText />
      </PageHeading>

      {productLoaded && <ProductForm product={product} />}
    </>
  )
}

export default Show