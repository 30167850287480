import Fetch from "../models/Fetch"

class State {
  name
  fetch

  static {
    this.fetch = new Fetch()
  }

  static save(data) {
    this.fetch.endpoint = "states"
    this.fetch.method = "POST"
    this.fetch.body = data
    return this.fetch.performRequest()
  }

  static show(id) {
    this.fetch.endpoint = `states/${ id }`
    this.fetch.method = "GET"
    return this.fetch.performRequest()
  }

  static get(search, page, paginate) {
    this.fetch.endpoint = "states"
    this.fetch.method = "GET"
    this.fetch.queries.set('search', search)
    this.fetch.queries.set('page', page)
    paginate === false && this.fetch.queries.set('paginate', 'false')
    return this.fetch.performRequest()
  }

  static update(id, data) {
    this.fetch.endpoint = `states/${ id }`
    this.fetch.method = "PUT"
    this.fetch.body = data
    return this.fetch.performRequest()
  }

  static delete(id) {
    this.fetch.endpoint = `states/${ id }`
    this.fetch.method = "DELETE"
    return this.fetch.performRequest()
  }
}

export default State
