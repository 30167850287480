const { default: Fetch } = require('./Fetch')

class ActiveForm {
  fetch = null

  static {
    this.fetch = new Fetch()
  }

  static create (data) {
    this.fetch.endpoint = 'active-forms'
    this.fetch.method = 'POST'
    this.fetch.body = data
    return this.fetch.performRequest()
  }

  static findAll() {
    this.fetch.endpoint = 'active-forms'
    this.fetch.method = 'GET'
    return this.fetch.performRequest()
  }

  static delete(id) {
    this.fetch.endpoint = `active-forms/${id}`
    this.fetch.method = 'DELETE'
    return this.fetch.performRequest()
  }

  static show(id) {
    this.fetch.endpoint = `active-forms/${id}`
    this.fetch.method = 'GET'
    return this.fetch.performRequest()
  }

  static update(id, data) {
    this.fetch.endpoint = `active-forms/${id}`
    this.fetch.method = 'PATCH'
    this.fetch.body = data
    return this.fetch.performRequest()
  }
}

export default ActiveForm