import React from 'react'
import useLocale from '../../hooks/useLocale'
import {FileInput, Input} from '../FormComponents'
import Modal from '../Modal'
import useModal from '../../hooks/useModal'
import Button from '../Button'
import TransferList from '../TransferList'
import {H2} from '../Headings'

function BookForm ({bookPdfRef, titleRef, isbnRef, coverRef, selected, setSelected}) {
  const {l} = useLocale()
  const modal = useModal()
  const previewRef = React.useRef(null)

  async function handleFileUpload (e, type) {
    const file = e.target.files[0]

    const fd = new FormData()
    fd.append('upload', file)

    const res = await window.fetch(`${process.env.REACT_APP_API_URL}images`, {
      method: 'POST',
      body: fd,
      credentials: 'include'
    })
    const data = await res.json()

    if (type === 'cover') {
      return coverRef.current = data.url
    }

    bookPdfRef.current = data.url
  }

  function handlePreview (url) {
    previewRef.current = url
    modal.openModal()
  }

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <Input label={l('title')} reference={titleRef} />
          <Input label={l('isbn')} reference={isbnRef} />
        </div>
        <div>
          <FileInput label={<>{l('cover')} <Button onClick={() => handlePreview(coverRef.current)} type='warning' className='px-1 py-0 ml-3'>{l('view_cover')}</Button></>} onChange={e => handleFileUpload(e, 'cover')} />
          <FileInput label={<>{l('book_in_pdf')} <Button onClick={() => handlePreview(bookPdfRef.current)} type='warning' className='px-1 py-0 ml-3'>{l('view_pdf')}</Button></>} onChange={e => handleFileUpload(e, 'book')} />
        </div>
      </div>
      <H2>{l('clinic_cases')}</H2>
      <TransferList selected={selected} setSelected={setSelected} />

      <Modal show={modal.showModal} onClose={modal.closeModal}>
        <div className='min-w-[1000px] min-h-[600px] flex justify-center items-center'>
          <iframe width={'100%'} className='min-h-[600px]' src={previewRef.current} title='preview'></iframe>
        </div>
      </Modal>
    </>
  )
}

export default BookForm