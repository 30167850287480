import React from 'react'
import { Link } from 'react-router-dom'

function Tab ({ active, label, tab }) {
  return (
    <Link
      to={`?tab=${tab}`}
      className={`${active ? 'text-gray-400 border-b-2 border-gray-400' : 'border-transparent'} inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300`}
    >
      {label}
    </Link>
  )
}

export default Tab