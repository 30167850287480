import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../components/Card';
import {PageHeading} from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import ActiveForm from '../../models/ActiveForm';
import { ActiveFormsForm } from './components/form';
import React from 'react';

export function ShowActiveForm() {
  const {id} = useParams()
  const {l} = useLocale();
  const navigate = useNavigate()
  const [isPending, setIsPending] = React.useState(true)
  const [activeForm, setActiveForm] = React.useState({})

  const getData = React.useCallback(async () => {
    const response = await ActiveForm.show(id)
    setActiveForm(response)
    setIsPending(false)
  }, [id])

  React.useEffect(() => {
    getData()
  },[getData])

  async function onSbumit(data) {
    await ActiveForm.update(id, data)
    navigate('/active-forms')
  }

  return (
    <>
      <PageHeading title={l('add_active_form')} image={'/img/active.svg'}></PageHeading>

      <Card>
        {isPending === false && <ActiveFormsForm initState={activeForm} onSubmit={onSbumit} />}
      </Card>
    </>
  )
}