import React from 'react'
import Card from '../../components/Card'
import { H1, H2 } from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import { Checkbox, Input } from '../../components/FormComponents'
import UserAttributes from '../../components/UserAttributes'
import useUser from '../../hooks/useUser'
import Modal from '../../components/Modal'
import UserRolesPermissions from '../../components/UserRolesPermissions'
import Button from '../../components/Button'

function Create() {
	const { l } = useLocale()
	const {
		user,
		openModal,
		handleAddAttribute,
		handleChangeAttribute,
		handleDeleteAttribute,
		showModal,
		closeModal,
		attributeKeyRef,
		handleChange,
		create,
		handlePermissionSelect,
		handleRoleSelect,
	} = useUser()

	return (
		<>
			<H1>
				<img src='/img/users.svg' className='w-[35px]' alt='' />
				{l('add_user')}
			</H1>
			<form onSubmit={create}>
				<Card>
					<div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
						<Input
							name='name'
							onChange={handleChange}
							value={user.name}
							label={l('name')}
						/>
						<Input
							name='email'
							onChange={handleChange}
							value={user.email}
							label={l('email')}
						/>
						<Input
							name='password'
							onChange={handleChange}
							value={user.password}
							type='password'
							label={l('password')}
						/>
						<Input
							onChange={handleChange}
							value={user.confirm_password}
							name='confirm_password'
							type='password'
							label={l('confirm_password')}
						/>
					</div>
					<div className='grid grid-cols-1 md:grid-cols-2'>
						<UserAttributes
							openModal={openModal}
							user={user}
							handleChangeAttribute={handleChangeAttribute}
							handleDeleteAttribute={handleDeleteAttribute}
						/>
						<UserRolesPermissions
							user={user}
							handlePermissionSelect={handlePermissionSelect}
							handleRoleSelect={handleRoleSelect}
						/>
					</div>
				</Card>
				<Button>{l('save')}</Button>
			</form>
			<Modal show={showModal} onClose={closeModal}>
				<H2>{l('add_new_key')}</H2>
				<Input label={l('key')} reference={attributeKeyRef} name='key' />
				<Button onClick={handleAddAttribute}>{l('add')}</Button>
			</Modal>
		</>
	)
}

export default Create
