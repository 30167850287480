import React from 'react'
import CheckRole from '../CheckRole'
import Button from '../Button'
import useLocale from '../../hooks/useLocale'
import useModal from '../../hooks/useModal'
import Modal from '../Modal'
import BooksList from './BooksList'

function BooksToClinicCase ({caseId}) {
  const {l} = useLocale()
  const modal = useModal()

  return (
    <>
      <CheckRole has="can_assign_book_to_clinic_case">
        <Button onClick={modal.openModal} type='secondary'>{l('assign_book')}</Button>
      </CheckRole>
      <Modal show={modal.showModal} onClose={modal.closeModal}>
        {modal.showModal && <BooksList caseId={caseId} />}
      </Modal>
    </>
  )
}

export default BooksToClinicCase