const { default: Fetch } = require('./Fetch')

class Answer {
  fetch = null

  static {
    this.fetch = new Fetch()
  }

  static answer (caseId, item, question, answer) {
    this.fetch.endpoint = 'answers'
    this.fetch.method = 'POST'
    this.fetch.body = {
      caseId, item, question, answer
    }
    return this.fetch.performRequest()
  }

  static check (questionId, clinicCaseId) {
    this.fetch.endpoint = 'answers/check'
    this.fetch.method = 'POST'
    this.fetch.body = {
      questionId, clinicCaseId
    }
    return this.fetch.performRequest()
  }
}

export default Answer