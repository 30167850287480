import React from "react"

function ItemCard({ children, id }) {
	const handleDragStart = e => {
		e.dataTransfer.setData("id", id)
	}

	return (
		<div
			draggable
			onDragStart={handleDragStart}
			className={`bg-gray-800 my-2 p-2 text-center rounded-md border-gray-600 border cursor-grab`}
		>
			{children}
		</div>
	)
}

export default ItemCard
