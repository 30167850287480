import ToggleSwitch from '../../SharedComponents/ToggleSwitch';

export function SendToDelegateColumn({ rowData, handleToggle }) {
  return (
    <ToggleSwitch
      isOn={rowData.sendToDelegate ? rowData.sendToDelegate : false}
      handleToggle={() => handleToggle(rowData)}
    />
  );
}
