class Fetch {
  url = ''
  endpoint = ''
  method = 'GET'
  isDownloadingFile = false
  isUploadingFile = false
  headers = {
    'Content-Type': 'application/json',
  }
  body = null
  status = null
  queries = new URLSearchParams()

  METHODS_WITH_BODY = ['POST', 'PUT', 'PATCH']

  constructor(method = 'GET') {
    this.url = process.env.REACT_APP_API_URL
    this.method = method
  }

  async performRequest () {
    const options = {}
    options.method = this.method
    options.headers = this.headers
    options.credentials = 'include'
    if (this.METHODS_WITH_BODY.includes(this.method))
      options.body = !this.isUploadingFile ? JSON.stringify(this.body) : this.body

    const req = await window.fetch(
      `${this.url}${this.endpoint}?${this.queries.toString()}`,
      options
    )
    this.status = req.status
    if (this.status !== 200) {
      throw await req.json()
    }
    this.queries = new URLSearchParams()

    if (this.isDownloadingFile) {
      this.isDownloadingFile = false
      return await req.blob()
    }
    return await req.json()
  }
}

export default Fetch
