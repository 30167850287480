import React from 'react'
import { useNavigate } from 'react-router-dom'
import User from '../../models/User'
import AppContext from '../../contexts/AppContext'
import useLocale from '../../hooks/useLocale'

function LogoutButton() {
	const { l } = useLocale()
	const { user, handleUserRefresh } = React.useContext(AppContext)
	const navigate = useNavigate()

	const handleLogout = () => {
		User.logout().then(res => {
			handleUserRefresh()
			navigate('/')
		})
	}

	return (
		<div className='mt-5 text-center  px-3 flex flex-col gap-3'>
			<div className='text-xl'>
				<p>{l('hello')}</p>
				<p>{user.name}</p>
				<p className='text-sm text-gray-300 mt-1'>
					Roles: {user?.roles?.map(role => role.name).join(', ')}
				</p>
			</div>
			<div>
				<button
					className='hover:underline text-gray-300'
					onClick={handleLogout}
				>
					<img src='/img/logout.svg' className='w-[20px]' alt='logout' />
				</button>
			</div>
		</div>
	)
}

export default LogoutButton
