import React from 'react';
import Button from '../../components/Button';
import useLocale from '../../hooks/useLocale';
import useModal from '../../hooks/useModal';
import AnswerQuestions from '../AnswerQuestions';
import { ViewQuestionsModal } from '../ViewQuestionsModal';
import { componentsMap } from './components';

function ViewClinicCase({ clinicCase, withAnswers, withViewQuestions }) {
  const questionsModal = useModal();
  const viewQuestionsModal = useModal();
  const itemRef = React.useRef(null);
  const { l } = useLocale();

  function handleOpen(item) {
    itemRef.current = item;
    questionsModal.openModal();
  }

  function handleView(item) {
    itemRef.current = item;
    viewQuestionsModal.openModal();
  }

  return (
    <div className="mb-5 grid grid-cols-12">
      {Object.keys(clinicCase.items).map(key => {
        const element = clinicCase.items[key];
        const hasQuestions = element.attributes.has_questions;

        return (
          <div
            style={{
              gridColumn: `span ${element.attributes.cols || 12} / span ${
                element.attributes.cols || 12
              }`,
            }}
          >
            {React.createElement(componentsMap[element.component], {
              item: element,
              isAnswersActive: withAnswers,
              handleOpen: handleOpen,
              clinicCase,
            })}
            {withAnswers && hasQuestions && (
              <>
                <Button type="warning" onClick={() => handleOpen(element)}>
                  {l('answer_questions')}
                </Button>
              </>
            )}
            {withViewQuestions && hasQuestions && (
              <Button type="warning" onClick={() => handleView(element)}>
                {l('view_questions')}
              </Button>
            )}
          </div>
        );
      })}

      {withAnswers && (
        <>
          <AnswerQuestions
            item={itemRef.current}
            id={clinicCase._id}
            modal={questionsModal}
            questionsRef={itemRef.current?.attributes.questions}
          />
        </>
      )}
      {withViewQuestions && (
        <ViewQuestionsModal modal={viewQuestionsModal} id={clinicCase._id} item={itemRef.current} />
      )}
    </div>
  );
}

export default ViewClinicCase;
