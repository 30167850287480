import React from "react";

function Code({ code, label }) {
  return (
    <>
      <label className="font-bold">{label}</label>
      <pre className="overflow-x-scroll rounded-md bg-[#333] px-5 py-2">
        <code>{JSON.stringify(code, null, 2)}</code>
      </pre>
    </>
  );
}

export default Code;
