import React from 'react'
import Mail from '../models/Mail'
import { toast } from 'react-toastify'
import useModal from '../hooks/useModal'
import useLocale from '../hooks/useLocale'

function useEmails(paginate = true) {
  const { l } = useLocale()
  const nameRef = React.useRef(null)
  const templateRef = React.useRef(null)
  const [ mails, setMails ] = React.useState([])
  const { showModal, closeModal, openModal } = useModal()
  const [ search, setSearch ] = React.useState('')
  const [ page, setPage ] = React.useState(1)

  const getMails = () => {
    Mail.get(search, page, paginate)
      .then(res => setMails(res))
      .catch(err => toast.error(l('general_error')))
  }

  React.useEffect(() => {
    getMails()
  }, [ search ])

  const handleSubmit = e => {
    e.preventDefault()
    const { value } = nameRef.current
    const { checked } = templateRef.current
    Mail.save({ name: value, isTemplate: checked }).then(res => {
      toast.success(l('mail_added'))
      nameRef.current.value = ''
      closeModal()
      getMails()
    })
  }

  const handleDelete = id => {
    Mail.delete(id).then(res => {
      toast.success(l('mail_deleted'))
      getMails()
    })
  }

  return {
    mails,
    openModal,
    showModal,
    closeModal,
    handleSubmit,
    handleDelete,
    nameRef,
    templateRef,
    search,
    setSearch,
    setPage
  }
}

export default useEmails
