import React from 'react'
import Navigation from '../../components/Navigation'
import Sidebar from '../../components/Sidebar'

function Layout({ children }) {
	return (
		<div className='flex max-h-screen overflow-hidden'>
			<Sidebar>
				<Navigation />
			</Sidebar>
			<main className='py-5 px-9 w-full overflow-y-auto'>{children}</main>
		</div>
	)
}

export default Layout
