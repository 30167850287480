import React from 'react'
import useLocale from '../../hooks/useLocale'
import CheckRole from '../CheckRole'
import Divider from '../Divider'
import { Input } from '../FormComponents'
import { H2 } from '../Headings'
import Button from '../../components/Button'

function UserAttributes({
	user,
	handleChangeAttribute,
	handleDeleteAttribute,
	openModal,
}) {
	const { l } = useLocale()

	return (
		<div>
			<H2>{l('attributes')}</H2>
			<CheckRole has='add_attributes'>
				<Button onClick={() => openModal()} isSubmit={false}>
					{l('add')}
				</Button>
			</CheckRole>
			{user.attributes &&
				Object.keys(user.attributes).map(key => {
					const el = user.attributes[key]
					return (
						<React.Fragment key={key}>
							<div className='flex gap-5'>
								<Input
									name='value'
									label={key}
									className='col-span-4'
									value={el}
									onChange={e => handleChangeAttribute(e, key)}
								/>
								<CheckRole has='delete_attributes'>
									<Button
										isSubmit={false}
										type='danger'
										className='self-end my-2 border border-red-900'
										onClick={() => handleDeleteAttribute(key)}
									>
										{l('delete')}
									</Button>
								</CheckRole>
							</div>
							<Divider />
						</React.Fragment>
					)
				})}
		</div>
	)
}

export default UserAttributes
