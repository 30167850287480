import React from 'react'
import {PageHeading} from '../components/Headings'
import Card from '../components/Card'

function TestPage () {
  // const {l} = useLocale()
  // const {getClinicCases, clinicCases, handleDelete} = useClinicCases()
  // const {forms} = useForms({paginate: false})
  // const {states} = useStates(false)

  // const columns = [
  //   {
  //     header: l('id'),
  //     accessor: '_id'
  //   },
  //   {
  //     header: l('title'),
  //     accessor: 'title',
  //   },
  //   {
  //     header: l('form'),
  //     accessor: 'form.name',
  //     filtrable: true,
  //     filterType: 'select',
  //     options: forms.map(form => ({value: form._id, label: form.name}))
  //   },
  //   {
  //     header: l('user'),
  //     accessor: 'user.name',
  //     filtrable: true
  //   },
  //   {
  //     header: l('state'),
  //     accessor: 'state.name',
  //     component: StateColumn,
  //     cellClass: 'text-center',
  //     filtrable: true,
  //     filterType: 'select',
  //     options: states.map(state => ({value: state._id, label: state.name}))
  //   },
  //   {
  //     header: l('actions'),
  //     component: ({rowData, page, limit}) => <ActionsColumn rowData={rowData} handleDelete={(id) => handleDelete(id, page, limit)} />,
  //     cellClass: 'flex gap-3 justify-center'
  //   }
  // ]

  return (
    <>
      <PageHeading title={'TestPage'}></PageHeading>
      <Card>
        {/* <DataGridView columns={columns} getData={getClinicCases} data={clinicCases.docs} totalPages={clinicCases.totalPages} /> */}
      </Card>
    </>
  )
}

export default TestPage