import React from 'react';
import Button from '../../components/Button';
import Card from '../../components/Card';
import CheckRole from '../../components/CheckRole';
import DataGridView from '../../components/DataGridView';
import { PageHeading } from '../../components/Headings';
import useLocale from '../../hooks/useLocale';
import useUserExchanges from '../../hooks/useUserExchanges';
import { DateColumn } from '../SharedComponents/DateColumn';
import { UserExchangesActionColumn } from './columns/ActionColumn';
import { ExchangeStateColumn } from './columns/ExchangeStateColumn';
import { SendToDelegateColumn } from './columns/SendToDelegateColumn';

export function UserExchangesPage() {
  const { l } = useLocale();
  const { getExchanges, exchanges, handleUpdate, exportXlsx } = useUserExchanges();

  const handleExchangeSent = rowData => {
    const data = { ...rowData, sentDate: rowData.sentDate ? null : new Date().toISOString() };

    handleUpdate(data);
  };

  const handleToggle = rowData => {
    const aux = { ...rowData, sendToDelegate: !rowData.sendToDelegate };

    handleUpdate(aux);
  };

  const columns = [
    {
      header: l('state'),
      accessor: 'sentDate',
      component: ExchangeStateColumn,
      filtrable: true,
      filterType: 'select',
      options: [
        { value: '', label: l('all_states') },
        { value: true, label: l('product_sent') },
        { value: false, label: l('product_pending') },
      ],
    },
    {
      header: l('product_requested'),
      accessor: 'createdAt',
      component: DateColumn,
    },
    {
      header: l('user'),
      accessor: 'user.name',
      filtrable: true,
    },
    {
      header: l('hospital'),
      accessor: 'user.attributes.hospital',
    },
    {
      header: l('product'),
      accessor: 'product.name',
      filtrable: true,
    },
    {
      header: l('product_price'),
      accessor: 'product.pricing.costPrice',
    },
    {
      header: l('points'),
      accessor: 'price',
    },
    {
      header: l('send_to_delegate'),
      accessor: 'sendToDelegate',
      component: ({ rowData }) => (
        <SendToDelegateColumn rowData={rowData} handleToggle={handleToggle} />
      ),
      cellClass: 'text-center',
    },
    {
      header: l('actions'),
      component: ({ rowData, page, limit, filters }) => (
        <UserExchangesActionColumn rowData={rowData} handleExchangeSent={handleExchangeSent} />
      ),
      cellClass: 'flex gap-2 justify-center',
    },
  ];

  return (
    <>
      <PageHeading title={l('user_exchanges')} image={'/img/exchange.svg'}>
        <CheckRole has={'export_user_exchanges'}>
          <Button onClick={exportXlsx} type="warning">
            {l('export')}
          </Button>
        </CheckRole>
      </PageHeading>
      <Card>
        <DataGridView
          getData={getExchanges}
          data={exchanges.docs}
          columns={columns}
          totalPages={exchanges.totalPages}
          showPagination={true}
        />
      </Card>
    </>
  );
}
