import React from 'react'
import useLocale from '../../hooks/useLocale'
import useModal from '../../hooks/useModal'
import User from '../../models/User'
import Button from '../Button'
import CheckRole from '../CheckRole'
import { H2 } from '../Headings'
import Modal from '../Modal'
import { toast } from 'react-toastify'

function UserSessions({ userId }) {
	const { l } = useLocale()
	const { openModal, showModal, closeModal } = useModal()
	const [sessions, setSessions] = React.useState([])

	React.useEffect(() => {
		getSessions()
	}, [])

	const getSessions = () => {
		User.getSessions(userId).then(res => setSessions(res))
	}

	const handleDelete = id => {
		User.deleteSession(id)
			.then(res => {
				getSessions()
				toast.success(l('session_closed'))
			})
			.catch(err => toast.error(l('general_error')))
	}

	return (
		<>
			<CheckRole has='view_user_sessions'>
				<Button onClick={openModal} type='warning'>
					{l('view_sessions')}
				</Button>
			</CheckRole>
			<Modal show={showModal} onClose={closeModal}>
				<H2>{l('view_sessions')}</H2>
				<div className='flex flex-col gap-3 w-full min-w-[500px]'>
					{sessions.map(session => (
						<div className='p-5 border-gray-400 border rounded-md flex relative'>
							{session.isCurrent && (
								<span className='absolute top-0 right-0 px-2 py-1 bg-green-500 rounded-bl-md text-black'>
									{l('current_session')}
								</span>
							)}
							<div className='flex flex-1 justify-between p-[5px]'>
								<img
									src={`/img/${session.data?.device?.type}.svg`}
									alt={session.data?.device?.type}
									className='max-w-[40px]'
								/>
								<img
									src={`/img/${session.data?.os?.name}.svg`}
									alt={session.data?.os?.name}
									className='max-w-[40px]'
								/>
								<img
									src={`/img/${session.data?.client?.name}.svg`}
									alt={session.data?.client?.name}
									className='max-w-[40px]'
								/>
							</div>
							<div className='flex-1 min-w-[70%] border-l p-3 justify-center flex flex-col'>
								<p>
									{l('session_id')}: {session._id}
								</p>
								<p>
									{l('started')}: {new Date(session.createdAt).toLocaleString()}
								</p>
								<CheckRole has='delete_user_session'>
									<button
										className='text-left underline text-red-500'
										onClick={() => handleDelete(session._id)}
									>
										{l('close')}
									</button>
								</CheckRole>
							</div>
						</div>
					))}
				</div>
			</Modal>
		</>
	)
}

export default UserSessions
