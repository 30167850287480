const {default: Fetch} = require('./Fetch')

class Contact {
  fetch = null

  static {
    this.fetch = new Fetch()
  }

  static getAll (page = null, limit = null) {
    this.fetch.endpoint = 'contact'
    this.fetch.method = 'GET'
    page && this.fetch.queries.set('page', page)
    limit && this.fetch.queries.set('limit', limit)
    return this.fetch.performRequest()
  }

  static get (id) {
    this.fetch.endpoint = `contact/${id}`
    this.fetch.method = 'GET'
    return this.fetch.performRequest()
  }
}

export default Contact