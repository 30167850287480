import React from 'react'
import useLocale from '../../hooks/useLocale'
import { H2, PageHeading } from '../../components/Headings'
import Card from '../../components/Card'
import Button from '../../components/Button'
import CheckRole from '../../components/CheckRole'
import Modal from '../../components/Modal'
import { Checkbox, Input } from '../../components/FormComponents'
import { Link } from 'react-router-dom'
import useEmails from '../../hooks/useEmails'
import DangerButton from '../../components/DangerButton'
import Paginator from '../../components/Paginator'

function EmailsPage() {
	const { l } = useLocale()
	const {
		mails,
		openModal,
		showModal,
		closeModal,
		handleSubmit,
		handleDelete,
		nameRef,
		templateRef,
		setSearch,
		search,
		setPage,
	} = useEmails()

	return (
		<>
			<PageHeading title={l('emails')} image='/img/email.svg'>
				<CheckRole has='add_emails'>
					<Button onClick={openModal}>{l('add')}</Button>
				</CheckRole>
			</PageHeading>
			<Card>
				<Input
					className='w-64'
					value={search}
					onChange={e => setSearch(e.target.value)}
					placeholder={l('search')}
				/>
				<table className='w-full'>
					<thead>
						<tr className='text-left border-b border-gray-600'>
							<th>{l('name')}</th>
							<th>{l('is_template')}</th>
							<th>{l('actions')}</th>
						</tr>
					</thead>
					<tbody>
						{mails.docs?.map(mail => (
							<tr key={mail._id}>
								<td>{mail.name}</td>
								<td>
									<img
										src={`/img/${mail.isTemplate}.svg`}
										className={'w-[15px]'}
										alt=''
									/>
								</td>
								<td className='flex gap-3'>
									<CheckRole has='view_legal_texts'>
										<Link
											to={`/emails/${mail._id}`}
											className='bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200'
										>
											<img className='w-[25px]' src='/img/view.svg' alt='' />
										</Link>
									</CheckRole>
									<CheckRole has='delete_legal_texts'>
										<DangerButton onClick={() => handleDelete(mail._id)} />
									</CheckRole>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<Paginator meta={mails} setPage={setPage} />
			</Card>
			<Modal show={showModal} onClose={closeModal}>
				<H2>{l('add_email')}</H2>
				<form onSubmit={handleSubmit}>
					<Input label={l('name')} reference={nameRef} />
					<Checkbox
						label={l('is_template')}
						name={'is_template'}
						reference={templateRef}
					/>
					<Button full type='secondary'>
						{l('add')}
					</Button>
				</form>
			</Modal>
		</>
	)
}

export default EmailsPage
