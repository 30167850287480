import React from 'react'
import Role from '../models/Role'
import { toast } from 'react-toastify'
import useModal from '../hooks/useModal'
import useLocale from './useLocale'
import AppContext from '../contexts/AppContext'

function useRoles(paginate = true) {
  const { l } = useLocale()
  const [ roles, setRoles ] = React.useState([])
  const [ selectedRole, setSelectedRole ] = React.useState(null)
  const { showModal, closeModal, openModal } = useModal()
  const nameRef = React.useRef(null)
  const { handleUserRefresh } = React.useContext(AppContext)
  const [ search, setSearch ] = React.useState('')
  const [ page, setPage ] = React.useState(1)

  const getRoles = () => {
    Role.get(search, page, paginate)
      .then(res => setRoles(res))
      .catch(err => toast.error(err.message))
  }

  React.useEffect(() => {
    getRoles()
  }, [ search, page ])

  const handleDelete = id => {
    Role.delete(id)
      .then(res => {
        toast.success(l('role_deleted'))
        getRoles()
        setSelectedRole(null)
      })
      .catch(err => toast.error(err.message))
  }

  const handleSubmit = e => {
    e.preventDefault()
    Role.save({ name: nameRef.current.value })
      .then(res => {
        toast.success(l('role_saved'))
        getRoles()
        nameRef.current.value = ''
        closeModal()
      })
      .catch(err => toast.error(err.message))
  }

  const handleView = id => {
    const role = roles.docs.find(role => role._id === id)
    setSelectedRole(role)
  }

  const handleRoleEdit = e => {
    e.preventDefault()
    Role.update({ id: selectedRole._id, data: selectedRole })
      .then(res => {
        toast.success(l('role_updated'))
        setSelectedRole(null)
        getRoles()
        handleUserRefresh()
      })
      .catch(err => toast.error(l('general_error')))
  }

  const handleRoleChange = (e, type = 'permissions') => {
    const { name } = e.currentTarget

    const index = selectedRole[type].indexOf(name)

    if (index === -1) {
      setSelectedRole({
        ...selectedRole,
        [type]: [ ...selectedRole[type], name ],
      })
      return
    }

    let aux = selectedRole
    aux[type].splice(index, 1)
    setSelectedRole({ ...aux })
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setSelectedRole({
      ...selectedRole,
      [name]: value,
    })
  }

  return {
    handleInputChange,
    handleRoleChange,
    handleRoleEdit,
    handleView,
    handleSubmit,
    handleDelete,
    showModal,
    openModal,
    closeModal,
    selectedRole,
    roles,
    nameRef,
    setSearch,
    search,
    page,
    setPage
  }
}

export default useRoles
