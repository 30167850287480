import React from 'react'
import Card from '../../components/Card'
import { PageHeading } from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import useUserPoints from '../../hooks/useUserPoints'
import PointsCard from '../../components/PointsCard'

function UserPointsPage () {
  const { l } = useLocale()
  const { points } = useUserPoints()

  return (
    <>
      <PageHeading image={'/img/trohpy.svg'} title={l('view_points')}></PageHeading>
      <Card>
        <div className="grid grid-cols-3 mb-9 gap-3">
          <PointsCard>
            <strong>{l('total')}:</strong>
            <span>{points.totals.total?.toLocaleString('es-ES', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })} pts</span>
          </PointsCard>
          <PointsCard type='gained'>
            <strong>{l('gained')}:</strong>
            <span>{points.totals.gained?.toLocaleString('es-ES', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })} pts</span>
          </PointsCard>
          <PointsCard type='wasted'>
            <strong>{l('spent')}:</strong>
            <span>{points.totals.wasted?.toLocaleString('es-ES', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })} pts</span>
          </PointsCard>
        </div>
        <table className="w-full">
          <thead className='text-left border-b border-gray-600'>
            <tr>
              <th>{l('concept')}</th>
              <th>{l('ammount')}</th>
            </tr>
          </thead>
          <tbody>
            {points.points.map(point => <tr key={point._id}>
              <td>{l(point.concept)}</td>
              <td>{point.points.toLocaleString('es-ES', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })} pts</td>
            </tr>)}
          </tbody>
        </table>
      </Card>
    </>
  )
}

export default UserPointsPage