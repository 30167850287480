import Fetch from './Fetch'

export default class Points {
  fetch = null

  static {
    this.fetch = new Fetch()
  }

  static getHistory (id) {
    this.fetch.endpoint = `pointledgerentries/${id}`
    this.fetch.method = 'GET'
    return this.fetch.performRequest()
  }
}