import React from 'react'

function DeleteButton({ handleDelete, absolute = true }) {
	return (
		<button
			className={`${
				absolute ? 'rounded-tr-md py-2' : 'rounded-md py-0.5'
			} bg-red-900 px-4 hover:bg-red-500 ease-in-out duration-200`}
			onClick={handleDelete}
		>
			<img src='/img/trash.svg' className='w-full max-w-[20px]' />
		</button>
	)
}

export default DeleteButton
