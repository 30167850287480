import React from 'react'
import useLocale from '../../hooks/useLocale'
import { PageHeading } from '../../components/Headings'
import Modal from '../../components/Modal'
import { Input } from '../../components/FormComponents'
import Card from '../../components/Card'
import useRoles from '../../hooks/useRoles'
import RolePermissions from '../../components/RolePermissions'
import CheckRole from '../../components/CheckRole'
import Button from '../../components/Button'
import DangerButton from '../../components/DangerButton'
import Paginator from '../../components/Paginator'

function RolesAndPermissions() {
	const { l } = useLocale()
	const {
		openModal,
		showModal,
		closeModal,
		roles,
		handleDelete,
		handleView,
		handleSubmit,
		handleRoleChange,
		selectedRole,
		handleRoleEdit,
		handleInputChange,
		nameRef,
		search,
		setSearch,
		setPage,
	} = useRoles()

	return (
		<>
			<PageHeading title={l('roles')} image='/img/roles.svg'>
				<CheckRole has='add_roles'>
					<Button onClick={openModal}>{l('add')}</Button>
				</CheckRole>
			</PageHeading>
			<Card>
				<Input
					className='w-64'
					value={search}
					onChange={e => setSearch(e.target.value)}
					placeholder={l('search')}
				/>
				<div className='grid grid-cols-2 gap-9'>
					<div>
						<table className='w-full'>
							<thead>
								<tr className='text-left border-b border-gray-600'>
									<th>{l('name')}</th>
									<th>{l('actions')}</th>
								</tr>
							</thead>
							<tbody>
								{roles.docs?.map(role => (
									<tr key={role._id}>
										<td>{role.name}</td>
										<td className='flex gap-3'>
											<CheckRole has='view_roles'>
												<button
													onClick={() => handleView(role._id)}
													className='bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200'
												>
													<img
														className='w-[25px]'
														src='/img/view.svg'
														alt=''
													/>
												</button>
											</CheckRole>
											{role.name !== 'Super admin' && (
												<CheckRole has='delete_roles'>
													<DangerButton
														onClick={() => handleDelete(role._id)}
													/>
												</CheckRole>
											)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
						<Paginator setPage={setPage} meta={roles} />
					</div>
					<div>
						<RolePermissions
							handleInputChange={handleInputChange}
							handleRoleChange={handleRoleChange}
							selectedRole={selectedRole}
							handleRoleEdit={handleRoleEdit}
						/>
					</div>
				</div>
			</Card>
			<Modal show={showModal} onClose={closeModal}>
				<form onSubmit={handleSubmit}>
					<Input label={l('name')} reference={nameRef} />
					<input
						type='submit'
						value={l('add')}
						className='text-white w-full bg-gray-900 py-1 rounded-md hover:bg-gray-800 ease-in-out duration-200 cursor-pointer'
					/>
				</form>
			</Modal>
		</>
	)
}

export default RolesAndPermissions
