import React from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import User from '../models/User'
import AppContext from '../contexts/AppContext'

function useLoginForm() {
	const navigate = useNavigate()
	const [loading, setLoading] = React.useState(true)
	const emailRef = React.useRef(null)
	const passwordRef = React.useRef(null)
	const { handleUserRefresh } = React.useContext(AppContext)

	React.useEffect(() => {
		User.whoami()
			.then(res => {
				return navigate('/dashboard')
			})
			.catch(err => {
				setLoading(false)
			})
	})

	const handleSubmit = e => {
		e.preventDefault()
		User.login({
			username: emailRef.current.value,
			password: passwordRef.current.value,
		})
			.then(res => {
				handleUserRefresh()
				navigate('/dashboard')
			})
			.catch(err => {
				toast.error(err.message)
			})
	}

	return { loading, handleSubmit, emailRef, passwordRef }
}

export default useLoginForm
