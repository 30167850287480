import {useParams} from 'react-router-dom';
import {PageHeading} from '../../components/Headings';
import useLocale from '../../hooks/useLocale';
import Card from '../../components/Card';
import {useCallback, useEffect, useRef, useState} from 'react';
import Classifier from '../../models/Classifier';
import {Input, Select} from '../../components/FormComponents';
import Divider from '../../components/Divider'
import Button from '../../components/Button';
import useModal from '../../hooks/useModal';
import Modal from '../../components/Modal';
import DataGridView from '../../components/DataGridView';
import {toast} from 'react-toastify'
import CheckRole from '../../components/CheckRole'

export function ShowClassifier () {
  const {id} = useParams()
  const {l} = useLocale()
  const [classifier, setClassifier] = useState({items: []});
  const modal = useModal()
  const labelRef = useRef(null)
  const pointsRef = useRef(null)

  const cols = [
    {
      header: l('id'),
      accessor: '_id'
    },
    {
      header: l('label'),
      accessor: 'label',
    },
    {
      header: l('points'),
      accessor: 'points',
      cellClass: 'text-end'
    }
  ]

  const selectOptions = [
    {label: l('on_assignment'), value: 'on_assignment'},
    {label: l('on_answer'), value: 'on_answer'}
  ]

  const getData = useCallback(async function () {
    const classifier = await Classifier.findOne(id)
    setClassifier(classifier)
  }, [])

  useEffect(() => {
    getData()
  }, [])

  async function handleAddItem (event) {
    event.preventDefault()
    const aux = JSON.parse(JSON.stringify(classifier))

    aux.items.push({
      label: labelRef.current.value,
      points: pointsRef.current.value,
    })
    await Classifier.update(id, {
      ...aux,
    })

    await getData()
    modal.closeModal()
  }

  function handleChange (event) {
    const {name, value} = event.target
    setClassifier(old => ({
      ...old,
      [name]: value
    }))
  }

  async function handleUpdate () {
    await Classifier.update(id, {
      ...classifier,
    })
    toast.success(l('updated'))
  }

  return (
    <>
      <PageHeading title={l('view_classifier')} image={'/img/classifiers.svg'}></PageHeading>
      <Card>
        {/* <pre>
          {JSON.stringify(classifier, null, 2)}
        </pre> */}
        <div className="grid grid-cols-12 gap-2">
          <Input label={l('name')} className='col-span-6' value={classifier.name} onChange={handleChange} name="name" />
          <Select value={classifier.appliesOn} label={l('applies_on')} options={selectOptions} name="appliesOn" className={'col-span-6'} onChange={handleChange} />
        </div>

        <PageHeading title={l('items')}>
          <CheckRole has="edit_classifiers">
            <Button onClick={modal.openModal}>{l('add_item')}</Button>
          </CheckRole>
        </PageHeading>
        <Divider />

        <DataGridView columns={cols} data={classifier.items} showPagination={false} />
      </Card>

      <CheckRole has="edit_classifiers">
        <Button onClick={handleUpdate}>
          {l('save')}
        </Button>
      </CheckRole>

      <Modal show={modal.showModal} onClose={modal.closeModal}>
        <form onSubmit={handleAddItem}>
          <Input label={l('label')} reference={labelRef} />
          <Input label={l('points')} type='number' reference={pointsRef} />
          <Button isSubmit full type='secondary'>
            {l('add')}
          </Button>
        </form>
      </Modal >
    </>
  )
}