import React from 'react'
import { useParams } from 'react-router-dom'
import LegalText from '../models/LegalText'
import { toast } from 'react-toastify'
import useLocale from './useLocale'
import useModal from './useModal'

function useLegalText() {
	const { id } = useParams()
	const [legalText, setLegalText] = React.useState({ name: '' })
	const { l } = useLocale()
	const { showModal, openModal, closeModal } = useModal()
	const nameRef = React.useRef(null)

	const getLegal = () => {
		LegalText.show(id)
			.then(res => setLegalText(res))
			.catch(err => toast.error(l('general_error')))
	}

	React.useEffect(() => {
		getLegal()
	}, [])

	const handleInputChange = e => {
		const { name, value } = e.target
		setLegalText(old => ({
			...old,
			[name]: value,
		}))
	}

	const handleAddSection = e => {
		e.preventDefault()
		const { value } = nameRef.current
		setLegalText(old => ({
			...old,
			texts: [
				...old.texts,
				{
					title: value,
					text: '',
				},
			],
		}))
		nameRef.current.value = ''
		closeModal()
	}

	const handleTextChange = ({ name, value, idx }) => {
		const aux = legalText
		aux.texts[idx][name] = value
		setLegalText({ ...aux })
	}

	const handleSave = () => {
		LegalText.update(id, legalText)
			.then(res => {
				toast.success(l('legal_updated'))
				getLegal()
			})
			.catch(err => toast.error(l('general_error')))
	}

	const handleDelete = idx => {
		let aux = legalText
		aux.texts.splice(idx, 1)
		setLegalText({ ...aux })
	}

	const handleChange = (e, idx) => {
		const { value, name } = e.target
		const aux = legalText
		aux.texts[idx][name] = value
		setLegalText({ ...aux })
	}

	return {
		legalText,
		handleInputChange,
		handleDelete,
		handleChange,
		openModal,
		showModal,
		closeModal,
		handleSave,
		handleAddSection,
		nameRef,
		handleTextChange,
	}
}

export default useLegalText
