import React from 'react'
import { PageHeading } from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import Card from '../../components/Card'
import { Link } from 'react-router-dom'
import CheckRole from '../../components/CheckRole'
import DangerButton from '../../components/DangerButton'
import useProducts from '../../hooks/useProducts'
import Paginator from '../../components/Paginator'
import { Input } from '../../components/FormComponents'
import ExchangeButton from '../../components/ExchangeButton'

function Products () {
  const { l } = useLocale()
  const { products, handleDelete, setPage, search, setSearch } = useProducts()

  return (
    <>
      <PageHeading title={l('products')} image={'/img/shopping_cart.svg'}>
        <Link className='bg-gray-500 enabled:hover:bg-gray-400 px-2 py-1 rounded-md ease-in-out duration-200' to="/products/create">
          {l('add')}
        </Link>
      </PageHeading>

      <Card>
        <Input className='max-w-sm' label={l('search')} placeholder={l('custom_mug')} value={search} onChange={(e) => setSearch(e.target.value)} />
        <table className="w-full">
          <thead>
            <tr className='text-left border-b border-gray-600'>
              <th>ID</th>
              <th>{l('image')}</th>
              <th>{l('name')}</th>
              <th>{l('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {products.docs.map(product => <tr key={product._id}>
              <td>{product._id}</td>
              <td>
                <div
                  className='w-[75px] relative h-[75px] rounded-md flex justify-center items-center overflow-hidden'
                >
                  <img src={product.coverImage?.url} className='min-h-full min-w-full flex-shrink-0' alt="" />
                </div>
              </td>
              <td>{product.name}</td>
              <td>
                <div className='flex gap-3'>
                  <CheckRole has='view_products'>
                    <Link
                      to={`/products/${product._id}`}
                      className='bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200'
                    >
                      <img className='w-[25px]' src='/img/view.svg' alt='' />
                    </Link>
                  </CheckRole>
                  <CheckRole has='delete_products'>
                    <DangerButton onClick={() => handleDelete(product._id)} />
                  </CheckRole>
                  <ExchangeButton product={product} />
                </div>
              </td>
            </tr>)}
          </tbody>
        </table>

        <Paginator meta={products} setPage={setPage} />
      </Card>
    </>
  )
}

export default Products