const {default: Fetch} = require('./Fetch')

class Book {
  fetch = null

  static {
    this.fetch = new Fetch()
  }

  static getAll () {
    this.fetch.endpoint = 'books'
    this.fetch.method = 'GET'
    return this.fetch.performRequest()
  }

  static create (data) {
    this.fetch.endpoint = 'books'
    this.fetch.method = 'POST'
    this.fetch.body = data
    return this.fetch.performRequest()
  }

  static delete (id) {
    this.fetch.endpoint = `books/${id}`
    this.fetch.method = 'DELETE'
    return this.fetch.performRequest()
  }

  static getBook (id) {
    this.fetch.endpoint = `books/${id}`
    this.fetch.method = 'GET'
    return this.fetch.performRequest()
  }

  static update (id, data) {
    this.fetch.endpoint = `books/${id}`
    this.fetch.method = 'PUT'
    this.fetch.body = data
    return this.fetch.performRequest()
  }

  static syncClinicCases (data) {
    this.fetch.endpoint = 'books/clinic-cases'
    this.fetch.method = 'PUT'
    this.fetch.body = data
    return this.fetch.performRequest()
  }
}

export default Book