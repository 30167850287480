import React from 'react'
import CheckRole from '../../../components/CheckRole'
import {Link} from 'react-router-dom'
import DangerButton from '../../../components/DangerButton'
import CheckFormPermission from '../../../components/CheckFormPermission'
import CopyToClipboard from '../../../components/CopyToClipboard'

function ActionColumn ({rowData, handleDelete}) {
  return (
    <>
      <CheckRole has='view_forms'>
        <Link
          to={`/form/${rowData._id}`}
          className='bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200'
        >
          <img className='w-[25px]' src='/img/view.svg' alt='' />
        </Link>
      </CheckRole>
      <CheckRole has='delete_forms'>
        <DangerButton onClick={() => handleDelete(rowData._id)} />
      </CheckRole>
      <CheckRole has='answer_forms'>
        <CheckFormPermission has={rowData._id}>
          <Link
            to={`/form/${rowData._id}/answer`}
            className='bg-gray-500 hover:bg-gray-400 px-2 py-1 rounded-md ease-in-out duration-200'
          >
            <img src='/img/fill.svg' className='w-[25px]' alt='' />
          </Link>
        </CheckFormPermission>
      </CheckRole>
      <CopyToClipboard text={rowData._id} />
    </>
  )
}

export default ActionColumn