import React from 'react'

function Button ({
  children,
  onClick = null,
  type = 'primary',
  full = false,
  className = '',
  isToolbar = false,
  tooltip = undefined,
  isSubmit = true,
  style = {},
  disabled = false,
}) {
  const classes = {
    primary: 'bg-gray-500 enabled:hover:bg-gray-400',
    danger: 'bg-red-900 enabled:hover:bg-red-500',
    secondary: 'bg-gray-900 enabled:hover:bg-gray-800',
    dark: 'bg-black enabled:hover:bg-gray-700',
    warning: 'bg-yellow-500 enalbed:hover:bg-yellow-900 text-black',
    green: 'bg-green-500 enabled:hover:bg-green-900',
    empty: '',
  }
  return (
    <button
      className={`${!isToolbar && classes[type]
        } px-2 py-1 rounded-md ease-in-out duration-200 disabled:opacity-50 ${full && 'w-full'
        } ${className}`}
      onClick={onClick}
      title={tooltip}
      type={isSubmit ? '' : 'button'}
      style={style}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button
