import React from 'react'
import { H1 } from '../../components/Headings'
import useLocale from '../../hooks/useLocale'

function Dashboard() {
	const { l } = useLocale()
	return (
		<H1>
			<img src='/img/dashboard.svg' className='w-[35px]' alt='' />{' '}
			{l('dashboard')}
		</H1>
	)
}

export default Dashboard
