import React from 'react'
import {H2} from '../Headings'
import useLocale from '../../hooks/useLocale'
import useEmails from '../../hooks/useEmails'
import {Checkbox, Input, Select} from '../FormComponents'
import Button from '../Button'
import useModal from '../../hooks/useModal'
import Modal from '../Modal'

function ContactConfig ({config, handleChange, handleCheckboxChange, addDate, deleteDate}) {
  const {l} = useLocale()
  const modal = useModal()
  const datePickerRef = React.useRef(null)
  const {mails} = useEmails(false)

  function handleClick () {
    if (datePickerRef.current.value === '' || !datePickerRef.current.value) return;
    addDate(datePickerRef.current.value)
    datePickerRef.current.value = ''
  }

  return (
    <>
      <H2>
        <img src='/img/consultation.svg' className={'w-[25px]'} alt='' />{' '}
        {l('contact_config')}
      </H2>
      <div className="grid grid-cols-2 gap-3">
        <Select
          options={mails}
          label={l('mail_to_user')}
          value={config.contact?.mailToUser}
          valueKey={'_id'}
          labelKey={'name'}
          onChange={handleChange}
          name={'mailToUser'}
        />
        <Select
          options={mails}
          label={l('mail_to_admin')}
          value={config.contact?.mailToAdmin}
          valueKey={'_id'}
          labelKey={'name'}
          onChange={handleChange}
          name={'mailToAdmin'}
        />
      </div>
      <div>
        <Checkbox
          checked={config.contact?.isEnabled}
          label={l('enable_contact_form')}
          onChange={handleCheckboxChange}
          name="isEnabled"
        />
        <Button onClick={modal.openModal} isSubmit={false}>{l('disabled_dates')}</Button>
      </div>
      <Modal show={modal.showModal} onClose={modal.closeModal}>
        <H2>{l('disabled_dates')}</H2>
        <div className='flex flex-row gap-3 items-end'>
          <Input
            label={l('add_new')}
            type="date"
            reference={datePickerRef}
          />
          <Button className='mb-2' onClick={handleClick}>
            {l('add')}
          </Button>
        </div>
        <table className='w-full'>
          <thead>
            <tr className='border-b border-gray-500'>
              <th>{l('date')}</th>
              <th>{l('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {config.contact?.disabledOn?.map((date, index) => (
              <tr key={index}>
                <td>{new Date(date).toLocaleDateString()}</td>
                <td className='flex justify-center'>
                  <Button type="danger" onClick={() => deleteDate(index)}>
                    <img src='/img/trash.svg' alt='' className='w-full max-w-[20px]' />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>
    </>
  )
}

export default ContactConfig