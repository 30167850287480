import React from 'react'
import AppContext from '../../contexts/AppContext'

function CheckRole({ children, has = null }) {
	const { permissions } = React.useContext(AppContext)
	const [authorized, setAuthorized] = React.useState(false)

	React.useEffect(() => {
		if (has === null) {
			setAuthorized(true)
			return
		}
		setAuthorized(permissions.some(permission => permission.name === has))
	}, [has, permissions])

	if (!authorized) return null

	return <>{children}</>
}

export default CheckRole
