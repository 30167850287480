import {
  DatetimeField,
  InputField,
  Multiple,
  RadioButtons,
  Section,
  Editor,
  TextArea,
  Select,
  Tag,
  PredefinedQuestions,
  ImageGalleryItem,
  TitleField,
  File,
  Optin,
} from '../components/ClinicCaseForm'

const components = {
  textarea: TextArea,
  editor: Editor,
  radios: RadioButtons,
  multiple: Multiple,
  section: Section,
  input: InputField,
  datetime: DatetimeField,
  select: Select,
  tag: Tag,
  predefined_questions: PredefinedQuestions,
  image_gallery_item: ImageGalleryItem,
  title: TitleField,
  file: File,
  optin: Optin
}

export default components
