import React from 'react'
import { useParams } from 'react-router-dom'
import Mail from '../models/Mail'
import { toast } from 'react-toastify'
import useLocale from '../hooks/useLocale'
import useModal from '../hooks/useModal'

function useEmail() {
  const { l } = useLocale()
  const { id } = useParams()
  const [ mail, setMail ] = React.useState({
    name: '',
    subject: '',
    text: '',
    additionalDestinations: '',
    rolesToSend: [],
    sendToUser: false,
  })
  const { openModal, showModal, closeModal } = useModal()
  const testDestinationsRef = React.useRef(null)
  const testConfigSet = React.useRef(null)

  React.useEffect(() => {
    Mail.show(id).then(res => setMail(res))
  }, [ id ])

  const handleChange = e => {
    const { name, value } = e.target
    setMail(old => ({
      ...old,
      [name]: value,
    }))
  }

  const handleSave = () => {
    Mail.update({ id, data: mail }).then(res =>
      toast.success(l('email_updated'))
    )
  }

  const handleRecipientsChange = (e, type = 'destinations') => {
    const { name } = e.currentTarget

    const index = mail[type]?.indexOf(name)
    if (index === -1) {
      return setMail(old => ({
        ...old,
        [type]: [ ...old[type], name ],
      }))
    }

    const aux = mail
    aux[type].splice(index, 1)
    setMail({ ...aux })
  }

  const handleUserSelect = e => {
    const { checked } = e.target

    setMail(old => ({
      ...old,
      sendToUser: checked,
    }))
  }

  const handleEmailTest = e => {
    e.preventDefault()
    const { value: destination } = testDestinationsRef.current
    const {value: config} = testConfigSet.current

    toast.promise(
      Mail.sendTest({
        id,
        data: mail,
        dest: destination,
        config,

      }),
      {
        pending: l('processing'),
        success: l('test_email_sended'),
        error: l('general_error'),
      }
    )
  }

  const handleCheckboxChange = e => {
    const { name, checked } = e.target
    setMail(old => ({
      ...old,
      [name]: checked
    }))
  }

  return {
    mail,
    handleChange,
    handleSave,
    showModal,
    closeModal,
    openModal,
    handleEmailTest,
    testDestinationsRef,
    handleRecipientsChange,
    handleUserSelect,
    handleCheckboxChange,
    testConfigSet
  }
}

export default useEmail
