import React from 'react'
import Points from '../models/Points'
import { useParams } from 'react-router-dom'

function useUserPoints () {
  const { id } = useParams()
  const [points, setPoints] = React.useState({
    points: [],
    totals: {}
  })

  React.useEffect(() => {
    Points.getHistory(id)
      .then(res => setPoints(res))
  }, [id])

  return {
    points
  }
}

export default useUserPoints