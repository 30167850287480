import React from 'react'
import useLocale from '../../hooks/useLocale'
import { H2, PageHeading } from '../../components/Headings'
import Card from '../../components/Card'
import Button from '../../components/Button'
import CheckRole from '../../components/CheckRole'
import Modal from '../../components/Modal'
import useQueues from '../../hooks/useQueues'

function Queues() {
	const { l } = useLocale()
	const { queue, handleViewInfo, showModal, closeModal, info } = useQueues()

	return (
		<>
			<PageHeading image='/img/queue.svg' title={l('queue')} />
			<Card>
				<table className='w-full'>
					<thead>
						<tr className='text-left border-b border-gray-600'>
							<th>#</th>
							<th>{l('queue')}</th>
							<th>{l('tries')}</th>
							<th>{l('state')}</th>
							<th>{l('date')}</th>
							<th>{l('actions')}</th>
						</tr>
					</thead>
					<tbody>
						{queue.map(item => (
							<tr key={item._id}>
								<td>{item._id}</td>
								<td>{item.queue}</td>
								<td>{item.tries}</td>
								<td>{item.status}</td>
								<td>{new Date(item.createdAt).toLocaleString()}</td>
								<td>
									<CheckRole has='view_queue_info'>
										<Button onClick={() => handleViewInfo(item.info)}>
											<img src='/img/info.svg' className='w-[20px]' alt='' />
										</Button>
									</CheckRole>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</Card>
			<Modal show={showModal} onClose={closeModal}>
				<H2>{l('view_info')}</H2>
				{info.map(item => (
					<p>
						{new Date(item.createdAt).toLocaleString()} {item.message}
					</p>
				))}
			</Modal>
		</>
	)
}

export default Queues
