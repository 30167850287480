import React from 'react'
import { H2, PageHeading } from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import Card from '../../components/Card'
import Button from '../../components/Button'
import useOrigins from '../../hooks/useOrigins'
import Modal from '../../components/Modal'
import { Input } from '../../components/FormComponents'
import CheckRole from '../../components/CheckRole'
import DangerButton from '../../components/DangerButton'

function Origins() {
	const { l } = useLocale()
	const {
		origins,
		openModal,
		showModal,
		closeModal,
		inputRef,
		handleSubmit,
		handleDelete,
	} = useOrigins()

	return (
		<>
			<PageHeading title={l('origins')} image='/img/origin.svg'>
				<CheckRole has='add_origins'>
					<Button onClick={openModal}>{l('add')}</Button>
				</CheckRole>
			</PageHeading>
			<Card>
				<table className='w-full'>
					<thead>
						<tr className='text-left border-gray-600 border-b'>
							<th>{l('name')}</th>
							<th>{l('actions')}</th>
						</tr>
					</thead>
					<tbody>
						{origins.map(origin => (
							<tr>
								<td>{origin.origin}</td>
								<td>
									<CheckRole has='delete_origins'>
										<DangerButton onClick={() => handleDelete(origin._id)} />
									</CheckRole>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</Card>
			<Modal show={showModal} onClose={closeModal}>
				<form onSubmit={handleSubmit}>
					<H2>{l('add')}</H2>
					<Input label={l('name')} reference={inputRef} />
					<Button type='secondary' full>
						{l('save')}
					</Button>
				</form>
			</Modal>
		</>
	)
}

export default Origins
