import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Product from '../models/Product';
import { useDebounce } from './useDebounce';
import useLocale from './useLocale';

function useUserExchange(initData = null, exchangeData = null) {
  const { id } = useParams();
  const { l } = useLocale();
  const [userExchange, setUserExchange] = React.useState({});
  const [debouncedAnnotations, setDebouncedAnnotations] = useDebounce('', 500);

  const getUserExchange = React.useCallback(() => {
    Product.showExchange(id)
      .then(res => setUserExchange(res))
      .catch(err => toast.error(l('general_error')));
  }, [id, l]);

  const handleUpdate = () => {
    Product.updateExchange(userExchange._id, userExchange)
      .then(res => toast.success(l('user_exchange_updated')))
      .catch(() => toast.error(l('general_error')));

    return;
  };

  const handleUserExchangeSent = () => {
    setUserExchange(prevState => {
      const sentDate = prevState.sentDate ? null : new Date().toISOString();

      return {
        ...prevState,
        sentDate,
      };
    });
  };

  const handleUserExchangeHasToBeSentToDelegate = () => {
    setUserExchange(prevState => {
      return {
        ...prevState,
        sendToDelegate: !prevState.sendToDelegate,
      };
    });
  };

  React.useEffect(() => {
    setUserExchange(prevState => ({
      ...prevState,
      annotations: debouncedAnnotations,
    }));
  }, [debouncedAnnotations]);

  const handleOnAnnotationsChange = event => {
    setDebouncedAnnotations(event.currentTarget.value);
  };

  return {
    userExchange,
    id,
    handleUpdate,
    getUserExchange,
    handleUserExchangeSent,
    handleOnAnnotationsChange,
    handleUserExchangeHasToBeSentToDelegate,
  };
}

export default useUserExchange;
