import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import { PageHeading } from '../../components/Headings';
import ProductForm from '../../components/ProductForm';
import useLocale from '../../hooks/useLocale';

const initData = {
  name: '',
  basic: {
    summary: '',
    description: '',
    images: [],
    categories: [],
    reference: '',
  },
  quantities: {
    labelWhenInStock: '',
    labelWhenNoStock: '',
    availabilityDate: '',
    behaviorWhenNoStock: '',
    stock: '',
  },
  shipping: {
    width: '',
    height: '',
    depth: '',
    weight: '',
    deliveryTime: '',
    deliveryTimeInStock: '',
    deliveryTimeNoStock: '',
    isShipping: true,
  },
  pricing: {
    price: '',
    unitPrice: '',
    units: '',
    lastPrice: '',
    onSale: false,
    costPrice: '',
  },
  options: {
    tags: [],
    enabled: true,
    enableForOrder: true,
    isbn: '',
    ean13: '',
    mpn: '',
    attachedFiles: [],
  },
  attributes: {},
};

function CreateProduct() {
  const { l } = useLocale();
  const navigate = useNavigate();

  return (
    <>
      <PageHeading title={l('add_product')} image={'/img/shopping_cart.svg'}>
        <Button type="warning" onClick={() => navigate('/products')} className="flex gap-2">
          <img src="/img/back.svg" className="max-w-[20px]" alt="" />
          {l('return_to_products')}
        </Button>
      </PageHeading>
      <ProductForm initData={initData} />
    </>
  );
}

export default CreateProduct;
