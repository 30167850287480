import React from 'react'
import useLocale from '../../hooks/useLocale'
import {PageHeading} from '../../components/Headings'
import Card from '../../components/Card'
import Contact from '../../models/Contact'
import {useParams} from 'react-router-dom'

function ShowContact () {
  const {id} = useParams()
  const {l} = useLocale()
  const [contact, setContact] = React.useState({attributes: {}})

  React.useEffect(() => {
    Contact.get(id).then(res => setContact(res))
  }, [id])

  const attrs = Object.entries(contact.attributes)

  return (
    <>
      <PageHeading title={l('show_consultation')} image={'/img/consultation.svg'}></PageHeading>
      <Card>
        <div className="grid grid-cols-3">
          <p><strong>{l('user')}: </strong>{contact.user?.name}</p>
          <p><strong>{l('email')}: </strong> {contact.user?.email}</p>
        </div>
        <div className='mt-5'>
          {attrs.map(attr => (
            <div className='mb-3'>
              <p><strong>{attr[0]}: </strong></p>
              <p>{attr[1]}</p>
            </div>
          ))}
        </div>
      </Card>
    </>
  )
}

export default ShowContact