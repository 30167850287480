import React from 'react'
import Code from '../models/Code'
import { toast } from 'react-toastify'
import useModal from './useModal'
import useForms from './useForms'
import AppContext from '../contexts/AppContext'
import useLocale from './useLocale'

function useRegisterCodes() {
	const { l } = useLocale()
	const { permissions } = React.useContext(AppContext)
	const inputRef = React.useRef(null)
	const maxUsesRef = React.useRef(null)
	const formRef = React.useRef(null)
	const qtyRef = React.useRef(null)
	const [codes, setCodes] = React.useState({ docs: [] })
	const [page, setPage] = React.useState(1)
	const { openModal, showModal, closeModal } = useModal()
	const { forms } = useForms({ paginate: false })
	const [editing, setEditing] = React.useState(null)

	const canEditCode = permissions.some(
		permission => permission.name === 'edit_code_format'
	)
	const canEditForm = permissions.some(
		permission => permission.name === 'edit_code_form'
	)

	const getCodes = () => {
		Code.get({ page })
			.then(res => setCodes(res))
			.catch(err => toast.error(l('general_error')))
	}

	React.useEffect(() => {
		getCodes()
	}, [page])

	const resetFields = () => {
		inputRef.current.value = ''
		maxUsesRef.current.value = ''
		formRef.current.value = ''
	}

	const handleEdit = id => {
		Code.show(id)
			.then(res => {
				inputRef.current.value = res.code
				maxUsesRef.current.value = res.maxUses
				formRef.current.value = res.form
				openModal()
				setEditing(id)
			})
			.catch(err => toast.error(l('general_error')))
	}

	const handleSubmit = e => {
		e.preventDefault()
		const data = {
			format: inputRef.current.value,
			maxUses: maxUsesRef.current.value,
			form: formRef.current.value,
			quantity: qtyRef.current.value,
		}

		Code.create({ data })
			.then(res => {
				getCodes()
				resetFields()
				closeModal()
				toast.success(l('code_created'))
			})
			.catch(err => {
				toast.error(l(err.message))
			})
	}

	const handleAdd = () => {
		setEditing(null)
		resetFields()
		openModal()
	}

	const handleDelete = id => {
		Code.delete(id)
			.then(() => {
				toast.success(l('code_deleted'))
				getCodes()
			})
			.catch(err => toast.error(l('general_error')))
	}

	return {
		handleAdd,
		handleDelete,
		handleEdit,
		codes,
		showModal,
		closeModal,
		handleSubmit,
		editing,
		canEditCode,
		canEditForm,
		inputRef,
		maxUsesRef,
		formRef,
		forms,
		qtyRef,
		setPage,
	}
}

export default useRegisterCodes
