import React from 'react'
import {PageHeading} from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import {useParams} from 'react-router-dom'
import Card from '../../components/Card'
import BookForm from '../../components/BookForm'
import Book from '../../models/Book'
import Button from '../../components/Button'
import {toast} from 'react-toastify'

function ViewBookPage () {
  const {l} = useLocale()
  const {id} = useParams()

  const titleRef = React.useRef(null)
  const isbnRef = React.useRef(null)
  const coverRef = React.useRef(null)
  const bookPdfRef = React.useRef(null)
  const [clinicCases, setClinicCases] = React.useState([])

  React.useEffect(() => {
    Book.getBook(id).then(data => {
      titleRef.current.value = data.title
      isbnRef.current.value = data.isbn
      coverRef.current = data.cover
      bookPdfRef.current = data.bookInPdf
      setClinicCases(data.clinicCases)
    })
  }, [id])

  async function handleUpdate () {
    try {
      await Book.update(id, {
        title: titleRef.current.value,
        isbn: isbnRef.current.value,
        cover: coverRef.current,
        bookInPdf: bookPdfRef.current,
        clinicCases
      })
      toast.success(l('book_updated'))
    } catch (err) {
      toast.error(l('general_error'))
    }
  }

  return (
    <>
      <PageHeading title={`${l('view_book')} ${id}`} image={'/img/book.svg'}></PageHeading>
      <Card>
        <BookForm titleRef={titleRef} isbnRef={isbnRef} coverRef={coverRef} bookPdfRef={bookPdfRef} selected={clinicCases} setSelected={setClinicCases} />
      </Card>
      <Button onClick={handleUpdate}>{l('save')}</Button>
    </>
  )
}

export default ViewBookPage