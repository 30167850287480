import React from 'react'
import useClinicCase from '../../hooks/useClinicCase'
import ViewClinicCase from '../../components/ViewClinicCase'
import Card from '../../components/Card'
import { PageHeading } from '../../components/Headings'
import useLocale from '../../hooks/useLocale'

function Answer () {
  const { l } = useLocale()
  const { clinicCase, id } = useClinicCase()

  return (
    <>
      <PageHeading
        title={`${l('answer_clinic_case')} ${id}`}
        image={'/img/answer.svg'}
      >
      </PageHeading>
      <Card>
        <ViewClinicCase clinicCase={clinicCase} withAnswers />
      </Card>
    </>
  )
}

export default Answer