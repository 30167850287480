import Card from '../../components/Card';
import {H2, PageHeading} from '../../components/Headings';
import useLocale from '../../hooks/useLocale';
import CheckRole from '../../components/CheckRole'
import Button from '../../components/Button'
import useModal from '../../hooks/useModal'
import Modal from '../../components/Modal'
import {Input} from '../../components/FormComponents'
import {useCallback, useRef, useState} from 'react';
import Classifier from '../../models/Classifier';
import DataGridView from '../../components/DataGridView'
import {ActionColumn} from './components/ActionColumn';

export function ListClassifiersPage () {
  const {l} = useLocale()
  const modal = useModal()
  const nameRef = useRef(null)
  const [classifiers, setClassifiers] = useState([])

  async function handleAddClassifier (event) {
    event.preventDefault()
    await Classifier.create({name: nameRef.current.value})
    getData()
    nameRef.current.value = ''
    modal.closeModal()
  }

  const getData = useCallback(async function () {
    const classifiers = await Classifier.get()
    setClassifiers(classifiers)
  }, [])

  async function handleDelete (id) {
    await Classifier.delete(id)
    getData()
  }

  const columns = [
    {
      header: l('name'),
      accessor: 'name',
    },
    {
      header: l('actions'),
      component: ({rowData, page, limit, filters}) => <ActionColumn handleDelete={(id) => handleDelete(id, page, limit, filters)} rowData={rowData} />,
      cellClass: 'flex gap-2 justify-center'
    }
  ]

  return (
    <>
      <PageHeading title={l('list_classifiers')} image={'/img/classifiers.svg'}>
        <CheckRole has='add_legal_texts'>
          <Button onClick={modal.openModal}>{l('add')}</Button>
        </CheckRole>
      </PageHeading>
      <Card>
        <DataGridView columns={columns} data={classifiers} getData={getData} />
      </Card>

      <Modal show={modal.showModal} onClose={modal.closeModal}>
        <form onSubmit={handleAddClassifier}>
          <H2>{l('add_classifier')}</H2>
          <Input label={l('name')} reference={nameRef} />
          <Button type='secondary' full>
            {l('add')}
          </Button>
        </form>
      </Modal>
    </>
  )
}