export function hexToRgb (hex) {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return [(c >> 16) & 255, (c >> 8) & 255, c & 255]
  }
  throw new Error('Bad Hex')
}

export function textColor (bgColor) {
  if (bgColor.slice(0, 1) === '#') {
    bgColor = bgColor.slice(1)
  }

  if (bgColor.length === 3) {
    bgColor = bgColor
      .split('')
      .map(function (hex) {
        return hex + hex
      })
      .join('')
  }

  var r = parseInt(bgColor.substr(0, 2), 16)
  var g = parseInt(bgColor.substr(2, 2), 16)
  var b = parseInt(bgColor.substr(4, 2), 16)
  var yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? 'black' : 'white'
}

export const enums = {
  Product: {
    Shipping: {
      None: 'none',
      Specific: 'specific',
      Default: 'default'
    },
    Quantities: {
      Default: 'default',
      Deny: 'deny',
      Allow: 'allow'
    }
  }
}

export const slugify = text =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
