import Fetch from "./Fetch"

class Export {
  fetch = null

  static {
    this.fetch = new Fetch()
  }

  static save (data) {
    this.fetch.endpoint = `exports`
    this.fetch.method = 'POST'
    this.fetch.body = data
    return this.fetch.performRequest()
  }

  static get (form) {
    this.fetch.endpoint = 'exports'
    this.fetch.method = 'GET'
    form !== '' && this.fetch.queries.set('form', form)
    return this.fetch.performRequest()
  }
}

export default Export