import React from 'react'
import useModal from '../hooks/useModal'
import Queue from '../models/Queue'

function useQueues() {
	const [queue, setQueue] = React.useState([])
	const { showModal, closeModal, openModal } = useModal()
	const [info, setInfo] = React.useState([])

	React.useEffect(() => {
		Queue.get().then(res => setQueue(res))
	}, [])

	const handleViewInfo = info => {
		setInfo(info)
		openModal()
	}

	return {
		queue,
		showModal,
		openModal,
		closeModal,
		info,
		handleViewInfo,
	}
}

export default useQueues
