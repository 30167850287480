import React from 'react'
import { H2, PageHeading } from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import Card from '../../components/Card'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { Input, Select } from '../../components/FormComponents'
import CheckRole from '../../components/CheckRole'
import DangerButton from '../../components/DangerButton'
import useRegisterCodes from '../../hooks/useRegisterCodes'
import Paginator from '../../components/Paginator'

function RegisterCodes() {
	const { l } = useLocale()
	const {
		handleAdd,
		handleDelete,
		handleEdit,
		codes,
		showModal,
		closeModal,
		handleSubmit,
		editing,
		canEditCode,
		canEditForm,
		inputRef,
		maxUsesRef,
		formRef,
		forms,
		qtyRef,
		setPage,
		// search,
	} = useRegisterCodes()

	return (
		<>
			<PageHeading image={'/img/codes.svg'} title={l('codes')}>
				<CheckRole has='add_codes'>
					<Button onClick={handleAdd}>{l('add')}</Button>
				</CheckRole>
			</PageHeading>
			<Card>
				{/* <Input
					className='w-64'
					value={search}
					onChange={e => setSearch(e.target.value)}
					placeholder={l('search')}
				/> */}
				<table className='w-full'>
					<thead>
						<tr className='text-left border-b border-gray-600'>
							<th>{l('form')}</th>
							<th>{l('code')}</th>
							<th>{l('max_uses')}</th>
							<th>{l('actions')}</th>
						</tr>
					</thead>
					<tbody>
						{codes.docs.map(code => (
							<tr key={code._id}>
								<td>{code.form?.name}</td>
								<td>{code.code}</td>
								<td>{code.maxUses}</td>
								<td className='flex gap-3'>
									<CheckRole has='view_codes'>
										<Button onClick={e => handleEdit(code._id)} type='dark'>
											<img className='w-[25px]' src='/img/view.svg' alt='' />
										</Button>
									</CheckRole>
									<CheckRole has='delete_codes'>
										<DangerButton onClick={() => handleDelete(code._id)} />
									</CheckRole>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<Paginator meta={codes} setPage={setPage} />
			</Card>
			<Modal show={showModal} onClose={closeModal}>
				<form onSubmit={handleSubmit}>
					<H2>{l(editing !== null ? 'edit_code' : 'create_code')}</H2>
					<Input
						disabled={!canEditCode && editing !== null}
						label={l('code_format')}
						reference={inputRef}
					/>
					<ul>
						<li>{l('rand_char')}</li>
					</ul>
					<Input label={l('max_uses')} reference={maxUsesRef} />
					<Select
						disabled={!canEditForm && editing !== null}
						label={l('form')}
						options={forms}
						labelKey={'name'}
						valueKey='_id'
						reference={formRef}
					/>
					<Input label={l('quantity')} reference={qtyRef} />
					<CheckRole has={editing !== null ? 'edit_codes' : null}>
						<Button type='secondary' full>
							{l('save')}
						</Button>
					</CheckRole>
				</form>
			</Modal>
		</>
	)
}

export default RegisterCodes
