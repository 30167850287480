import CheckRole from '../../../components/CheckRole';
import DangerButton from '../../../components/DangerButton'
import {Link} from 'react-router-dom'
import CopyToClipboard from '../../../components/CopyToClipboard'

export function ActionColumn ({rowData, handleDelete}) {
  return (
    <>
      <CheckRole has="view_classifiers">
        <Link
          to={`/classifiers/${rowData._id}`}
          className='bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200'
        >
          <img className='w-[25px]' src='/img/view.svg' alt='' />
        </Link>
      </CheckRole>
      <CheckRole has="delete_classifiers">
        <DangerButton onClick={() => handleDelete(rowData._id)} />
      </CheckRole>
      <CopyToClipboard text={rowData._id} />
    </>
  )
}