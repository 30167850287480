import React from 'react'

function SelectFilter ({value, onChange, options = [], reference}) {
  return (
    <select
      className="w-full px-2 py-1 border rounded text-sm"
      onChange={onChange}
      value={value}
      ref={reference}
    >
      {options.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
    </select>
  )
}

export default SelectFilter