import React from 'react'

function Label ({ name, label }) {
  return (
    <label htmlFor={name} className='font-bold'>
      {label}
    </label>
  )
}

export default Label