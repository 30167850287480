import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import User from '../models/User'
import { toast } from 'react-toastify'
import useLocale from './useLocale'
import useModal from '../hooks/useModal'
import AppContext from '../contexts/AppContext'

function useUser() {
	const { id } = useParams()
	const { l } = useLocale()
	const { showModal, openModal, closeModal } = useModal()
	const [user, setUser] = React.useState({
		name: '',
		email: '',
		attributes: {},
		permissions: [],
		roles: [],
		forms: [],
		states: [],
		viewStates: [],
    active: true,
	})
	const [loading, setLoading] = React.useState(true)
	const attributeKeyRef = React.useRef(null)
	const { handleUserRefresh } = React.useContext(AppContext)
	const navigate = useNavigate()

	const getUser = () => {
		if (!id) return

		User.show(id)
			.then(res => {
				if (User.fetch.status !== 200) {
					return toast.error(l('general_error'))
				}
				setUser(res)
			})
			.catch(err => toast.error(l('general_error')))
			.finally(() => setLoading(false))
	}

	React.useEffect(() => {
		getUser()
	}, [])

	const create = e => {
		e.preventDefault()
		User.store(user)
			.then(res => {
				navigate('/users')
				toast.success(l('user_added'))
			})
			.catch(err => toast.error(l(err.message)))
	}

	const handleAddAttribute = () => {
		const { value } = attributeKeyRef.current

		if (!value) {
			return alert('error')
		}

		setUser({
			...user,
			attributes: {
				...user.attributes,
				[value]: '',
			},
		})
		closeModal()
		attributeKeyRef.current.value = ''
	}

	const handleDeleteAttribute = key => {
		let aux = user
		delete aux.attributes[key]
		setUser({ ...aux })
	}

	const handleChangeAttribute = (e, key) => {
		const { value } = e.target
		setUser({
			...user,
			attributes: {
				...user.attributes,
				[key]: value,
			},
		})
	}

	const save = () => {
		User.update(id, user)
			.then(res => {
				if (User.fetch.status !== 200) {
					return toast.error(l('general_error'))
				}
				toast.success(l('user_saved'))
				handleUserRefresh()
				getUser()
			})
			.catch(err => toast.error(l('general_error')))
	}

	const handleChange = e => {
		const { name, value } = e.target
		setUser(old => ({
			...old,
			[name]: value,
		}))
	}

  function handleCheckboxChange(e) {
    const {name, checked} = e.target
    setUser(old => ({
      ...old,
      [name]: checked
    }))
  }

	const handleRoleSelect = (e, type = 'roles') => {
		const { name } = e.currentTarget

		const index = user[type].indexOf(name)

		if (index === -1) {
			setUser(old => ({
				...old,
				[type]: [...old[type], name],
			}))
			return
		}

		let aux = user
		aux[type].splice(index, 1)
		setUser({ ...aux })
	}

	return {
		user,
		showModal,
		handleAddAttribute,
		handleDeleteAttribute,
		handleChangeAttribute,
		loading,
		openModal,
		attributeKeyRef,
		closeModal,
		save,
		handleChange,
		handleRoleSelect,
		create,
    handleCheckboxChange,
    getUser
	}
}

export default useUser
