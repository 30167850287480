import React from 'react'
import useModal from "./useModal";
import Configuration from "../models/Configuration";
import { toast } from "react-toastify";
import useLocale from "./useLocale";

function useConfigurations (paginate = true, requestOnMount = true) {
  const { l } = useLocale()
  const [ configSets, setConfigSets ] = React.useState([])
  const {showModal, openModal, closeModal} = useModal()
  const nameRef = React.useRef(null)

  const getConfigs = React.useCallback(({page = 1, limit = 10, filters = {}} = {}) => {
    Configuration.get(filters['name'], page, paginate, limit)
      .then(res => setConfigSets(res))
      .catch(err => toast.error(l('general_error')))
  }, [paginate])

  React.useEffect(() => {
    if (requestOnMount === false) return;
    getConfigs()
  }, [getConfigs, requestOnMount])

  const handleSave = (e) => {
    e.preventDefault()

    const { value } = nameRef.current
    Configuration.create({ name: value })
      .then(res => {
        const config = JSON.parse(localStorage.getItem('table_configuration'))

        toast.success(l('config_added'))
        closeModal()
        nameRef.current.value = ''
        getConfigs({
          page: config.page,
          limit: config.limit,
          filters: config.filters
        })
      })
      .catch(err => console.log(err))
  }

  const handleDelete = (id, page, limit, filters) => {
    Configuration.delete(id).then(() => {
      getConfigs(page, limit, filters)
      toast.success(l('config_deleted'))
    })
  }

  return {handleDelete, openModal, closeModal, showModal, handleSave, nameRef, configSets, getConfigs}
}

export default useConfigurations