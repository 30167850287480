import Fetch from './Fetch'

class Log {
	fetch = null

	static {
		this.fetch = new Fetch()
	}

	static get({ page, filters }) {
		this.fetch.endpoint = 'logs'
		this.fetch.method = 'GET'
		Object.keys(filters).forEach(key => {
			const value = filters[key]
			this.fetch.queries.set(key, value)
		})
		this.fetch.queries.set('page', page)
		return this.fetch.performRequest()
	}
}

export default Log
