import { H2 } from '../../../components/Headings';
import useLocale from '../../../hooks/useLocale';

export function DangerModalContent() {
  const {l} = useLocale()
  
  return (
    <>
      <H2>{l('sure_message')}</H2>
      <p>{l('proceed_question')}</p>
    </>
  )
}