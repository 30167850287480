import React from 'react'
import useRules from '../../hooks/useRules'
import useLocale from '../../hooks/useLocale'
import useModal from '../../hooks/useModal'
import Modal from '../Modal'
import Button from '../Button'
import { H2 } from '../Headings'
import { Select } from '../FormComponents'

function RuleSetSelection({ onChange, ruleset }) {
  const { l } = useLocale()
  const { sets } = useRules(false)
  const { showModal, openModal, closeModal } = useModal()

  return (
    <>
      <Button onClick={ openModal }>
        <img src='/img/flow.svg' className='' alt=''/>
      </Button>
      <Modal show={ showModal } onClose={ closeModal }>
        <H2>{ l('ruleset') }</H2>
        <Select
          label={ l('ruleset') }
          options={ sets }
          labelKey={ 'name' }
          valueKey='_id'
          value={ ruleset }
          name='ruleset'
          onChange={ onChange }
        />
        <Button type='secondary' full onClick={ closeModal }>
          { l('accept') }
        </Button>
      </Modal>
    </>
  )
}

export default RuleSetSelection
