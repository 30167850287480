import React from 'react'
import Button from '../../components/Button'
import { Input } from '../../components/FormComponents'

function Accordion({
	title,
	children,
	onDelete = null,
	onChange = null,
	openDefault = false,
}) {
	const [show, setShow] = React.useState(openDefault)

	const handleToggle = () => {
		setShow(old => !old)
	}

	return (
		<div className='my-3'>
			<div
				className={`bg-gray-600 p-3 flex ${
					show ? 'rounded-tr-md rounded-tl-md' : 'rounded-md'
				} w-full text-left gap-3`}
			>
				{!onChange ? (
					<span
						onClick={handleToggle}
						className='flex-1 cursor-pointer text-xl font-bold'
					>
						{title}
					</span>
				) : (
					<Input
						name='title'
						className='flex-1'
						value={title}
						onChange={onChange}
					/>
				)}
				{onDelete && (
					<Button type='danger' onClick={onDelete}>
						<img src='/img/trash.svg' className='w-[20px]' alt='' />
					</Button>
				)}
				<Button type='empty' onClick={handleToggle}>
					<img
						src='/img/arrow.svg'
						className={`w-[20px] ${!show && 'rotate-180'}`}
						alt=''
					/>
				</Button>
			</div>
			<div
				className={`bg-gray-800 p-5 ${
					show ? 'rounded-br-md rounded-bl-md' : 'rounded-md'
				} ${!show && 'hidden'}`}
			>
				{children}
			</div>
		</div>
	)
}

export default Accordion
