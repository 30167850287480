import {PageHeading} from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import Book from '../../models/Book'
import Card from '../../components/Card'
import React from 'react'
import Button from '../../components/Button'
import {useNavigate} from 'react-router-dom'
import BookForm from '../../components/BookForm'
import {toast} from 'react-toastify'

export default function CreateBookPage () {
  const {l} = useLocale()
  const navigate = useNavigate()

  const titleRef = React.useRef(null)
  const isbnRef = React.useRef(null)
  const coverRef = React.useRef(null)
  const bookPdfRef = React.useRef(null)
  const [clinicCases, setClinicCases] = React.useState([])

  async function handleSave (e) {
    try {
      const data = {
        title: titleRef.current.value,
        isbn: isbnRef.current.value,
        cover: coverRef.current,
        bookInPdf: bookPdfRef.current,
        clinicCases
      }

      await Book.create(data)
      toast.success(l('book_created'))
      navigate(`/books`)
    } catch (err) {

    }
  }

  return (
    <>
      <PageHeading title={l('add_book')} image={'/img/book.svg'}>
      </PageHeading>

      <Card>
        <BookForm titleRef={titleRef} isbnRef={isbnRef} coverRef={coverRef} bookPdfRef={bookPdfRef} selected={clinicCases} setSelected={setClinicCases} />
      </Card>
      <Button onClick={handleSave}>
        {l('save')}
      </Button>
    </>
  )
}