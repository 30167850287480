import React from 'react'
import useModal from './useModal'
import Rule from '../models/Rule'
import { toast } from 'react-toastify'
import useLocale from './useLocale'

function useRules(paginate = true) {
  const [ sets, setSets ] = React.useState([])
  const { showModal, closeModal, openModal } = useModal()
  const nameRef = React.useRef(null)
  const [ search, setSearch ] = React.useState('')
  const [ page, setPage ] = React.useState(1)
  const { l } = useLocale()

  const getRuleSets = () => {
    Rule.get(search, page, paginate)
      .then(res => setSets(res))
      .catch(err => toast.error(l('general_error')))
  }

  const handleDelete = id => {
    Rule.delete(id)
      .then(res => {
        getRuleSets()
      })
      .catch(err => toast.error(l('general_error')))
  }

  const handleAdd = () => {
    const { value: name } = nameRef.current
    Rule.save({ name })
      .then(res => {
        getRuleSets()
        closeModal()
        nameRef.current.value = ''
      })
      .catch(err => toast.error(l('general_error')))
  }

  React.useEffect(() => {
    getRuleSets()
  }, [ search, page ])

  return {
    sets,
    showModal,
    closeModal,
    openModal,
    handleDelete,
    handleAdd,
    nameRef,
    search,
    setSearch,
    setPage
  }
}

export default useRules
