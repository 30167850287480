import React from 'react';
import BooksToClinicCase from '../../components/BooksToClinicCase';
import Button from '../../components/Button';
import Card from '../../components/Card';
import CheckFormPermission from '../../components/CheckFormPermission';
import CheckRole from '../../components/CheckRole';
import Divider from '../../components/Divider';
import EditClinicCase from '../../components/EditClinicCase';
import { Select } from '../../components/FormComponents';
import { H3, PageHeading } from '../../components/Headings';
import Modal from '../../components/Modal';
import ReassignClinicCaseModal from '../../components/ReassignClinicCaseModal';
import ViewClinicCase from '../../components/ViewClinicCase';
import useClinicCase from '../../hooks/useClinicCase';
import useLocale from '../../hooks/useLocale';
import { textColor } from '../../utils';
import { AuthorDetails } from './components/AuthorDetails';

function ShowClinicCase() {
  const { l } = useLocale();
  const {
    id,
    clinicCase,
    execute,
    handleCheckboxChange,
    handleInputChange,
    handleSubmit,
    commentsRef,
    errors,
    download,
    canEdit,
    canEditState,
    canDownload,
    handleAddRow,
    handleDeleteRow,
    handleTagChange,
    setClinicCase,
    isGamificationEnabled,
    canAssignClassifiers,
    reassign,
    reassignModal,
    authorCommentsRef,
    handleOptinChange,
    handleClassifierSelect,
    internalCommentsRef,
  } = useClinicCase();

  const selectComiteRef = React.useRef(null);

  function handleSendToComite(event) {
    execute(selectComiteRef.current.value);
  }

  const comiteStates =
    clinicCase?.allowedStates?.filter(action => action.isComiteState === true) || [];
  const noComiteStates =
    clinicCase?.allowedStates?.filter(
      action => action.isComiteState === false || action.isComiteState == null
    ) || [];
  const hasComiteStates = comiteStates.length > 0;

  return (
    <>
      <PageHeading title={`${l('clinic_case')} ${id}`} image={'/img/clinic_case.svg'}>
        {canDownload && (
          <CheckRole has="download_clinic_cases">
            <Button onClick={download}>{l('download')}</Button>
          </CheckRole>
        )}
        <CheckRole has="reassign_clinic_cases">
          <Button onClick={reassignModal.openModal} type="warning">
            {l('reassign_case')}
          </Button>
        </CheckRole>
        <BooksToClinicCase caseId={id} />
        <AuthorDetails author={clinicCase.user} />
      </PageHeading>
      <Card>
        {clinicCase.state?.editable === true && canEdit && canEditState ? (
          <EditClinicCase
            clinicCase={clinicCase}
            handleCheckboxChange={handleCheckboxChange}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            errors={errors}
            handleAddRow={handleAddRow}
            handleDeleteRow={handleDeleteRow}
            handleTagChange={handleTagChange}
            setClinicCase={setClinicCase}
            handleOptinChange={handleOptinChange}
          />
        ) : (
          <ViewClinicCase clinicCase={clinicCase} withViewQuestions={true} />
        )}
        {isGamificationEnabled && (
          <>
            <Divider />
            <H3>{l('assign_classifiers')}</H3>
            <div className="grid grid-cols-12 gap-3">
              {canAssignClassifiers &&
              clinicCase.classifiers.length !== clinicCase.form.classifiers.length ? (
                <>
                  {clinicCase.form?.classifiers.map(classifier => (
                    <Select
                      onChange={handleClassifierSelect}
                      value={
                        clinicCase.classifiers.find(c => c.classifier === classifier._id)?.selected
                      }
                      label={classifier.name}
                      className={'col-span-2'}
                      name={classifier._id}
                      labelKey="{label} ({points} pts.)"
                      useLabelRegex
                      options={classifier.items}
                      valueKey="_id"
                    />
                  ))}
                </>
              ) : (
                clinicCase.classifiers.map(c => {
                  const classifierData = clinicCase.form.classifiers.find(
                    cf => cf._id === c.classifier
                  );
                  const item = classifierData.items.find(item => item._id === c.selected);
                  return (
                    <div className="col-span-2">
                      <p className="font-bold">{classifierData.name}</p>
                      <p>
                        {item.label} ({item.points} pts.)
                      </p>
                    </div>
                  );
                })
              )}
            </div>
          </>
        )}
        <CheckRole has="execute_actions_on_clinic_cases">
          <CheckFormPermission has={clinicCase.form?._id}>
            <Divider />
            <div className="flex justify-between">
              <div className="flex gap-3 mb-3 items-center">
                {noComiteStates.map(action => {
                  const color = textColor(action.color);
                  return (
                    <button
                      key={action._id}
                      style={{ backgroundColor: action.color, color }}
                      className={`px-2 py-1 rounded-md ease-in-out duration-200`}
                      onClick={() => execute(action._id)}
                    >
                      {action.label}
                    </button>
                  );
                })}
              </div>
              {hasComiteStates && (
                <div className="flex gap-3 items-end">
                  <Select
                    label={'Enviar a comite'}
                    options={comiteStates.map(rule => ({
                      label: rule.label,
                      value: rule._id,
                    }))}
                    reference={selectComiteRef}
                  />
                  <Button onClick={handleSendToComite} className="my-2">
                    {l('send')}
                  </Button>
                </div>
              )}
            </div>
          </CheckFormPermission>
        </CheckRole>
        {clinicCase.state?.canAddComments && (
          <CheckRole has="add_clinic_cases_comments">
            <Divider />
            <label className="font-bold">{l('comments')}</label>
            <textarea
              ref={commentsRef}
              className="w-full rounded-md text-black px-3 py-2"
              rows="5"
            ></textarea>
          </CheckRole>
        )}
        {clinicCase.state?.canAddAuthorComments && (
          <CheckRole has="add_clinic_cases_author_comments">
            <Divider />
            <label className="font-bold">{l('author_comments')}</label>
            <textarea
              ref={authorCommentsRef}
              className="w-full rounded-md text-black px-3 py-2"
              rows="5"
            ></textarea>
          </CheckRole>
        )}
        {clinicCase.state?.canAddInternalComments && (
          <CheckRole has="add_clinic_cases_internal_comments">
            <Divider />
            <label className="font-bold">{l('internal_comments')}</label>
            <textarea
              ref={internalCommentsRef}
              className="w-full rounded-md text-black px-3 py-2"
              rows="5"
            ></textarea>
          </CheckRole>
        )}
        <CheckRole has="view_clinic_cases_comments">
          <Divider />
          <H3>{l('historic_comments')}</H3>
          {clinicCase.comments?.map(comment => (
            <div className="px-2 py-1 my-2 border rounded-md">
              <p className="font-bold text-xl mb-1">{l(comment.type)}</p>
              <div className="grid grid-cols-2 md:grid-cols-4">
                <p>
                  <strong>{l('user')}:</strong> {comment.user.name}
                </p>
                <p>
                  <strong>{l('source')}:</strong>{' '}
                  <span
                    className="py-0.5 px-2 rounded-full"
                    style={{
                      background: comment.source.color,
                      color: textColor(comment.source.color),
                    }}
                  >
                    {comment.source.name}
                  </span>
                </p>
                <p>
                  <strong>{l('destination')}:</strong>{' '}
                  <span
                    className="py-0.5 px-2 rounded-full"
                    style={{
                      background: comment.destination.color,
                      color: textColor(comment.destination.color),
                    }}
                  >
                    {comment.destination.name}
                  </span>
                </p>
                <p>
                  <strong>{l('date')}:</strong> {new Date(comment.createdAt).toLocaleString()}
                </p>
              </div>
              <p className="font-bold mt-2">{l('comment')}:</p>
              <p className="whitespace-pre-line">{comment.comment}</p>
            </div>
          ))}
        </CheckRole>
      </Card>

      <Modal show={reassignModal.showModal} onClose={reassignModal.closeModal}>
        <ReassignClinicCaseModal reassign={reassign} />
      </Modal>
    </>
  );
}

export default ShowClinicCase;
