import React from 'react'
import AppContext from '../../contexts/AppContext'

function CheckFormPermission({ children, has = null }) {
	const { forms } = React.useContext(AppContext)
	const [authorized, setAuthorized] = React.useState(false)

	React.useEffect(() => {
		if (has === null) {
			setAuthorized(true)
			return
		}
		setAuthorized(forms.some(form => form === has))
	}, [has])

	if (!authorized) return null

	return <>{children}</>
}

export default CheckFormPermission
