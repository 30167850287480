import React from 'react'

function Card({ children, classname = '' }) {
	return (
		<div
			className={`bg-gray-700 border border-gray-600 rounded-md p-5 ${classname} relative min-w-[400px] mb-3`}
		>
			{children}
		</div>
	)
}

export default Card
