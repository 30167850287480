import React from 'react'
import Modal from '../Modal'
import {H2, H3} from '../Headings'
import useLocale from '../../hooks/useLocale'
import Divider from '../Divider'
import Button from '../Button'
import {Checkbox, Input, TextAreaField} from '../FormComponents'

function FormQuestions ({
  viewQeustions,
  currentQuestions,
  handleAddAnswer,
  handleAddQuestion,
  handleView,
  handleRemove,
  addQuestion,
  preQuestion,
  questionHandleChange,
  answerHandleChange,
  removePossibleAnswer,
  answerCheckboxChange,
  addPossibleAnswer,
  editingQuestion,
  handleAddGeneralJustification,
  generalJustification,
  handleToggleGeneralJustificationIsRequired,
  isGeneralJustificationRequired
}) {
  const {l} = useLocale()

  return (
    <>
      <Modal show={viewQeustions.showModal} onClose={viewQeustions.closeModal}>
        <H2>{l('view_questions')}</H2>
        <table className='w-full'>
          <thead className='border-b border-gray-600 text-left'>
            <tr>
              <th>{l('question')}</th>
              <th>{l('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {currentQuestions.map((question, index) => <tr>
              <td>{question.question}</td>
              <td className='flex gap-1'>
                <Button type="dark" onClick={() => handleView(question, index)}>
                  <img src="/img/view.svg" className='w-[20px]' alt="" />
                </Button>
                <Button type="danger" onClick={() => handleRemove(index)}>
                  <img src="/img/trash.svg" className='w-[20px]' alt="" />
                </Button>
              </td>
            </tr>)}
          </tbody>
        </table>
        <Divider />
        <TextAreaField label={l('general_justification')} onChange={handleAddGeneralJustification} value={generalJustification} />
        <Checkbox label={l('is_general_justification_required')} onChange={handleToggleGeneralJustificationIsRequired} checked={isGeneralJustificationRequired} />
        <Button onClick={handleAddQuestion}>
          {l('add_question')}
        </Button>
      </Modal>

      <Modal show={addQuestion.showModal} onClose={addQuestion.closeModal}>
        <Input label={l('question')} name="question" onChange={questionHandleChange} value={preQuestion.question} />
        <H3>{l('possible_answers')}</H3>
        {preQuestion.possibleAnswers.map((answer, index) => (
          <React.Fragment key={index}>
            <Input label={l('answer')} name="answer" onChange={e => answerHandleChange(e, index)} value={answer.answer} />
            <Checkbox label={l('Correct answer')} name="isCorrect" onChange={e => answerCheckboxChange(e, index)} checked={answer.isCorrect} />
            <Button type="danger" onClick={() => removePossibleAnswer(index)}>{l('delete')}</Button>
            <Divider />
          </React.Fragment>
        ))}
        <Button onClick={addPossibleAnswer}>{l('add_possible_answer')}</Button>
        <TextAreaField label={l('justification')} name="justification" onChange={questionHandleChange} value={preQuestion.justification} />
        <Button onClick={handleAddAnswer} type="secondary" full>{l(editingQuestion.current === null ? 'add_question' : 'edit_question')}</Button>
      </Modal>
    </>
  )
}

export default FormQuestions