import React from 'react'
import User from '../models/User'

const AppContext = React.createContext()

export const AppProvider = ({ children }) => {
  const [locale, setLocale] = React.useState(
    localStorage.getItem('locale') || 'en'
  )
  const [user, setUser] = React.useState({})
  const [permissions, setPermissions] = React.useState([])
  const [forms, setForms] = React.useState([])
  const [viewStates, setViewStates] = React.useState([])
  const [editableStates, setEditableStates] = React.useState([])

  const getUser = () => {
    User.whoami()
      .then(res => setUser(res))
      .catch(err => setUser({}))
  }

  React.useEffect(() => {
    getUser()
  }, [])

  React.useEffect(() => {
    if (!user.roles || !user.permissions) return

    const permissionsArray = [...user.permissions]
    const formsArray = [...user.forms]
    const viewStatesArray = [...user.viewStates]
    const editableStatesArray = [...user.editableStates]
    user.roles.forEach(role => {
      permissionsArray.push(...role.permissions)
      formsArray.push(...role.forms)
      viewStatesArray.push(...role.viewStates)
      editableStatesArray.push(...role.editableStates)
    })

    setPermissions(permissionsArray)
    setForms(formsArray)
    setViewStates(viewStatesArray)
    setEditableStates(editableStatesArray)
  }, [user])

  const handleSetLocale = locale => {
    localStorage.setItem('locale', locale)
    setLocale(locale)
  }

  return (
    <AppContext.Provider
      value={{
        locale,
        setLocale: handleSetLocale,
        user,
        permissions: permissions,
        handleUserRefresh: getUser,
        forms,
        viewStates,
        editableStates
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContext
