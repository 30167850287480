import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Card from '../../components/Card';
import CheckRole from '../../components/CheckRole';
import DangerButton from '../../components/DangerButton';
import { PageHeading } from '../../components/Headings';
import useLocale from '../../hooks/useLocale';
import useUserExchange from '../../hooks/useUserExchange';
import { DangerModalContent } from '../ManageUsers/components/DangerModalContent';
import ToggleSwitch from '../SharedComponents/ToggleSwitch';

export function ShowUserExchange() {
  const { l } = useLocale();
  const navigate = useNavigate();
  const {
    userExchange,
    getUserExchange,
    handleUpdate,
    handleUserExchangeSent,
    handleOnAnnotationsChange,
    handleUserExchangeHasToBeSentToDelegate,
  } = useUserExchange();
  const userExchangeSentDateRef = React.useRef(false);
  const userExchangeHasTobeSentToDelegateRef = React.useRef(false);

  React.useEffect(() => getUserExchange(), [getUserExchange]);

  const formatDate = dateString => {
    if (!dateString) {
      return '-';
    }

    const date = new Date(dateString);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  };

  const handleExchangeSent = () => {
    handleUserExchangeSent();
    userExchangeSentDateRef.current = true;
  };

  const handleUserExchangeHasToBeSentToDelegateToggle = () => {
    handleUserExchangeHasToBeSentToDelegate();
    userExchangeHasTobeSentToDelegateRef.current = true;
  };

  React.useEffect(() => {
    if (userExchangeSentDateRef.current || userExchangeHasTobeSentToDelegateRef.current) {
      handleUpdate();
      userExchangeSentDateRef.current = false;
      userExchangeHasTobeSentToDelegateRef.current = false;
    }
  }, [userExchange, handleUpdate]);

  return (
    <>
      <PageHeading title={l('user_exchange')} image={'/img/exchange.svg'}>
        <Button type="warning" onClick={() => navigate('/user-exchanges')} className="flex gap-2">
          <img src="/img/back.svg" className="max-w-[20px]" alt="" />
          {l('return_to_user_exchanges')}
        </Button>
      </PageHeading>
      <Card>
        {/* <pre>{JSON.stringify(userExchange, null, 2)}</pre> */}

        <div className="grid grid-cols-4">
          <div className="flex flex-col gap-2">
            <span className="font-bold text-xl">{l('exchange_information')}</span>
            <span>
              {l('product_requested')}: {formatDate(userExchange.createdAt)}
            </span>
            <span>
              {l('product_sent')}:{' '}
              {userExchange.sentDate ? formatDate(userExchange.sentDate) : 'Pendiente'}
            </span>
            <span>
              {l('send_to_delegate')}:{' '}
              <ToggleSwitch
                isOn={userExchange.sendToDelegate ? userExchange.sendToDelegate : false}
                handleToggle={handleUserExchangeHasToBeSentToDelegateToggle}
              />
            </span>

            <span className="flex gap-2 justify-left mt-3">
              <CheckRole has="edit_user_exchanges">
                <DangerButton
                  type="dark"
                  tooltip={l('mark_exchange_as_sent')}
                  onClick={handleExchangeSent}
                  content={<DangerModalContent />}
                  icon={null}
                  textContent={<img className="w-[25px]" src="/img/send.svg" alt="" />}
                />
              </CheckRole>

              {userExchange.product?.attributes.link ? (
                <a
                  href={userExchange.product.attributes.link}
                  target="_blank"
                  rel="noreferrer"
                  title={l('open_product_link')}
                  className="bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200"
                >
                  <img className="w-[25px]" src="/img/shopping_cart.svg" alt="" />
                </a>
              ) : (
                <span
                  title={l('open_product_link')}
                  className="bg-gray-500 px-2 py-1 rounded-md cursor-not-allowed"
                >
                  <img className="w-[25px]" src="/img/shopping_cart.svg" alt="" />
                </span>
              )}
            </span>
          </div>

          <div className="flex flex-col gap-2">
            <span className="font-bold text-xl">{l('user_info')}</span>
            <span>
              {l('name')}: {userExchange.user?.name}
            </span>
            <span>
              {l('email')}: {userExchange.user?.email}
            </span>
            <span>
              {l('hospital')}: {userExchange.user?.attributes.hospital}
            </span>
          </div>

          <div className="flex flex-col gap-2 m-1">
            <span className="font-bold text-xl">{l('product_info')}</span>
            <span>
              {l('name')}: {userExchange.product?.name}
            </span>
            <span>
              {l('points')}: {userExchange.price} pts
            </span>
            <span>
              {l('product_price')}: {userExchange.product?.pricing.costPrice}
            </span>
          </div>

          <div className="flex flex-col gap-2">
            <span className="font-bold text-xl">{l('shipping_info')}</span>
            {Object.entries(userExchange.shippingInfo || {}).map(([key, val]) => (
              <p key={key}>
                <strong>{key}</strong>: {val}
              </p>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-4 mt-4">
          <div>
            <span className="font-bold text-xl">{l('annotations')}:</span>

            <textarea
              className="text-black mt-3"
              name="annotations"
              rows="4"
              cols="50"
              defaultValue={userExchange.annotations}
              onChange={handleOnAnnotationsChange}
            />
          </div>
        </div>
      </Card>

      <CheckRole has="edit_user_exchanges">
        <Button onClick={handleUpdate}>{l('update')}</Button>
      </CheckRole>
    </>
  );
}
