import React from 'react'
import useBooks from '../../hooks/useBooks'
import Button from '../Button'
import useLocale from '../../hooks/useLocale'
import {H2} from '../Headings'

function BooksList ({caseId}) {
  const {l} = useLocale()
  const {books, handleClinicCaseToBook, handleSyncClinicCases} = useBooks()

  return (
    <>
      <H2>{l('assign_book')}</H2>
      <div className='flex flex-col gap-3 mb-3'>
        {books.map(book => <>
          <div
            key={book._id}
            className={`px-3 py-2 border-gray-600 border rounded-md cursor-pointer ${book.clinicCases.some(bookCase => bookCase === caseId) ? 'bg-green-400' : ''}`}
            onClick={() => handleClinicCaseToBook(book._id, caseId)}
          >
            {book.title} ({book._id})
          </div>
        </>
        )}
      </div>
      <Button onClick={handleSyncClinicCases}>{l('save')}</Button>
    </>
  )
}

export default BooksList