import Fetch from './Fetch'

class Code {
	fetch = null

	static {
		this.fetch = new Fetch()
	}

	static get({ page }) {
		this.fetch.endpoint = `codes`
		this.fetch.method = 'GET'
		this.fetch.queries.set('page', page)
		return this.fetch.performRequest()
	}

	static create({ data }) {
		this.fetch.endpoint = 'codes'
		this.fetch.method = 'POST'
		this.fetch.body = data
		return this.fetch.performRequest()
	}

	static show(id) {
		this.fetch.endpoint = `codes/${id}`
		this.fetch.method = 'GET'
		return this.fetch.performRequest()
	}

	static update({ data, id }) {
		this.fetch.endpoint = `codes/${id}`
		this.fetch.method = 'PUT'
		this.fetch.body = data
		return this.fetch.performRequest()
	}

	static delete(id) {
		this.fetch.endpoint = `codes/${id}`
		this.fetch.method = 'DELETE'
		return this.fetch.performRequest()
	}
}

export default Code
