import React from 'react'
import CheckRole from '../CheckRole'
import Button from '../Button'
import useLocale from '../../hooks/useLocale'
import Product from '../../models/Product'
import { toast } from 'react-toastify'
import AppContext from '../../contexts/AppContext'

function ExchangeButton ({ product, withText = false }) {
  const { l } = useLocale()
  const { user } = React.useContext(AppContext)

  const isEnabled = product.options?.enableForOrder && product.options?.enabled && user.points > product.pricing.price

  const handleClick = async () => {
    try {
      await Product.exchange({ product: product._id })
      toast.success(l('product_exchanged'))
    } catch (err) {
      toast.error(l(err.message ?? 'general_error'))
      console.error({ ...err })
    }
  }

  return (
    <CheckRole has={'exchange_products'}>
      <Button type='green' className='flex gap-2' onClick={handleClick} disabled={!isEnabled}>
        <img src="/img/exchange.svg" className='max-w-[20px]' alt="" /> {withText && l('exchange')}
      </Button>
    </CheckRole>
  )
}

export default ExchangeButton