import React from 'react'
import Product from '../models/Product'
import { toast } from 'react-toastify'
import useLocale from './useLocale'

function useProducts () {
  const { l } = useLocale()
  const [products, setProducts] = React.useState({ docs: [] })
  const [page, setPage] = React.useState(1)
  const [search, setSearch] = React.useState('')

  React.useEffect(() => {
    getProducts()
  }, [page, search])

  const getProducts = () => {
    Product.get({ page, search })
      .then(res => setProducts(res))
      .catch(() => toast.error(l('general_error')))

  }

  const handleDelete = (id) => {
    Product.delete(id)
      .then(res => {
        getProducts()
      })
  }

  return {
    products,
    handleDelete,
    setPage,
    setSearch,
    search
  }
}

export default useProducts