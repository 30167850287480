import {textColor} from '../../../utils'

export function StateColumn ({columnData, rowData}) {
  return (
    <span
      style={{
        background: rowData.state.color,
        color: textColor(rowData.state.color),
      }}
      className='px-4 py-0.5 rounded-full inline-block min-w-[120px] text-center'
    >
      {rowData.state.name}
    </span>
  )
}