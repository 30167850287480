import React from 'react'
import useLocale from '../../hooks/useLocale'
import Divider from '../Divider'
import { Input } from '../FormComponents'
import { H2 } from '../Headings'

function GeneralConfig({ config, handleChange }) {
	const { l } = useLocale()

	return (
		<>
			<H2>
				<img src='/img/cog.svg' className={'w-[25px]'} alt='' />{' '}
				{l('general_config')}
			</H2>
			<Input
				label={l('app_url')}
				onChange={handleChange}
				value={config.general.appUrl}
				name={'appUrl'}
			/>
			<Divider />
		</>
	)
}

export default GeneralConfig
