import { Link } from 'react-router-dom';
import CheckRole from '../../../components/CheckRole';
import DangerButton from '../../../components/DangerButton';
import CopyToClipboard from '../../../components/CopyToClipboard';

export function ActiveFormsActionColumn({rowData, handleDelete}) {
  return (
    <>
      <CheckRole has='view_active_forms'>
        <Link
          to={`/active-forms/${rowData._id}`}
          className='bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200'
        >
          <img className='w-[25px]' src='/img/view.svg' alt='' />
        </Link>
      </CheckRole>
      <CheckRole has='delete_active_forms'>
        <DangerButton onClick={() => handleDelete(rowData._id)} />
      </CheckRole>
      <CopyToClipboard text={rowData._id} />
    </>
  )
}