import React from 'react'
import { useParams } from 'react-router-dom'
import useLocale from './useLocale'
import Configuration from '../models/Configuration'
import { toast } from 'react-toastify'

// TODO: Save automatically the config when date added or deleted

function useConfig () {
  const { id } = useParams()
  const { l } = useLocale()
  // const hasToSaveForm = React.useRef(false)
  const [config, setConfig] = React.useState({
    name: '',
    smtp: {},
    user: {},
    general: {},
    gamification: {
      enabled: false,
      qualities: [],
      dificulties: []
    },
    contact: {},
    exchangePeriods: {
      dates: [],
      numOfExchangesPerPeriod: 0,
    }
  })

  const getConfig = React.useCallback(function () {
    Configuration.show(id)
      .then(res => {
        res.exchangePeriods.dates.forEach(date => {
          date.from = new Date(date.from)
          date.to = new Date(date.to)
        })
        setConfig(res)
      })
      .catch(err => toast.error(l('general_error')))
  }, [id, l])

  const handleSave = React.useCallback(function () {
    Configuration.update(config, id)
      .then(() => {
        toast.success(l('config_added'))
        getConfig()
      })
      .catch(() => toast.error(l('general_error')))
  }, [config, getConfig, id, l])

  React.useEffect(() => {
    getConfig()
  }, [getConfig])

  // React.useEffect(() => {
  //   if (hasToSaveForm.current === false) return;
  //   handleSave()
  //   handleSave.current = false
  // }, [config])

  function handleChange (e, config) {
    const { name, value } = e.target
    setConfig(old => ({
      ...old,
      [config]: {
        ...old[config],
        [name]: value,
      },
    }))
  }

  function handleCheckboxChange (e, config) {
    const { checked, name } = e.target
    setConfig(old => ({
      ...old,
      [config]: {
        ...old[config],
        [name]: checked,
      },
    }))
  }

  function handleNameChange (e) {
    const { name, value } = e.target
    setConfig(old => ({
      ...old,
      [name]: value,
    }))
  }

  function addClassifier (type, id) {
    const aux = config
    aux.gamification[type].push(id)
    setConfig({ ...aux })
    handleSave()
  }

  function removeClassifier (type, id) {
    const aux = config
    const index = aux.gamification[type].map(item => item._id).indexOf(id)
    aux.gamification[type].splice(index, 1)
    setConfig({ ...aux })
    handleSave()
  }

  function addDate (date) {
    if (config.contact.disabledOn.includes(date)) return;
    // hasToSaveForm.current = true
    setConfig(old => ({
      ...old,
      contact: {
        ...old.contact,
        disabledOn: [
          ...old.contact.disabledOn,
          date
        ]
      }
    }))
  }

  function deleteDate (index) {
    const aux = config
    // hasToSaveForm.current = true
    aux.contact.disabledOn.splice(index, 1)
    setConfig({...aux})
  }

  function addPeriod(from, to) {
    const aux = config;

    aux.exchangePeriods.dates.push({from: new Date(from), to: new Date(to)});

    setConfig({...aux});
  }

  function deletePeriod(index) {
    const aux = config;

    aux.exchangePeriods.dates.splice(index, 1)

    setConfig({...aux});
  }

  return {
    config,
    handleChange,
    handleSave,
    handleNameChange,
    handleCheckboxChange,
    addClassifier,
    removeClassifier,
    addDate,
    deleteDate,
    addPeriod,
    deletePeriod
  }
}

export default useConfig
