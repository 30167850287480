import Fetch from './Fetch'

class ImageGallery {
  fetch = null

  static {
    this.fetch = new Fetch()
  }

  static list () {
    this.fetch.endpoint = 'image-galleries'
    this.fetch.method = 'GET'
    return this.fetch.performRequest()
  }

  static download (uuid) {
    this.fetch.endpoint = `image-galleries/${uuid}/download`
    this.fetch.method = 'GET'
    this.fetch.isDownloadingFile = true
    return this.fetch.performRequest()
  }

  static findOne (uuid) {
    this.fetch.endpoint = `image-galleries/${uuid}`
    this.fetch.method = 'GET'
    return this.fetch.performRequest()
  }
}

export default ImageGallery
