import Fetch from './Fetch';

class User {
  fetch = null;

  static {
    this.fetch = new Fetch();
  }

  static get(name, page, limit, paginate, email, platform, active) {
    this.fetch.endpoint = 'users';
    this.fetch.method = 'GET';

    if (name) this.fetch.queries.set('name', name);
    if (email) this.fetch.queries.set('email', email);
    if (platform) this.fetch.queries.set('platform', platform);
    if (active) this.fetch.queries.set('active', active);

    this.fetch.queries.set('page', page);
    this.fetch.queries.set('limit', limit);

    paginate === false && this.fetch.queries.set('paginate', 'false');

    return this.fetch.performRequest();
  }

  static show(id) {
    this.fetch.endpoint = `users/${id}`;
    this.fetch.method = 'GET';
    return this.fetch.performRequest();
  }

  static export() {
    this.fetch.endpoint = 'users/export';
    this.fetch.method = 'GET';
    this.fetch.isDownloadingFile = true;
    return this.fetch.performRequest();
  }

  static update(id, data) {
    this.fetch.endpoint = `users/${id}`;
    this.fetch.method = 'PUT';
    this.fetch.body = data;
    return this.fetch.performRequest();
  }

  static login(credentials) {
    this.fetch.endpoint = 'auth/login/admin';
    this.fetch.method = 'POST';
    this.fetch.body = credentials;
    return this.fetch.performRequest();
  }

  static whoami() {
    this.fetch.endpoint = 'auth/whoami';
    this.fetch.method = 'GET';
    return this.fetch.performRequest();
  }

  static store(data) {
    this.fetch.endpoint = 'users';
    this.fetch.method = 'POST';
    this.fetch.body = data;
    return this.fetch.performRequest();
  }

  static delete(id) {
    this.fetch.endpoint = `users/${id}`;
    this.fetch.method = 'DELETE';
    return this.fetch.performRequest();
  }

  static logout() {
    this.fetch.endpoint = 'auth/logout';
    this.fetch.method = 'POST';
    this.fetch.body = {};
    return this.fetch.performRequest();
  }

  static rememberPassword(data) {
    this.fetch.endpoint = 'auth/remember-password';
    this.fetch.method = 'POST';
    this.fetch.body = data;
    return this.fetch.performRequest();
  }

  static changePassword(data) {
    this.fetch.endpoint = `auth/change-password`;
    this.fetch.method = 'POST';
    this.fetch.body = data;
    return this.fetch.performRequest();
  }

  static getSessions(id) {
    this.fetch.endpoint = `sessions/${id}`;
    this.fetch.method = 'GET';
    return this.fetch.performRequest();
  }

  static deleteSession(id) {
    this.fetch.endpoint = `sessions/${id}`;
    this.fetch.method = 'DELETE';
    return this.fetch.performRequest();
  }

  static toggleAccount(id) {
    this.fetch.endpoint = `users/${id}/toggle-account`;
    this.fetch.method = 'PATCH';
    this.fetch.body = {};
    return this.fetch.performRequest();
  }
}

export default User;
