import React from 'react'
import Label from '../Label'

function Container ({children, className, style = {}}) {
  return (
    <div className={`flex flex-col gap-2 my-2 flex-1 ${className}`} style={style}>
      {children}
    </div>
  )
}

export function TextAreaField ({
  label,
  name = null,
  onChange = null,
  value = undefined,
  className = '',
  placeholder = '',
  reference = null,
}) {
  return (
    <Container>
      {label && (
        <Label htmlFor={name} label={label} />
      )}
      <textarea
        name={name}
        rows='5'
        className={`border border-gray-300 rounded-md px-2 py-1 ${className} text-black`}
        onChange={onChange}
        value={value}
        ref={reference}
        placeholder={placeholder}
      ></textarea>
    </Container>
  )
}

export function Input ({
  label,
  name = null,
  type = 'text',
  onChange = null,
  value = undefined,
  className = '',
  placeholder = '',
  reference = null,
  disabled = false,
  isDecimal = false,
  style = {}
}) {
  return (
    <Container className={className} style={style}>
      {label && (
        <Label name={name} label={label} />
      )}
      <input
        ref={reference}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        value={value}
        id={name}
        name={name}
        disabled={disabled}
        step={isDecimal ? "0.000001" : null}
        className={type !== 'file' ? 'border border-gray-300 rounded-md px-2 py-0.5 disabled:bg-gray-300' : 'text-white'}
      />
    </Container>
  )
}

export function Legend ({text}) {
  return <legend className='font-bold text-2xl mb-5'>{text}</legend>
}

export function Select ({
  label,
  name,
  className,
  options = [],
  valueKey = 'value',
  labelKey = 'label',
  value = undefined,
  onChange = null,
  reference = null,
  disabled = false,
  useLabelRegex = false,
  withDefaultOption = true
}) {
  return (
    <Container className={className}>
      {label && (
        <Label name={name} label={label} />
      )}
      <select
        name={name}
        ref={reference}
        id={name}
        className='border border-gray-300 rounded-md px-2 py-1'
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {withDefaultOption ? (
          <option value={null} disabled={false}></option>
        ) : (
          null
        )}
        {options.map(option => (
          <option value={option[valueKey]} key={option[valueKey]}>
            {!useLabelRegex ? (
              option[labelKey]
            ) : (
              labelKey.replace(/{(.*?)}/g, (a, b) => eval(`option.${b}`))
            )}
          </option>
        ))}
      </select>
    </Container>
  )
}

export function Checkbox ({
  label,
  name,
  id,
  reference = null,
  checked = null,
  onChange = null,
  className = null,
  value,
}) {
  return (
    <div className='flex items-center mb-4'>
      <input
        id={id}
        type='checkbox'
        checked={checked}
        name={name}
        className='w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
        ref={reference}
        onChange={onChange}
        value={value}
      />
      <label htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

export function Radio ({
  label,
  name,
  id,
  reference = null,
  checked = null,
  onChange = null,
  className = null,
  value,
}) {
  return (
    <div className='flex items-center'>
      <input
        id={id || name}
        type='radio'
        value={value}
        checked={checked}
        name={name}
        className='w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
        ref={reference}
        onChange={onChange}
      />
      <Label name={name || id} label={label} />
    </div>
  )
}

export function FileInput ({label, name, id, reference = null, onChange = null}) {
  return (
    <Container>
      {label && (
        <Label name={name} label={label} />
      )}
      <input
        type="file"
        id={id}
        name={name}
        ref={reference}
        onChange={onChange}
        className='text-white'
      />
    </Container>
  )
}