import React from 'react';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import useLocale from '../../hooks/useLocale';
import useModal from '../../hooks/useModal';
import { DangerModalContent } from '../ManageUsers/components/DangerModalContent';

const ToggleSwitch = ({ isOn, handleToggle, content = <DangerModalContent /> }) => {
  const { showModal, openModal, closeModal } = useModal();
  const { l } = useLocale();

  const handleAccept = () => {
    handleToggle();
    closeModal();
  };

  return (
    <>
      <label className="relative inline-flex cursor-pointer items-center">
        <input
          id="switch-2"
          type="checkbox"
          className="peer sr-only"
          checked={isOn}
          onChange={openModal}
        />
        <div className="peer h-4 w-11 rounded-full border bg-gray-600 after:absolute after:-top-1 after:left-0 after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-green-400 peer-checked:after:translate-x-full peer-focus:ring-green-400"></div>
      </label>
      <Modal show={showModal} onClose={closeModal}>
        {content}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-4">
          <Button onClick={handleAccept} type="danger">
            {l('yes_i_know')}
          </Button>
          <Button onClick={closeModal} type="secondary">
            {l('cancel')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ToggleSwitch;
