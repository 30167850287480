import Fetch from './Fetch'

class Mail {
  fetch = null

  static {
    this.fetch = new Fetch()
  }

  static get(search, page, paginate) {
    this.fetch.endpoint = 'mails'
    this.fetch.method = 'GET'
    this.fetch.queries.set('search', search)
    this.fetch.queries.set('page', page)
    paginate === false && this.fetch.queries.set('paginate', 'false')
    return this.fetch.performRequest()
  }

  static show(id) {
    this.fetch.endpoint = `mails/${ id }`
    this.fetch.method = 'GET'
    return this.fetch.performRequest()
  }

  static update({ id, data }) {
    this.fetch.endpoint = `mails/${ id }`
    this.fetch.method = 'PUT'
    this.fetch.body = data
    return this.fetch.performRequest()
  }

  static save(data) {
    this.fetch.endpoint = 'mails'
    this.fetch.method = 'POST'
    this.fetch.body = data
    return this.fetch.performRequest()
  }

  static delete(id) {
    this.fetch.endpoint = `mails/${ id }`
    this.fetch.method = 'DELETE'
    return this.fetch.performRequest()
  }

  static sendTest(body) {
    this.fetch.endpoint = 'mails/test'
    this.fetch.method = 'POST'
    this.fetch.body = body
    return this.fetch.performRequest()
  }
}

export default Mail
