import {useCallback, useEffect, useState} from 'react';
import useModal from '../../hooks/useModal';
import Button from '../Button';
import Modal from '../Modal';
import Classifier from '../../models/Classifier'
import {H2} from '../Headings';
import useLocale from '../../hooks/useLocale';
import {Checkbox} from '../FormComponents';

export function SelectClassifiersSection ({selected = [], handleChange}) {
  const modal = useModal()
  const {l} = useLocale()
  const [classifiers, setClassifiers] = useState([])

  const getData = useCallback(async function () {
    const classifiers = await Classifier.get()
    setClassifiers(classifiers)
  }, [])

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <Button onClick={modal.openModal}>
        <img src="/img/classifiers.svg" className={'w-[25px]'} alt="" />
      </Button>
      <Modal show={modal.showModal} onClose={modal.closeModal}>
        <H2>{l('select_classifiers')}</H2>
        {classifiers.map(classifier => <Checkbox onChange={handleChange} label={classifier.name} value={classifier._id} name={'classifier'} checked={selected.includes(classifier._id)} />)}
      </Modal>
    </>
  )
}