import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import items from '../../config/navigation.json';
import useLocale from '../../hooks/useLocale';
import CheckRole from '../CheckRole';

function Navigation() {
  const { l } = useLocale();
  const { pathname } = useLocation();

  return (
    <ul className="space-y-2 relative h-full">
      {items.map(el => (
        <CheckRole key={el.id} has={el.permission}>
          <li>
            <Link
              to={el.href}
              className={`flex items-center p-2 text-base font-normal ${
                pathname.includes(el.href) && 'bg-gray-500'
              } rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
            >
              <img src={el.icon} alt="" className="max-w-[20px] w-full" />
              <span className="ml-3">{l(el.label)}</span>
            </Link>
          </li>
        </CheckRole>
      ))}
      <li></li>
    </ul>
  );
}

export default Navigation;
