import React from 'react'
import {PageHeading} from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import Contact from '../../models/Contact'
import DataGridView from '../../components/DataGridView'
import Card from '../../components/Card'
import ActionColumn from './components/actions'

function ContactsPage () {
  const {l} = useLocale()
  const [contacts, setContacts] = React.useState({})

  const getContacts = React.useCallback(async ({page = 1, limit = 10, filters = {}} = {}) => {
    const contacts = await Contact.getAll(page, limit)
    setContacts(contacts)
  }, [])

  const columns = [
    {
      header: l('id'),
      accessor: '_id'
    },
    {
      header: l('user'),
      accessor: 'user.name'
    },
    {
      header: l('type'),
      accessor: 'attributes.type'
    },
    {
      header: l('subject'),
      accessor: 'attributes.subject'
    },
    {
      header: l('actions'),
      component: ActionColumn,
      cellClass: 'flex gap-3 justify-center'
    }
  ]

  return (
    <>
      <PageHeading title={l('consultations')} image={'/img/consultation.svg'}></PageHeading>
      <Card>
        <DataGridView columns={columns} getData={getContacts} data={contacts.docs} totalPages={contacts.totalPages} />
      </Card>
    </>
  )
}

export default ContactsPage