import React from 'react'
import {PageHeading} from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import Card from '../../components/Card'
import ImageGallery from '../../models/ImageGallery'
import {useParams} from 'react-router-dom'
import {H3} from '../../components/Headings'

const typesMap = {
  image: 'img',
  consent: 'iframe'
}

function ViewUpload () {
  const {uuid} = useParams()
  const [data, setData] = React.useState({})
  const {l} = useLocale()

  React.useEffect(() => {
    ImageGallery.findOne(uuid).then(res => setData(res))
  }, [uuid])

  return (
    <>
      <PageHeading title={l('view_image_gallery')} image='/img/image-gallery.svg'>
      </PageHeading>
      <Card>
        <div className="grid grid-cols-2 gap-5">
          <div>
            <H3>{l('files')}:</H3>
            {data.files?.map(file => (
              <>
                <p>{file.original_name}</p>
                {React.createElement(typesMap[file.type], {
                  src: `${process.env.REACT_APP_GALLERY_URL}/api/file/${file.uuid}`,
                  className: `w-full mx-auto mb-4 ${file.mime_type === 'application/pdf' ? 'min-h-[900px]' : null}`
                })}
              </>
            ))}
          </div>
          <div>
            <H3>{l('attributes')}:</H3>
            {data.attributes?.map(attr => (
              <p><strong>{attr.key}</strong>: {attr.value}</p>
            ))}
          </div>
        </div>
      </Card>
    </>
  )
}

export default ViewUpload