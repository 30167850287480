import React from 'react'
import { Input } from '../../components/FormComponents'
import { H1, H2 } from '../../components/Headings'
import LocaleButtons from '../../components/LocaleButtons'
import useLocale from '../../hooks/useLocale'
import useLoginForm from '../../hooks/useLoginForm'

function Entrypoint() {
	const { l } = useLocale()
	const { loading, emailRef, passwordRef, handleSubmit } = useLoginForm()

	if (loading) return null
	return (
		<div className='flex flex-col justify-center items-center h-screen gap-9'>
			<H1>{l('app_name')}</H1>
			<H2 className='text-2xl font-bold'>{l('login')}</H2>
			<form className='w-full max-w-sm' onSubmit={handleSubmit}>
				<Input label={l('username')} name='username' reference={emailRef} />
				<Input
					label={l('password')}
					name='password'
					type='password'
					reference={passwordRef}
				/>
				<input
					type='submit'
					value={l('login')}
					className='border-[3px] py-0.5 text-white w-full hover:bg-white hover:text-black ease-in-out duration-200 cursor-pointer rounded'
				/>
			</form>
			<LocaleButtons absolute={false} />
		</div>
	)
}

export default Entrypoint
