import React from 'react'

function TextFilter ({value, onChange, reference}) {
  return (
    <input
      type="text"
      value={value}
      className="w-full px-2 py-1 border rounded text-sm"
      onChange={onChange}
      ref={reference}
    />
  )
}

export default TextFilter