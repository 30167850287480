import React from 'react'

function PointsCard ({ children, type = "total" }) {
  const style = {
    total: 'border-l-blue-500',
    gained: 'border-l-green-500',
    wasted: 'border-l-red-500'
  }

  return (
    <div className={`border-l-4 border-gray-600 flex py-5 flex-col items-center justify-center border rounded-md ${style[type]}`}>
      {children}
    </div>
  )
}

export default PointsCard