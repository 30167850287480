import React from 'react'
import { toast } from 'react-toastify'
import Permission from '../models/Permission'
import useLocale from './useLocale'

function usePermissions() {
	const [permissions, setPermissions] = React.useState([])
	const { l } = useLocale()

	const getPermissions = () => {
		Permission.get()
			.then(res => setPermissions(res))
			.catch(err => toast.error(l('general_error')))
	}

	React.useEffect(() => {
		getPermissions()
	}, [])

	return {
		permissions,
	}
}

export default usePermissions
