import React from 'react'
import { Input, Legend } from '../../components/FormComponents'
import { toast } from 'react-toastify'
import Fetch from '../../models/Fetch'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button'
import useLocale from '../../hooks/useLocale'

function Install() {
	const { l } = useLocale()
	const navigate = useNavigate()
	const [data, setData] = React.useState({
		connString: '',
		ru_password: '',
	})

	const handleChange = e => {
		setData({
			...data,
			[e.currentTarget.name]: e.currentTarget.value,
		})
	}

	const handleSubmit = e => {
		e.preventDefault()

		const fetch = new Fetch()
		// fetch.url = "http://localhost:3030/api/install/"
		fetch.endpoint = 'install/'
		fetch.method = 'POST'
		fetch.body = data
		fetch
			.performRequest()
			.then(res => {
				if (fetch.status !== 200) {
					toast.error(res.message)
					return
				}

				toast.success(res.message)
				setTimeout(() => {
					navigate('/')
				}, 2500)
			})
			.catch(err => {
				toast.error('An error has ocurred!')
			})
	}

	const handleDbCheck = e => {
		e.preventDefault()

		const fetch = new Fetch()
		fetch.endpoint = 'install/check'
		fetch.method = 'POST'
		fetch.body = {
			connString: data.connString,
		}
		fetch
			.performRequest()
			.then(res => {
				toast(res.message, {
					type: `${res.connected ? 'success' : 'error'}`,
				})
			})
			.catch(err => {
				toast.error('Unhandled error!', { autoClose: false })
			})
	}

	return (
		<div className='container mx-auto max-w-lg py-9'>
			<div className='flex flex-col'>
				<h1 className='font-bold text-3xl mb-9 text-center'>
					Installation of ParanoiaCCL
				</h1>
				<form onSubmit={handleSubmit}>
					<Legend text={'Database'} />
					<Input
						label='Connection String'
						name='connString'
						value={data.connString}
						onChange={handleChange}
					/>
					<Button hoverBg='gray-300' bg={'gray-700'} onClick={handleDbCheck}>
						Check DB connection
					</Button>

					<Legend text='Root user' />
					<Input
						label='Name'
						name='ru_name'
						value={data.ru_name}
						type='text'
						onChange={handleChange}
					/>
					<Input
						label='Email'
						name='ru_username'
						value={data.ru_username}
						type='text'
						onChange={handleChange}
					/>
					<Input
						label='Password'
						name='ru_password'
						value={data.ru_password}
						type='password'
						onChange={handleChange}
					/>

					<Button width={'full'} bg='gray-800' hoverBg={'gray-400'}>
						{l('save')}
					</Button>
				</form>
			</div>
		</div>
	)
}

export default Install
