import React from 'react'
import User from '../../models/User'
import { useNavigate } from 'react-router-dom'
import AppContext from '../../contexts/AppContext'

function Protected({ children, type = 'redirectIfNoAuth', has = null }) {
	const navigate = useNavigate()
	const [loadingAuth, setLoadingAuth] = React.useState(true)
	const [loadingPermissions, setLoadingPermissions] = React.useState(true)
	const { permissions } = React.useContext(AppContext)

	React.useEffect(() => {
		User.whoami()
			.then(res => {
				setLoadingAuth(false)
			})
			.catch(err => {
				return navigate('/')
			})
	}, [navigate])

	React.useEffect(() => {
		if (has === null) {
			setLoadingPermissions(false)
			return
		}
		if (permissions.length === 0) return
		if (!permissions.some(permission => permission.name === has)) {
			return navigate('/dashboard')
		}

		setLoadingPermissions(false)
	}, [permissions, has, navigate])

	if (loadingAuth || loadingPermissions) return null

	return children
}

export default Protected
