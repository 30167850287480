import React from 'react'
import useModal from '../hooks/useModal'
import { toast } from 'react-toastify'
import LegalText from '../models/LegalText'
import useLocale from '../hooks/useLocale'

function useLegalTexts(paginate = true) {
  const { l } = useLocale()
  const { showModal, openModal, closeModal } = useModal()
  const nameRef = React.useRef(null)
  const [ legalTexts, setLegalTexts ] = React.useState([])
  const [ search, setSearch ] = React.useState('')
  const [ page, setPage ] = React.useState(1)

  const getLegals = () => {
    LegalText.get(search, page, paginate)
      .then(res => setLegalTexts(res))
      .catch(err => toast.error(l('general_error')))
  }

  const handleAddLegal = e => {
    e.preventDefault()
    const { value } = nameRef.current
    LegalText.save({ name: value })
      .then(res => {
        getLegals()
        nameRef.current.value = ''
        closeModal()
      })
      .catch(err => toast.error(l('general_error')))
  }

  React.useEffect(() => {
    getLegals()
  }, [ search ])

  const handleDelete = id => {
    LegalText.delete(id)
      .then(res => getLegals())
      .catch(err => l('general_error'))
  }

  return {
    openModal,
    closeModal,
    showModal,
    handleAddLegal,
    handleDelete,
    legalTexts,
    nameRef,
    search,
    setSearch,
    setPage
  }
}

export default useLegalTexts
